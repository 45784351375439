/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { ChangeEventHandler, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import borderHelper from './helpers/borderHelper';
import BasePlaceholder from './FadeOutPlaceholder';
import { BaseForm } from 'shared/types';

const Wrapper = styled.div`
  position: relative;
`;

const Placeholder = styled(BasePlaceholder)``;

const StyledTextarea = styled.textarea<Props & State>`
  color: var(--text-default);
  background-color: ${props =>
    props.disabled ? 'var(--surface-secondary)' : 'var(--surface-background)'};
  width: 100%;
  padding: 16px;
  line-height: ${pxInRem(20)};
  font-size: ${pxInRem(16)};
  ${props => borderHelper(props)};
`;

export interface Props extends BaseForm {
  rows?: number;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

interface State {
  focused: boolean;
}

export default class Textarea extends PureComponent<Props, State> {
  public readonly state = {
    focused: false
  };

  public static readonly defaultProps = {
    rows: 3
  };

  private handleFocus = (): void => {
    this.setState({ focused: true });
  };

  private handleBlur = (e: React.FocusEvent<any>): void => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    onBlur!(e);
  };

  public render(): ReactNode {
    const {
      className,
      name,
      value,
      placeholder,
      onChange,
      required,
      rows,
      error,
      disabled
    } = this.props;
    const { focused } = this.state;

    return (
      <Wrapper>
        <Placeholder
          className={className}
          placeholder={placeholder}
          value={value}
          focused={focused}
        >
          <StyledTextarea
            rows={rows}
            id={name}
            className={className}
            name={name}
            required={required}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={onChange}
            value={value}
            error={error}
            disabled={disabled}
            focused={focused}
          />
        </Placeholder>
      </Wrapper>
    );
  }
}
