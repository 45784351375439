import React from 'react';

import { IconProps } from '../types';

export default function UserPosition({
  className,
  width = '1em',
  height = '1em'
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 19 19"
    >
      <circle cx="9.5" cy="9.5" r="3.75" fill="current" />
      <circle
        fill="none"
        stroke="current"
        strokeWidth="3px"
        opacity="0.4"
        cx="9.5"
        cy="9.5"
        r="6.5"
      />
    </svg>
  );
}
