import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleHtmlTag: content.title.htmlTag,
  description: content.description ?? '',
  buttonLabel: content.button?.label ?? '',
  errorFeedback: content.cannotLabel ?? '',
  validationFeedback: content.validationLabel ?? '',
  apis: {
    canSendReacCommand: {
      url: content.apis?.canSendReacCommand?.url ?? '',
      method: content.apis?.canSendReacCommand?.method || 'GET'
    },
    reactivateImage: {
      url: content.apis?.sendReacCommand?.url ?? '',
      method: content.apis?.sendReacCommand?.method || 'PUT'
    },
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
