/*       */

import { combineReducers } from 'redux';

import page from '../modules/page/reducer';
import error from '../modules/error/reducer';
import domain from '../modules/domain/reducer';
import hostname from '../modules/hostname/reducer';
import requestInfo from '../modules/requestInfo/reducer';
import geolocation from '../modules/geolocation/reducer';
import selfcare from '../blocks/selfcare/reducers';
import modalGeolocation from '../blocks/modal-geolocation/redux/reducer';
import shopsShared from '../blocks/shops/shared/reducers/index';
import shopsMap from '../blocks/shops/map/reducers/index';
import tvGuide from '../blocks/tv-guide/reducers/index';
import forms from '../blocks/form/reducers/index';
import renewalForm from '../blocks/one-shop/unauthenticated-renewal/reducer/index';
import programPack from '../blocks/program-pack/generic/reducers/index';
import programMEA from '../blocks/program-mea/shared/reducers/index';
import smartFaq from '../blocks/smart-faq/reducers/index';
import vod from '../blocks/vod/reducers';
import eshop from '../blocks/eshop/shared/reducers';
import oneShopSummary from '../blocks/one-shop/subscription-offer-summary/reducers';

// -----------------------------------------------------------------------------
// EXPORTED REDUCER STATE TYPE

// -----------------------------------------------------------------------------
// REDUCER

const shops = combineReducers({
  shared: shopsShared,
  map: shopsMap
});

export default function createRootReducer(routerReducer) {
  return combineReducers({
    router: routerReducer,
    requestInfo,
    domain,
    hostname,
    page,
    error,
    geolocation,
    modalGeolocation,
    shops,
    tvGuide,
    forms,
    renewalForm,
    programPack,
    programMEA,
    selfcare,
    vod,
    smartFaq,
    eshop,
    oneShopSummary
  });
}
