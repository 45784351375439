import React, { useCallback } from 'react';

import Accordion from '../../offers-cards/components/Accordion/Accordion';
import { FormattedGroup } from '../types';

export const Groups = ({
  groups,
  moreChannelsIcon
}: {
  groups: FormattedGroup[];
  moreChannelsIcon: { label: string | null; href: string };
}) => {
  const renderGroup = useCallback(
    (group, index) => (
      <Accordion
        index={index}
        key={group.title}
        title={group.title}
        subtitle={group.subtitle}
        channels={group.channels}
        moreChannelsIcon={moreChannelsIcon}
        isMoreChannels={group.isMoreChannels}
      />
    ),
    [moreChannelsIcon]
  );

  return <>{groups.map(renderGroup)}</>;
};
