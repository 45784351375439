/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { COLORS } from 'shared/constants/theme';
import { DEFAULT_TEMPLATE } from './constants';
import {
  GuideContent,
  ProgressionContent,
  UnderscoreContent,
  TabsContent,
  ImagesContent
} from './type';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content:
    | GuideContent
    | ProgressionContent
    | UnderscoreContent
    | ImagesContent,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
) => ({
  template: content.template ?? DEFAULT_TEMPLATE,
  tabs: content.tabs.map((tab: TabsContent) => ({
    label: tab.title,
    url: getLinkHrefWithAuthentication(tab?.link ?? ''),
    active: tab.active,
    enabled: tab.isEnabled ?? true,
    pictogram: tab.pictogram?.link,
    labelColor: tab.color ?? COLORS.black
  })),
  title: (content as ImagesContent).title,
  currentTabColor:
    (content as UnderscoreContent).colorCurrentTab ?? COLORS.black,
  labelColor: (content as GuideContent).labelsColor ?? COLORS.cerulean,
  backgroundColor:
    (content as GuideContent | ImagesContent).backgroundColor ?? COLORS.white,
  borderColor: (content as GuideContent).bordersColor ?? COLORS.cerulean
});
