import React, { useEffect } from 'react';
import { connect, Field, FieldProps, FormikProps } from 'formik';

import {
  Image,
  OfferCardContainer,
  OfferCardInput,
  Operator,
  Operators,
  OperatorsContainer,
  OperatorName
} from '../styles/components';
import Title from 'shared/components/presentational/Title';
import { Operator as OperatorBaseType } from '../types';
import { getCompatibleOperators } from '../helpers/phoneNumberValidator';
import { CustomTitle } from 'shared/types';
import Hint from 'shared/components/presentational/Form/Hint';

type OperatorType = OperatorBaseType & {
  paymentMeanId?: string;
};

interface FormValues {
  selectedOperator: string;
  phoneNumber: string;
}

interface OperatorsSelectProps {
  title: CustomTitle;
  operators: OperatorType[];
  formik?: FormikProps<FormValues>;
}

function OperatorsSelect({ title, operators, formik }: OperatorsSelectProps) {
  const values = formik?.values || { selectedOperator: '', phoneNumber: '' };

  useEffect(() => {
    if (!formik || !values.phoneNumber) {
      return;
    }
    const compatibleOperator = getCompatibleOperators(
      values.phoneNumber,
      operators
    );

    if (compatibleOperator) {
      formik.setFieldValue('selectedOperator', compatibleOperator);
    }
  }, [values.phoneNumber]);

  if (!formik) return null;

  return (
    <OperatorsContainer>
      <Title titleLabel={title.label} titleHtmlTag={title.htmlTag} />
      <Operators>
        {operators.map(operator => {
          return (
            <OfferCardContainer key={operator.code}>
              <Field name="selectedOperator" type="radio">
                {({ field }: FieldProps<string>) => (
                  <label htmlFor={operator.code}>
                    <OfferCardInput
                      id={operator.code}
                      type="radio"
                      {...field}
                      value={operator.code}
                      checked={values.selectedOperator === operator.code}
                    />
                    <Operator
                      isSelected={values.selectedOperator === operator.code}
                    >
                      <Image url={operator.logo.link.href} />
                      <OperatorName>{operator.label}</OperatorName>
                    </Operator>
                  </label>
                )}
              </Field>
            </OfferCardContainer>
          );
        })}
      </Operators>
      {formik.touched.selectedOperator && formik.errors.selectedOperator && (
        <Hint error={formik.errors.selectedOperator} />
      )}
    </OperatorsContainer>
  );
}

export default connect(OperatorsSelect);
