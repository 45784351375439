import styled from 'styled-components';

import { wrapperStyled } from '../../shared/styles';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { container } from 'shared/constants/mixins';
import Accordion from 'shared/components/presentational/Accordion';
import Raw from 'shared/components/presentational/Raw/Raw';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

export const Container = styled.section`
  background-color: var(--surface-background);
  height: 100%;
  margin: O;
  ${container}
`;

export const StyledAccordion = styled(Accordion)`
  ${wrapperStyled};
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xl);
  gap: var(--spacing-xs, 2px);
`;

export const StyledAccordionHeader = styled(Accordion.Header)<{
  isOpened?: boolean;
}>`
  height: auto;
  color: var(--text-default);
  text-align: left;
  ${props => props.isOpened && `font-weight: ${FONT_WEIGHTS.bold};`};

  background-color: inherit;
  justify-content: space-between;
  border-radius: var(--border-radius-m);
`;

export const StyledAccordionItem = styled(Accordion.Item)<{
  isOpened?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  padding: var(--spacing-xl);
  background-color: ${props =>
    props.isOpened ? 'var(--surface-tertiary)' : 'var(--surface-secondary)'};
  border-radius: var(--border-radius-m);
  margin-bottom: 0;

  ${mediaQueries.fromTablet`
    &:hover {
      background-color: var(--surface-tertiary);
    }
  `}
`;

export const StyledAccordionContent = styled(Accordion.Content as any)``;

export const StyledRaw = styled(Raw)`
  color: var(--text-light);
  font-size: var(--text-size-m);
  p {
    margin-bottom: 0;
  }
`;

export const Text = styled(Raw)`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-top: calc(var(--spacing-m, 15px) * 2);
  text-align: center;
`;

export const StyledQuestion = styled.span`
  font-family: ${FONT_FAMILIES.base};
  font-size: var(--text-size-l);
`;

StyledAccordionHeader.displayName = 'Accordion.Header';
StyledAccordionItem.displayName = 'Accordion.Item';
StyledAccordionContent.displayName = 'Accordion.Content';
