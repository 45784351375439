/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent, ReactNode } from 'react';
import debounce from 'lodash/debounce';
import { stripUnit } from 'polished';

import { SliderSkeleton } from './styles/components';
import { DIMENSIONS } from 'shared/constants/theme';
import breakpoints from 'shared/constants/breakpoints';
import {
  SLIDE_HORIZONTAL_GAP_DESKTOP,
  SLIDE_HORIZONTAL_GAP,
  SLIDE_HORIZONTAL_GAP_TABLET,
  HALF_OF_CONTAINER_INNER_WIDTH
} from './styles/constants';
import { AutoPlaySpeed } from 'shared/types';

const {
  CONTAINER_INNER_WIDTH,
  CONTAINER_MINIMUM_PADDING,
  CONTAINER_MINIMUM_PADDING_TABLET
} = DIMENSIONS;

export interface Props {
  className?: string;
  children: ReactNode;
  slidesToShow: number;
  nextArrow?: React.ReactElement;
  prevArrow?: React.ReactElement;
  showDots?: boolean;
  isCenterMode?: boolean;
  initialSlide?: number;
  isInfinite?: boolean;
  isOneShop?: boolean;
  autoPlaySpeed?: AutoPlaySpeed;
}

interface State {
  computedPadding: number;
  computingPadding: boolean;
  isClientSide: boolean;
}

/**
 * Each slide is padded with half SLIDE_HORIZONTAL_GAP_DESKTOP to produce the desired gap.
 * The computedPadding given to the slider must take into account the padding of the slide.
 */
const MINIMUM_COMPUTED_PADDING =
  CONTAINER_MINIMUM_PADDING - SLIDE_HORIZONTAL_GAP / 2;

const MINIMUM_COMPUTED_PADDING_TABLET =
  CONTAINER_MINIMUM_PADDING_TABLET - SLIDE_HORIZONTAL_GAP_TABLET / 2;

const MINIMUM_COMPUTED_PADDING_DESKTOP =
  CONTAINER_MINIMUM_PADDING_TABLET - SLIDE_HORIZONTAL_GAP_DESKTOP / 2;

class Slider extends PureComponent<Props, State> {
  /**
   * Keep track of the window width in order to filter horizontal resize events.
   */
  private previousWindowWidth = -1;

  /**
   * Because react-slick accepts only absolute values, we need to compute the padding according to viewport width.
   */
  public readonly state = {
    computedPadding: MINIMUM_COMPUTED_PADDING,
    computingPadding: true,
    isClientSide: false
  };

  /**
   * The window resize event is debounce in order to compute the value once after resize is done.
   */
  private handleWindowResize = () => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (this.previousWindowWidth !== windowWidth) {
      this.previousWindowWidth = windowWidth;
      this.setState({ computingPadding: true });
      this.computePadding(windowWidth);
    }
  };

  private computePadding = debounce(windowWidth => {
    /**
     * Bellow tablet breakpoint the computed padding thresholds to the minimum.
     */
    let computedPadding = MINIMUM_COMPUTED_PADDING;

    if (windowWidth > stripUnit(breakpoints.phablet)) {
      computedPadding = MINIMUM_COMPUTED_PADDING_TABLET;
    }

    if (windowWidth > stripUnit(breakpoints.tablet)) {
      computedPadding = MINIMUM_COMPUTED_PADDING_DESKTOP;
    }

    /**
     * When window reaches the size of the container + the minimum padding on tablet (x2),
     * padding starts to be dynamic.
     */
    if (
      windowWidth >
      CONTAINER_MINIMUM_PADDING_TABLET * 2 + CONTAINER_INNER_WIDTH
    ) {
      computedPadding =
        windowWidth / 2 -
        HALF_OF_CONTAINER_INNER_WIDTH -
        SLIDE_HORIZONTAL_GAP_DESKTOP / 2;
    }

    /**
     * Update computedPadding.
     */
    this.setState({
      computedPadding: computedPadding,
      computingPadding: false
    });
  }, 1000);

  public componentDidMount(): void {
    this.setState({ isClientSide: true });

    window.addEventListener('resize', this.handleWindowResize);
    // init first size
    this.handleWindowResize();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  public render(): React.ReactNode {
    const {
      className,
      children,
      slidesToShow,
      autoPlaySpeed,
      ...props
    } = this.props;
    const { computedPadding, computingPadding, isClientSide } = this.state;

    return (
      <SliderSkeleton
        className={className}
        slidesToShow={slidesToShow}
        centerPadding={`${computedPadding}px`}
        hide={computingPadding}
        autoplay={!!autoPlaySpeed}
        autoplaySpeed={autoPlaySpeed}
        pauseOnHover={false}
        key={isClientSide ? 'client' : 'server'}
        data-testid="slider-container"
        {...props}
      >
        {children}
      </SliderSkeleton>
    );
  }
}

export default Slider;
