import styled from 'styled-components';

import { FONT_WEIGHTS } from 'shared/constants/theme';
import Raw from 'shared/components/presentational/Raw/Raw';

const SQUARE_SIZE = 24;
const LOGO_HEIGHT = 30;
const LOGO_WIDTH = 40;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
`;
export const WrapperFeature = styled(Wrapper)`
  gap: var(--spacing-xs);
`;

export const Commitment = styled.div`
  display: flex;
  gap: var(--spacing-s);

  img {
    height: ${SQUARE_SIZE}px;
    width: ${SQUARE_SIZE}px;
  }

  picture {
    width: unset;
  }

  p {
    margin: 0;
  }
`;

export const StyledRaw = styled(Raw)`
  color: var(--text-light);
`;

export const Gift = styled.div`
  display: flex;
  gap: var(--spacing-m);

  img {
    height: ${SQUARE_SIZE}px;
    width: ${SQUARE_SIZE}px;
  }

  picture {
    width: unset;
  }
`;
export const Logos = styled.div`
  display: flex;
  gap: var(--spacing-s);

  img {
    height: ${LOGO_HEIGHT}px;
    width: ${LOGO_WIDTH}px;
  }

  picture {
    width: unset;
  }
`;

export const Title = styled(Wrapper)`
  color: var(--text-title);
  font-size: var(--text-size-m);
  font-weight: ${FONT_WEIGHTS.demi};
  line-height: var(--line-height-m);
`;
export const Label = styled.span`
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
  color: var(--text-light);
`;
export const Text = styled.span`
  line-height: var(--line-height-s);
  font-size: var(--text-size-base);
  color: var(--text-light);
`;
