import { css } from 'styled-components';

interface Props {
  value?: string | number;
  error?: boolean;
  focused?: boolean;
}

export default (props: any) => css<Props>`
  outline: none;
  border-radius: var(--border-radius-m);
  border: 1px solid var(--surface-border-light);

  ${(props.focused || props.value) &&
  css`
    border: 1px solid var(--surface-border-default);
  `};

  ${props.error &&
  css`
    border: 1px solid var(--semantic-error);
  `};
`;
