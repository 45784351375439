/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import Grid from 'shared/components/presentational/Grid/Grid';
import Button from 'shared/components/presentational/Button';
import Card from 'shared/components/presentational/Card/Card';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { CARD_THRESHOLD } from '../constants';
import { Props } from '../types';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.toTablet`
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  `};
`;

const StyledGrid = styled(Grid)<any>`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin: 0 auto 20px;
`;

const cols = {
  hd: 3,
  desktop: 3,
  tablet: 2,
  phablet: 1,
  phone: 1,
  phoneMini: 1
};

interface State {
  cardsToDisplay: number;
}

class ItemGrid extends PureComponent<Props & WithTranslation, State> {
  public readonly state = {
    cardsToDisplay: CARD_THRESHOLD
  };

  /**
   * Private methods
   */

  private handleClick = () => {
    this.setState(state => ({
      cardsToDisplay: state.cardsToDisplay + CARD_THRESHOLD
    }));
  };

  private renderButton = () => {
    const { displayMoreButtonLabel, items } = this.props;

    // We should by default display 9 cards, if there is less or all the cards are displayed, we hide the button
    const shouldDisplayMore = this.state.cardsToDisplay < items.length;
    if (shouldDisplayMore) {
      return (
        <StyledButton
          variant="tertiary"
          onClick={this.handleClick}
          title={displayMoreButtonLabel}
        >
          <Button.children.Text>{displayMoreButtonLabel}</Button.children.Text>
        </StyledButton>
      );
    }
    return null;
  };

  /**
   * Render
   */
  public render(): React.ReactNode {
    const { items, t } = this.props;

    const { cardsToDisplay } = this.state;

    if (!items.length) {
      return (
        <Wrapper>
          <p>{t('blocks.itemGird.noElements')}</p>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <StyledGrid cols={cols}>
          {items.map((item, index) => {
            if (index < cardsToDisplay) {
              return (
                <Card
                  key={index}
                  description={item.text}
                  imageUrl={item.imageUrl}
                  imageAlt={item.imageAlt}
                  label={item.label}
                  buttonLabel={item.buttonLabel}
                  buttonUrl={item.buttonUrl}
                  detailUrl={item.buttonUrl}
                />
              );
            }
          })}
        </StyledGrid>
        {this.renderButton()}
      </Wrapper>
    );
  }
}

export default withTranslation()(ItemGrid);
