import React from 'react';

import { Button } from '../../../shared/components/Button/Button';
import OfferDetailsRoc from '../../../shared/containers/OfferDetailsRocContainer';
import { Wrapper, TitleWrapper, Sticker } from './styles/components';
import Title from 'shared/components/presentational/Title';
import { Groups } from '../Groups';
import { EngagementUnit, FormattedGroup } from '../../types';

export type CardProps = {
  title: string;
  promotionalSticker: string | null;
  offerDetails: {
    availableCommitmentDurations?: string | null;
    commitmentConditions?: string | null;
    engagementDuration?: string | null;
    engagementUnit: EngagementUnit | null;
    netPrice?: number | null;
    price?: number | null;
    currency?: string;
    priceInformation?: string | null;
    promoDuration?: string | null;
  };
  groups: FormattedGroup[];
  primaryButton?: { label: string; link: string } | null;
  secondaryButton?: { label: string; link: string } | null;
  moreChannelsIcon: {
    label: string | null;
    href: string;
  };
  isSliderHidden: boolean;
  isSelected?: boolean;
};

export const Card = ({
  promotionalSticker,
  title,
  offerDetails,
  primaryButton,
  secondaryButton,
  groups,
  moreChannelsIcon,
  isSliderHidden,
  isSelected = false
}: CardProps) => {
  return (
    <Wrapper isSliderHidden={isSliderHidden} isSelected={isSelected}>
      <TitleWrapper hideSticker={promotionalSticker == null}>
        <Sticker label={promotionalSticker} />
        <Title titleLabel={title} titleHtmlTag="h2"></Title>
      </TitleWrapper>
      <OfferDetailsRoc {...offerDetails} />
      {primaryButton ? (
        <Button
          {...primaryButton}
          variant="primary"
          fullWidthMobile
          fullWidthDesktop
        />
      ) : null}
      {secondaryButton ? (
        <Button
          {...secondaryButton}
          variant="secondary"
          fullWidthMobile
          fullWidthDesktop
        />
      ) : null}
      <Groups groups={groups} moreChannelsIcon={moreChannelsIcon} />
    </Wrapper>
  );
};
