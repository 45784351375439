/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { Media, PropsFormatterUtils } from 'shared/types';
import { getLinkHref } from 'shared/helpers/uri';
import {
  Content,
  FormattedProps,
  FormattedSelfcareOfferByCodeArticle,
  SelfcareOffer
} from './types';
import { PRICE_FREQUENCY } from './constants';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getSubscriptionAndOptions: {
    url:
      content.apis?.['selfcare.subscription.get_subscription_and_options']
        ?.url ?? '',
    method:
      content.apis?.['selfcare.subscription.get_subscription_and_options']
        .method || 'GET'
  },
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method ?? 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

const formatImages = (images: Media[]): string[] =>
  images.map((el: Media) => getLinkHref(el.link ?? ''));

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  defaultBackgroundImage: content.image?.link?.href,
  selfcareOfferByCodeArticle: (content.selfcareOffers || []).reduce(
    (acc: FormattedSelfcareOfferByCodeArticle, offer: SelfcareOffer) => ({
      ...acc,
      [offer.codeArticle]: {
        subtitle: offer.subtitle ?? null,
        description: offer.description ?? null,
        redirectButton: {
          label: offer.buttons?.redirectButton?.label ?? '',
          link: getLinkHrefWithAuthentication(
            offer.buttons?.redirectButton?.link ?? ''
          )
        },
        backgroundImage: getLinkHrefWithAuthentication(
          offer.backgroundImage?.link ?? ''
        ),
        primaryImages: formatImages(offer.primaryImages || []),
        secondaryImages: formatImages(offer.secondaryImages || []),
        price: offer.price ?? null,
        priceFrequency: offer.priceFrequency || PRICE_FREQUENCY.MONTHLY
      }
    }),
    {}
  ),
  apis: getFormattedApis(content),
  contextCriteria: content.frontDisplayCriteria ?? null
});
