import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';
import getLinkHref from 'shared/helpers/uri/getLinkHref';
import { EngagementUnit } from '../shared/types';
import { createTemplate } from 'shared/helpers/template';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: content.title,
  subtitle: {
    text: content.subtitle.text,
    icon: content.subtitle.icon?.link
      ? {
          link: getLinkHref(content.subtitle.icon.link),
          alt:
            content.subtitle.icon.description || content.subtitle.icon.filename
        }
      : undefined
  },
  mainProduct: content.mainProduct
    ? {
        productId: content.mainProduct.productId,
        title: content.mainProduct.title,
        price: content.mainProduct.price,
        netPrice: content.mainProduct.netPrice,
        engagementUnit: EngagementUnit[content.mainProduct.engagementUnit],
        engagementDuration: content.mainProduct.engagementDuration,
        summaryInvoiceDetails: content.mainProduct.summaryInvoiceDetails,
        summaryOptionsInformation: {
          text: content.mainProduct.summaryOptionsInformation.text,
          icon: content.mainProduct.summaryOptionsInformation.icon?.link
            ? {
                link: getLinkHref(
                  content.mainProduct.summaryOptionsInformation.icon.link
                ),
                alt:
                  content.mainProduct.summaryOptionsInformation.icon
                    .description ||
                  content.mainProduct.summaryOptionsInformation.icon.filename
              }
            : undefined
        }
      }
    : null,
  minorProducts: content.minorProducts.map(
    ({ engagementUnit, ...product }) => ({
      ...product,
      engagementUnit: EngagementUnit[engagementUnit]
    })
  ),
  form: {
    tosAgreement: content.tosAgreement,
    tosAgreementError: content.tosAgreementError,
    newsletterAgreement: content.newsletterAgreement,
    paymentInformationMessage: {
      text: content.paymentInformationMessage.text,
      icon: content.paymentInformationMessage.icon?.link
        ? {
            link: getLinkHref(content.paymentInformationMessage.icon.link),
            alt:
              content.paymentInformationMessage.icon.description ||
              content.paymentInformationMessage.icon.filename
          }
        : undefined
    },
    subscriptionInformationMessage: content.subscriptionInformationMessage,
    confirmationButton: content.confirmationButton
  },
  redirectPopin: {
    title: content.redirectPopin.title,
    text: content.redirectPopin.text,
    link: {
      url: getLinkHrefWithAuthentication(content.redirectPopin.link),
      label: content.redirectPopin.label
    },
    image: content.redirectPopin.image
      ? {
          url: content.redirectPopin.image?.link.href,
          alt: content.redirectPopin.image?.filename
        }
      : undefined
  },
  errorPopin: {
    title: content.errorPopin.title,
    text: content.errorPopin.text,
    link: {
      url: getLinkHrefWithAuthentication(content.errorPopin.link),
      label: content.errorPopin.label
    },
    image: content.errorPopin.image
      ? {
          url: content.errorPopin.image?.link.href,
          alt: content.errorPopin.image?.filename
        }
      : undefined
  },
  createSubscriptionAPI: ({ appLocations }: { appLocations: string }) => ({
    url: createTemplate(content.apis.crm_subscription.url)({
      salesDevice: content.apis.crm_subscription.parameters?.salesDevice ?? '',
      userId: content.apis.crm_subscription.parameters?.userId ?? '',
      distributorId:
        content.apis.crm_subscription.parameters?.distributorId ?? '',
      operatorCod: content.apis.crm_subscription.parameters?.operatorCod ?? '',
      appLocations
    }),
    method: content.apis.crm_subscription.method
  })
});
