import { SizesByDevice } from 'shared/components/presentational/Picture/types';
import { Media, Link, CustomTitle } from 'shared/types';

export enum BannerTemplate {
  Top = 'alignTop',
  Center = 'alignCenter',
  Left = 'alignLeft',
  Right = 'alignRight',
  TwoImages = 'twoImages',
  AnimationBig = 'animationBig',
  AnimationSmall = 'animationSmall'
}

export type BannerTemplateLayout =
  | 'alignTop'
  | 'alignCenter'
  | 'alignLeft'
  | 'alignRight'
  | 'twoImages';

type BannerTemplateAnimation = 'animationBig' | 'animationSmall';

interface ApashMedia extends Omit<Media, 'link'> {
  link?: Link;
}

export interface AnimationBannerItem {
  logotype: string;
  title?: CustomTitle;
  description: string | null;
  primaryLink: {
    label: string;
    link: Link;
  } | null;
  primaryButton: {
    label: string | null;
    link: Link | null;
  };
  medias: {
    desktopMedia: Media;
    tabletMedia: Media | null;
    mobileMedia: Media | null;
  };
}
export interface LayoutBannerContent {
  title?: CustomTitle;
  subtitle: string | null;
  buttons: {
    bannerButton?: {
      label: string;
      link: {
        rel: string;
        href: string;
      } | null;
    };
  };
  banner: {
    logotype?: string;
    title?: CustomTitle;
    subtitle?: string;
    media?: Media;
    template: BannerTemplateLayout;
    description?: string;
    price?: {
      marketingPriceInteger: number;
      marketingPriceExponent: string;
      marketingPriceTerms: string;
    };
    productExternalId?: string;
  };
  medias?: {
    primaryBanner: {
      desktopMedia: ApashMedia;
      tabletMedia: ApashMedia;
      mobileMedia: ApashMedia;
    };
    secondaryBanner: {
      desktopMedia: ApashMedia;
      tabletMedia: ApashMedia;
      mobileMedia: ApashMedia;
    };
  };
}

export interface AnimationBannerContent {
  banner: {
    template: BannerTemplateAnimation;
    autoPlaySpeed: number | null;
  };
  items: AnimationBannerItem[];
}

export interface LayoutFormattedProps {
  title?: string;
  titleHtmlTag?: string;
  subtitle?: string;
  bannerTitle?: string;
  bannerTitleHtmlTag?: string;
  bannerSubtitle?: string;
  bannerDescription?: string;
  bannerBackgroundImageUrl?: string;
  bannerBackgroundImageAlt?: string;
  bannerTemplate: BannerTemplateLayout;
  bannerButtonLabel?: string;
  logotype?: string;
  primaryMediaDesktop?: string;
  primaryMediaTablet?: string;
  primaryMediaMobile?: string;
  primaryMediaAlt?: string;
  secondaryMediaDesktop?: string;
  secondaryMediaTablet?: string;
  secondaryMediaMobile?: string;
  secondaryMediaAlt?: string;
  bannerButtonUrl: string;
  priceInteger?: number;
  priceExponent?: string;
  priceTerms?: string;
  handleBannerClick?: () => void;
}

export interface AnimationFormattedProps {
  bannerTemplate: BannerTemplateAnimation;
  autoPlaySpeed: number | null;
  items: {
    sticker: string | null;
    title?: string;
    titleHtmlTag?: string;
    description: string | null;
    link: {
      label: string;
      url: string;
    } | null;
    button: {
      label: string | null;
      url: string;
    };
    banner: {
      pictures: SizesByDevice;
    };
  }[];
}

export type Content = LayoutBannerContent | AnimationBannerContent;
