import styled from 'styled-components';
import React, { CSSProperties, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactSelect, {
  ActionMeta,
  OptionsType,
  ValueType,
  CommonProps,
  MenuPlacement,
  Props as SelectProps,
  InputActionMeta
} from 'react-select';
import isArray from 'lodash/isArray';

import DropdownIndicator from 'shared/components/presentational/DropdownIndicator';
import { SelectTheme, StyledWrapper } from './Select.styled';
import { BaseForm } from 'shared/types';

const StyledDropdownIndicator = styled(DropdownIndicator).attrs(
  ({ selectProps: { menuIsOpen } }: DropdownIndicatorProps) => ({
    isOpen: menuIsOpen
  })
)`
  color: var(--icons-default);
  margin: 0 15px 0 5px;
`;

const CustomSelect = styled(ReactSelect)`
  & .ReactSelect__control.ReactSelect__control--is-disabled {
    background-color: var(--surface-secondary);
    border: 1px solid var(--surface-border-light);
  }
`;

export interface OptionType {
  label: string;
  value: string;
}

export interface BaseSelectProps extends BaseForm {
  className?: string;
  onChange?: (
    value: ValueType<OptionType>,
    action?: ActionMeta<OptionType>
  ) => void;
  onInputChange?: (value: string, { action }: InputActionMeta) => void;
  options: OptionsType<OptionType>;
  components?: SelectProps<OptionType>['components'];
  isClearable?: boolean;
  isSearchable?: boolean;
  id?: string;
  filterOption?: () => any;
  styles?: {
    [key: string]: (base: CSSProperties) => CSSProperties;
  };
  menuPlacement?: MenuPlacement;
}

export interface BaseSelectValue {
  value: OptionType | null | undefined;
}

type DropdownIndicatorProps = CommonProps<OptionsType<OptionType>>;

type Props = BaseSelectProps & BaseSelectValue & SelectTheme & WithTranslation;

class Select extends PureComponent<Props & WithTranslation> {
  public render() {
    const {
      onChange,
      onInputChange,
      components,
      options,
      isClearable,
      isSearchable,
      value,
      error,
      t,
      border,
      disabled,
      ...props
    } = this.props;

    const hasValue = isArray(value) ? Boolean(value.length) : Boolean(value);

    return (
      <StyledWrapper hasValue={hasValue} error={error} border={border}>
        <CustomSelect
          isClearable={isClearable}
          isSearchable={isSearchable}
          classNamePrefix="ReactSelect"
          onChange={onChange}
          onInputChange={onInputChange}
          components={{
            // @ts-ignore (types should be optionals and not mandatory)
            DropdownIndicator: StyledDropdownIndicator,
            ...components
          }}
          noOptionsMessage={() => t('common.select.noResult2')}
          options={options}
          value={value}
          isDisabled={disabled}
          {...props}
        />
      </StyledWrapper>
    );
  }
}

export default withTranslation()(Select);
