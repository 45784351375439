import React from 'react';

import SliderOrList from 'shared/components/presentational/SliderOrList/SliderOrList';
import ArticleSlide from './ArticleSlide';
import { FormattedArticle, ListProps } from '../types';

export default function ListSlider({
  articles,
  mediaQueries,
  onArticleClick,
  onArticleHover
}: ListProps) {
  return (
    <SliderOrList slidesToShow={mediaQueries.toTablet ? 2 : 3}>
      {articles.map((article: FormattedArticle, index: number) => {
        let imageUrl = article.mainMedia;

        if (mediaQueries.toPhablet && article.responsiveMedia) {
          imageUrl = article.responsiveMedia;
        }

        return (
          <ArticleSlide
            key={`article-${article.id}-${index}`}
            title={article.title}
            subtitle={article.subtitle}
            imageUrl={imageUrl}
            alt={article.alt}
            slug={article.slug}
            onClick={
              onArticleClick ? () => onArticleClick(article.slug) : undefined
            }
            onHover={
              onArticleHover ? () => onArticleHover(article.slug) : undefined
            }
          />
        );
      })}
    </SliderOrList>
  );
}
