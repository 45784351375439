import React from 'react';
import styled from 'styled-components';

import LegalNotice from 'shared/components/presentational/LegalNotice';
import { Props } from '../types';
import { container } from 'shared/constants/mixins';

const Wrapper = styled.section`
  ${container};
  background-color: var(--surface-primary);
  margin-bottom: 0 !important;
`;

export default function LegalNoticeBlock({
  className,
  title,
  notice,
  pictogram
}: Props) {
  return (
    <Wrapper className={className}>
      <LegalNotice
        title={title}
        notice={notice}
        pictogram={pictogram}
        fontSizes={{ title: 16, notice: 14 }}
      />
    </Wrapper>
  );
}
