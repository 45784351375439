export interface RenewalFormState {
  selectedOffer: string | null;
  minorsOffers: Record<string, boolean>;
  phoneNumber: string;
  selectedOperator: string | null;
  paymentMeanId: string | null;
  token: string;
  correlationId: string;
  equipmentId: string;
  personId: number;
  contractId: number;
  managementAct: string;
}

const defaultState: RenewalFormState = {
  selectedOffer: null,
  minorsOffers: {},
  phoneNumber: '',
  selectedOperator: null,
  paymentMeanId: null,
  token: '',
  correlationId: '',
  equipmentId: '',
  personId: 0,
  contractId: 0,
  managementAct: ''
};

interface UpdateRenewalFormAction {
  type: 'UPDATE_RENEWAL_FORM';
  payload: {
    selectedOffer: string;
    minorsOffers: Record<string, boolean>;
    phoneNumber: string;
    selectedOperator: string;
    paymentMeanId: string;
    equipmentId: string;
    personId: number;
    contractId: number;
    managementAct: string;
  };
}

type AddHeadersToStoreAction = {
  type: 'ADD_HEADERS_TO_STORE';
  payload: {
    token: string;
    correlationId: string;
  };
};

type Actions = AddHeadersToStoreAction | UpdateRenewalFormAction;

export default function renewalForm(state = defaultState, action: Actions) {
  switch (action.type) {
    case 'UPDATE_RENEWAL_FORM':
      return {
        ...state,
        ...action.payload
      };

    case 'ADD_HEADERS_TO_STORE':
      return {
        ...state,
        token: action.payload.token,
        correlationId: action.payload.correlationId
      };

    default:
      return state;
  }
}

export const ADD_HEADERS_TO_STORE = 'ADD_HEADERS_TO_STORE';
export const UPDATE_RENEWAL_FORM = 'UPDATE_RENEWAL_FORM';
