import { useQuery } from 'react-query';

interface YouTubeOembedResponse {
  title: string;
  author_name: string;
  author_url: string;
  type: 'video';
  height: number;
  width: number;
  version: string;
  provider_name: 'YouTube';
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
}

/**
 * Custom hook to fetch oembed data for a YouTube video
 *
 * @param videoId - The YouTube video ID
 * @returns Query result containing the oembed data
 */
export const useYouTubeOembed = (videoId: string) => {
  return useQuery<YouTubeOembedResponse, Error>(
    ['youtube-oembed', videoId],
    async () => {
      const response = await fetch(
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch YouTube oembed data');
      }

      return response.json();
    },
    {
      enabled: !!videoId,
      staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours since oembed data rarely changes
      retry: 2
    }
  );
};
