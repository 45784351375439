/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';
import queryString from 'query-string';

import { Content, FormattedProps, MeaContentApiWithParameters } from './types';
import { createTemplate } from 'shared/helpers/template';
import { PropsFormatterUtils } from 'shared/types';

const formatMeaContentApiToUrl = (
  apiWithParameters: MeaContentApiWithParameters
) => {
  if (!apiWithParameters.parameters || !apiWithParameters.url) {
    return '';
  }
  const { vectorSat, range, theme, queryParam } = apiWithParameters.parameters;
  const template = createTemplate(apiWithParameters.url);
  const urlWithVectorSat = template({ vectorSat: vectorSat.toString() });
  return queryString.stringifyUrl({
    url: urlWithVectorSat,
    query: {
      range,
      [queryParam]: theme
    }
  });
};

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label'),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  template: get(content, 'template'),
  redirectButtonLabel: get(content, 'buttons.redirectButton.label'),
  redirectButtonUrl: getLinkHrefWithAuthentication(
    get(content, 'buttons.redirectButton.link', '')
  ),
  createSlideHref: createTemplate(
    getLinkHrefWithAuthentication(get(content, 'dynamicProgramLink'))
  ),
  meaContentApi: {
    url: formatMeaContentApiToUrl(get(content, 'apis.mea_content')),
    method: get(content, 'apis.mea_content.method')
  }
});
