import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useInitiateRenewal } from '../hooks/useInitiateRenewal';
import { useOrderStatusPolling } from '../hooks/useOrderStatusPolling';
import {
  AlertBoxContainer,
  Wrapper,
  Title,
  Container,
  PendingContainer
} from '../styles/components';
import AlertBox from 'shared/components/presentational/AlertBox/AlertBox';
import Loader from 'shared/components/presentational/Loader';
import { FormattedProps } from '../types';
import Raw from 'shared/components/presentational/Raw/Raw';
import { RenewalFormState } from '../../reducer';

export default function Payment({
  success,
  failure,
  pending,
  apis: { getQrCodeRenewal, getQrCodeOrderStatus },
  saleDeviceId
}: FormattedProps) {
  const { t } = useTranslation();
  const [orderId, setOrderId] = useState<string | null>(null);
  const [correlationId, setCorrelationId] = useState<string | null>(null);

  const renewalFormValues = useSelector(
    (state: { renewalForm: RenewalFormState }) => state.renewalForm
  );
  const { mutate: initiateRenewal, isError, isLoading } = useInitiateRenewal({
    renewalFormValues,
    getQrCodeRenewal,
    saleDeviceId
  });

  const {
    data: orderStatus,
    isError: pollingError,
    error
  } = useOrderStatusPolling({
    getQrCodeOrderStatus,
    orderId,
    correlationId
  });

  useEffect(() => {
    initiateRenewal(undefined, {
      onSuccess: data => {
        setOrderId(data.orderId), setCorrelationId(data.correlationId);
      }
    });
  }, []);

  if (isError || pollingError) {
    return (
      <AlertBoxContainer>
        <AlertBox
          status="error"
          content={t([(error as Error).message, 'common.global.error'])}
        />
      </AlertBoxContainer>
    );
  }

  const statusComponents: Record<string, JSX.Element> = {
    CONFIRMED: (
      <StatusDisplay title={success.title}>
        <AlertBox
          status={success.content.status}
          content={success.content.content}
          link={
            success.content.cta
              ? {
                  label: success.content.cta.label,
                  href: success.content.cta.link.href
                }
              : undefined
          }
        />
      </StatusDisplay>
    ),
    FAILED: (
      <StatusDisplay title={failure.title}>
        <AlertBox
          status={failure.content.status}
          content={failure.content.content}
          link={
            failure.content.cta
              ? {
                  label: failure.content.cta.label,
                  href: failure.content.cta.link.href
                }
              : undefined
          }
        />
      </StatusDisplay>
    ),
    CREATED: (
      <StatusDisplay title={pending.title}>
        <PendingContainer>
          <div>
            <Loader />
          </div>
          {pending.content && <Raw html={pending.content} />}
        </PendingContainer>
      </StatusDisplay>
    )
  };

  if (isLoading) {
    return statusComponents['CREATED'];
  }

  return orderStatus ? statusComponents[orderStatus] : null;
}

const StatusDisplay = ({
  title,
  children
}: {
  title: string | null;
  children: React.ReactNode;
}) => (
  <Wrapper>
    <Container>
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  </Wrapper>
);
