import {
  Content,
  FormattedProps,
  DisneyServiceContent,
  DisneyServiceFormattedProps,
  HboServiceContent,
  HboServiceFormattedProps
} from './types';
import { PropsFormatterUtils } from 'shared/types';
import { DISNEY_PARTNER } from './constants';

export const getFormattedApis = (
  apis: Content['apis']
): FormattedProps['apis'] => ({
  getSubscriberData: {
    url: apis?.getSubscriberData?.url ?? '',
    method: apis?.getSubscriberData?.method || 'GET',
    parameters: apis?.getSubscriberData?.parameters || {}
  },
  getSubscriptionAndOptions: {
    url: apis?.get_subscription_and_options?.url ?? '',
    method: apis?.get_subscription_and_options?.method || 'GET'
  }
});

export const getDisneyFormattedApis = (
  content: DisneyServiceContent
): DisneyServiceFormattedProps['apis'] => ({
  getDisneyActivationToken: {
    url: content.apis?.get_disney_activation_token?.url ?? '',
    method: content.apis?.get_disney_activation_token?.method || 'GET'
  },
  getDisneyActivationStatus: {
    url: content.apis?.get_disney_activation_status?.url ?? '',
    method: content.apis?.get_disney_activation_status?.method || 'GET'
  }
});

function isDisneyServiceContent(
  content: Content['services'][0]
): content is DisneyServiceContent {
  return content.partner === DISNEY_PARTNER;
}

function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps {
  return {
    title: {
      label: content.title.label,
      color: content.title.color,
      htmlTag: content.title.htmlTag
    },
    subtitle: content.subtitle,
    apis: getFormattedApis(content.apis),
    services: content.services.map((service: Content['services'][0]) => {
      if (isDisneyServiceContent(service)) {
        return formatDisneyService(service, getLinkHrefWithAuthentication);
      } else {
        return formatHboService(service, getLinkHrefWithAuthentication);
      }
    }),
    contextCriteria: content.frontDisplayCriteria ?? null
  };
}

function formatDisneyService(
  service: DisneyServiceContent,
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
): DisneyServiceFormattedProps {
  return {
    partner: service.partner,
    description: service.description ?? null,
    imageUrl: service.image?.link?.href ?? '',
    imageAlt: service.image?.filename ?? '',
    activateButton: {
      label: service.activateButton?.label ?? '',
      link: getLinkHrefWithAuthentication(service.activateButton?.link || '')
    },
    alreadyActivatedButton: {
      label: '',
      link: ''
    },
    popin: {
      description: service.popin.description ?? '',
      linkLabel: service.popin.redirectLink.label ?? '',
      link: getLinkHrefWithAuthentication(
        service.popin.redirectLink?.link || ''
      )
    },
    apis: getDisneyFormattedApis(service)
  };
}

function formatHboService(
  service: HboServiceContent,
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
): HboServiceFormattedProps {
  return {
    partner: service.partner,
    description: service.description ?? null,
    imageUrl: service.image?.link?.href ?? '',
    imageAlt: service.image?.filename ?? '',
    activateButton: {
      label: service.activateButton?.label ?? '',
      link: getLinkHrefWithAuthentication(service.activateButton?.link || '')
    },
    alreadyActivatedButton: {
      label: service.alreadyActivatedButton?.label ?? '',
      link: getLinkHrefWithAuthentication(
        service.alreadyActivatedButton?.link || ''
      )
    }
  };
}

export default propsFormatter;
