import { css } from 'styled-components';

export default css`
  .gm-style-iw {
    background-color: var(--surface-primary) !important;

    .gm-ui-hover-effect > span {
      background-color: var(--icons-default) !important;
    }

    .gm-style-iw-d {
      overflow: auto !important;
    }
  }

  .gm-style-iw-tc::after {
    background-color: var(--surface-primary) !important;
  }
`;
