import { css } from 'styled-components';
import get from 'lodash/get';
import { lighten, darken } from 'polished';

import { StyledLoader } from './core/Loader';
import isDarkColor from './helpers/isDarkColor';
import { Props } from './Button';
import {
  COLORS,
  BUTTON_SIZES,
  FONT_WEIGHTS,
  FONT_FAMILIES
} from 'shared/constants/theme';

const primaryButton = css`
  background-color: var(--primary-default);
  color: var(--primary-label-default);

  &:hover {
    background-color: var(--primary-hover);
  }

  &:active {
    background-color: var(--primary-pressed);
  }

  &:disabled {
    cursor: inherit;
    background-color: var(--primary-disabled);
    color: var(--primary-label-disabled);
  }
`;

const secondaryButton = css`
  background-color: var(--secondary-default);
  color: var(--secondary-label-default);

  &:hover {
    background-color: var(--secondary-hover);
  }

  &:active {
    background-color: var(--secondary-pressed);
  }

  &:disabled {
    cursor: inherit;
    background-color: var(--secondary-disabled);
    color: var(--secondary-label-disabled);
  }
`;

const tertiaryButton = css`
  width: fit-content;
  padding: 0;
  color: var(--tertiary-label-default);

  &:hover {
    color: var(--tertiary-label-hover);
  }

  &:active {
    color: var(--tertiary-label-pressed);
  }

  &:disabled {
    cursor: inherit;
    color: var(--tertiary-label-default);
  }
`;

const textButton = css`
  color: var(--text-default);
`;

const defaultButtonWithTheme = css`
  padding: ${props => props.theme.button.padding || '0 40px'};
  border: ${props =>
    get(props, 'theme.button.normal.borderColor') === 'none'
      ? '0'
      : '1px solid'};
  border-radius: ${props => get(props, 'theme.button.borderRadius', '3px')};

  background-color: ${props =>
    get(props, 'theme.button.normal.backgroundColor')};
  border-color: ${props => get(props, 'theme.button.normal.borderColor')};
  color: ${props => get(props, 'theme.button.normal.color')};
  text-decoration: ${props => get(props, 'theme.button.normal.textDecoration')};

  &:hover {
    border-color: ${props => get(props, 'theme.button.hover.borderColor')};
    color: ${props => get(props, 'theme.button.hover.color')};
    background-color: ${props =>
      get(props, 'theme.button.hover.backgroundColor')};
    text-decoration: ${props =>
      get(props, 'theme.button.hover.textDecoration')};
  }

  &:disabled {
    cursor: inherit;
    border-color: ${props => get(props, 'theme.button.disabled.borderColor')};
    color: ${props => get(props, 'theme.button.disabled.color')};
    background-color: ${props =>
      get(props, 'theme.button.disabled.backgroundColor')};
    text-decoration: ${props =>
      get(props, 'theme.button.disabled.textDecoration')};
  }

  ${StyledLoader} {
    border: 3px solid
      ${props =>
        isDarkColor(get(props, 'theme.button.disabled.backgroundColor'))
          ? COLORS.white
          : lighten(0.2, COLORS.boulder)} !important;
    border-top-color: ${props =>
      isDarkColor(get(props, 'theme.button.disabled.backgroundColor'))
        ? darken(0.2, COLORS.white)
        : COLORS.boulder} !important;
  }
`;

export default css<Props>`
  height: ${BUTTON_SIZES.default}px;
  width: ${props => (props.fluid ? '100%' : 'auto')};
  padding: 0 40px;
  cursor: pointer;
  border-radius: var(--border-radius-m);
  font-size: 1rem;

  transition: background-color 250ms ease;
  position: relative;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};

  // inherited from wrapper
  display: ${props => (props.fluid ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;

  ${props =>
    props.variant === 'primary'
      ? primaryButton
      : props.variant === 'secondary'
      ? secondaryButton
      : props.variant === 'tertiary'
      ? tertiaryButton
      : props.variant === 'text'
      ? textButton
      : defaultButtonWithTheme}
`;
