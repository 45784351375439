import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import ConfigHelper from 'shared/helpers/config';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import getHostname from 'shared/modules/hostname/selectors/getHostname';

export type SiteConfig = {
  video_players: {
    dailymotion: {
      player_id: string | null;
    };
  };
};

export const SITE_CONFIG_QUERY_KEY = 'site-config';

export const useSiteConfig = () => {
  const hostname = useSelector(getHostname);
  const baseUrl = ConfigHelper.getClientConfig('api.structure.base_url');

  return useQuery({
    queryKey: [SITE_CONFIG_QUERY_KEY],
    queryFn: () =>
      fetch(`${baseUrl}site-config`, {
        headers: {
          'X-MINISITE-DOMAIN': getMiniSiteDomain(hostname)
        }
      }).then(res => res.json()) as Promise<SiteConfig>
  });
};
