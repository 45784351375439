import { createGlobalStyle } from 'styled-components';

// CSS VENDORS
import 'shared/assets/css/normalize.css';
import 'shared/assets/css/slick/slick.css';
import 'shared/assets/css/slick/slick-theme.css';
import 'shared/assets/css/modal.css';
import 'shared/blocks/selfcare/apash/one-auth/OneAuth/main.css';

import globals from './globals';
import slider from './slider';
import reactGoogleMaps from './reactGoogleMaps';

export default createGlobalStyle`
  ${globals}
  ${slider}
  ${reactGoogleMaps}
`;
