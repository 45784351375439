/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Fragment, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { BaseForm } from 'shared/types';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const StyledLabel = styled.label`
  position: relative;
  display: block;
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.bold};
  margin-bottom: 15px;
  color: var(--text-title);

  span {
    font-weight: ${FONT_WEIGHTS.normal};
  }
`;

const SubLabel = styled.span`
  color: var(--text-light);
`;

const Warning = styled.b`
  margin-left: 8px;
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--semantic-error);
`;

interface Props extends BaseForm {
  label: string | null;
  subLabel?: string | null;
}

export default class Label extends PureComponent<Props> {
  public render(): ReactNode {
    const { className, name, label, subLabel, error, children } = this.props;

    return (
      <StyledLabel htmlFor={name} className={className}>
        <Fragment>
          {label} {subLabel && <SubLabel>{`${subLabel}`}</SubLabel>}
          {error && <Warning>!</Warning>}
        </Fragment>

        {children}
      </StyledLabel>
    );
  }
}
