import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalWrapper, ContainerWrapper } from '../styles/components';
import { Props, TEMPLATES } from '../types';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import Title from 'shared/components/presentational/Title';
import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import formatArticleContent from '../../shared/helpers/formatArticleContent';
import AlternativeModal from '../../../../components/presentational/AlternativeModal';
import ArticleContentComponent from '../../shared/components/ArticleContent';
import ApiError from '../../../../components/presentational/ApiError';
import useArticleContentQuery from '../../shared/hooks/useArticleContent';
import ListSlider from './ListSlider';
import ListGrid from './ListGrid';

function List({
  template,
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  articles,
  mediaQueries,
  onArticleClose,
  onArticleClick,
  onArticleHover,
  url,
  lang,
  miniSiteDomain,
  domainUrl,
  zone
}: Props) {
  const { t } = useTranslation();

  const { data, error, isLoading } = useArticleContentQuery(url, {
    miniSiteDomain,
    lang
  });

  const renderInnerModal = useCallback(() => {
    if (data) {
      const article = formatArticleContent({
        articleContent: data,
        domainUrl,
        zone
      });

      return (
        <ModalWrapper>
          <ArticleContentComponent
            article={article}
            displayAsModal={true}
            mediaQueries={mediaQueries}
          />
        </ModalWrapper>
      );
    }

    if (isLoading) {
      return <Loader />;
    }

    return <ApiError text={t('common.global.error')} />;
  }, [data, error, isLoading]);

  if (articles.length === 0) {
    return null;
  }

  return (
    <>
      <AlternativeModal
        modalProps={{
          isOpen: !!url,
          onCloseRequest: onArticleClose
        }}
      >
        {renderInnerModal()}
      </AlternativeModal>

      <ContainerWrapper>
        {title && (
          <Title
            titleLabel={title}
            subtitle={subtitle}
            titleColor={titleColor}
            titleHtmlTag={titleHtmlTag}
          />
        )}

        {template === TEMPLATES.slider ? (
          <ListSlider
            articles={articles}
            mediaQueries={mediaQueries}
            onArticleClick={onArticleClick}
            onArticleHover={onArticleHover}
          />
        ) : (
          <ListGrid
            articles={articles}
            mediaQueries={mediaQueries}
            onArticleClick={onArticleClick}
            onArticleHover={onArticleHover}
          />
        )}
      </ContainerWrapper>
    </>
  );
}

export default ResponsiveHoc(List);
