/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2019 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import {
  FormattedProps,
  Content
} from 'shared/blocks/selfcare/parent-code/save/types';
import { TitleHtmlTag } from 'shared/types';

export default (content: Content): FormattedProps => ({
  createConfirmationContent: {
    customTitle: {
      title: content.create?.title.label ?? '',
      titleHtmlTag: content.create?.title.htmlTag ?? TitleHtmlTag.h1
    },
    text: content.create?.confirmationText ?? '',
    logoutButton: {
      label: content.create?.logoutLabel ?? ''
    },
    button: {
      label: content.buttons?.create?.label ?? ''
    }
  },

  updateConfirmationContent: {
    customTitle: {
      title: content.update?.title.label ?? '',
      titleHtmlTag: content.update?.title.htmlTag ?? TitleHtmlTag.h1 //AWA-2360
    },
    text: content.update?.confirmationText ?? '',
    logoutButton: {
      label: content.update?.logoutLabel ?? ''
    },
    button: {
      label: content.buttons.update.label ?? ''
    }
  },

  apis: {
    getParentCode: {
      url: content.apis?.get_parent_code?.url ?? '',
      method: content.apis?.get_parent_code?.method || 'POST'
    },
    setParentCode: {
      url: content.apis?.set_parent_code?.url ?? '',
      method: content.apis?.set_parent_code?.method || 'POST'
    },
    sendParentCodeEmail: {
      url: content.apis?.send_parent_code_email?.url ?? '',
      method: content.apis?.send_parent_code_email?.method || 'POST'
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
