import { Content, FormattedProps } from './types';

export default function propsFormatter(content: Content): FormattedProps {
  return {
    label: content.title.label ?? '',
    htmlTag: content.title.htmlTag,
    description: content.description ?? '',
    buttonLabel: content.button?.label ?? '',
    errorFeedback: content.cannotLabel ?? '',
    validationFeedback: content.validationLabel ?? '',
    apis: {
      canResetParentalCode: {
        url: content.apis?.canResetParentalCode.url ?? '',
        method: content.apis?.canResetParentalCode.method || 'GET'
      },
      resetParentalCode: {
        url: content.apis?.resetParentalCode.url ?? '',
        method: content.apis?.resetParentalCode.method || 'PUT'
      },
      getSubscriberData: {
        url: content.apis?.getSubscriberData.url ?? '',
        method: content.apis?.getSubscriberData.method || 'GET',
        parameters: content.apis?.getSubscriberData?.parameters || {}
      }
    },
    contextCriteria: content.frontDisplayCriteria ?? null
  };
}
