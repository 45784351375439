/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/sort-comp */
import React from 'react';
import Modal from 'react-modal';

import Button from 'shared/components/presentational/Button';
import { BaseModalProps } from './types';
import {
  ContentContainer,
  CloseButton,
  StyledDialogContent
} from './styles/components';
import { BUTTON_THEME, CUSTOM_STYLE } from './styles/constants';

const AlternativeModal = ({ children, modalProps = {} }: BaseModalProps) => (
  <Modal
    style={CUSTOM_STYLE}
    bodyOpenClassName="preventScroll"
    {...modalProps}
    ariaHideApp={false}
    onRequestClose={modalProps.onCloseRequest}
  >
    <StyledDialogContent>
      {modalProps.onCloseRequest && (
        <CloseButton theme={BUTTON_THEME} onClick={modalProps.onCloseRequest}>
          <Button.children.SvgIcon
            width="14"
            height="14"
            xlink="/sprite.svg#cos-svg-close"
          />
        </CloseButton>
      )}
      <ContentContainer>{children}</ContentContainer>
    </StyledDialogContent>
  </Modal>
);

export default AlternativeModal;
