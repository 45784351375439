import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const spinCentered = keyframes`
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }

  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
`;

const LoaderItem = styled.div<{ size?: number }>`
  margin: auto;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
  border-color: var(--surface-border-light);
  border-top-color: var(--surface-border-default);
  width: ${props => (props.size ? `${props.size}px` : '24px')};
  height: ${props => (props.size ? `${props.size}px` : '24px')};
  animation: ${spin} 500ms infinite linear;
`;

interface Props {
  className?: string;
  size?: number;
}

export default function Loader({ className, size }: Props) {
  return (
    <LoaderItem
      data-testid="loader"
      aria-label="Loading"
      className={className}
      size={size}
    />
  );
}
