import React, { useCallback, ChangeEvent } from 'react';

import {
  IndexFiltersList,
  IndexFiltersListEntry,
  IndexFiltersCheckbox,
  IndexFiltersListEntryText
} from './styles/components';

interface Props {
  indexFiltersOptions: { label: string; value: string }[];
  indexFiltersValues: string[];
  onIndexFilterToggle: (value: string) => void;
}

export default function IndexFilters({
  indexFiltersOptions,
  indexFiltersValues,
  onIndexFilterToggle
}: Props) {
  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onIndexFilterToggle(event.currentTarget.value),
    [onIndexFilterToggle]
  );

  return (
    <IndexFiltersList data-testid="search-engine-index-filters-list">
      {indexFiltersOptions.map(option => (
        <IndexFiltersListEntry key={option.value}>
          <IndexFiltersCheckbox
            name={option.value}
            checked={indexFiltersValues.includes(option.value)}
            onChange={handleCheckboxChange}
            scale={1.3}
          />

          <IndexFiltersListEntryText>{option.label}</IndexFiltersListEntryText>
        </IndexFiltersListEntry>
      ))}
    </IndexFiltersList>
  );
}
