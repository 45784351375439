import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import ConfigHelper from 'shared/helpers/config/index';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import getCurrentZone from 'shared/modules/page/selectors/getCurrentZone';
import { Media } from 'shared/types';
import { useUserRenewalData } from './useUserRenewalData';

interface Response {
  mobileOperators: {
    code: string;
    label: string;
    logo: Media;
    areaCode: string;
    phonePrefixList: string[];
  }[];
}

export const useMobileOperators = ({
  userRenewalData
}: {
  userRenewalData: ReturnType<typeof useUserRenewalData>['data'];
}) => {
  const currentZone = useSelector(getCurrentZone);
  const hostname = useSelector(getHostname);
  const miniSiteDomain = getMiniSiteDomain(hostname);
  return useQuery({
    queryKey: ['mobileOperators', currentZone],
    queryFn: async () => {
      const baseURL = ConfigHelper.getClientConfig('api.structure.base_url');

      const url = `${baseURL}${currentZone}/mobile-operators`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-Minisite-Domain': miniSiteDomain
        }
      });

      return response.json() as Promise<Response>;
    },
    select: mobileOpsData => {
      const commonOperators = mobileOpsData.mobileOperators.filter(op =>
        userRenewalData?.operators.some(({ id }) => id === op.code)
      );
      return commonOperators.map(op => {
        return {
          ...op,
          paymentMeanId: userRenewalData?.operators.find(
            ({ id }) => id === op.code
          )?.paymentMeanId
        };
      });
    }
  });
};
