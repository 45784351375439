/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { FormattedProps } from '../types';
import ChannelsGroup from 'shared/components/presentational/ChannelsGroup/ChannelsGroup';
import Title from 'shared/components/presentational/Title/components/Title';
import { Wrapper } from '../styles/components';
import { style } from '../styles/variables';
import { SeeMore } from './SeeMore';

const ChannelGroupsStyle = createGlobalStyle` 
  ${style}
  `;

export default function Channels({
  title,
  titleHtmlTag,
  subtitle,
  groups,
  seeMore
}: FormattedProps) {
  const renderChannelGroup = React.useCallback(
    (group, index) => (
      <ChannelsGroup
        key={`${index}-${group.title}`}
        title={group.title}
        channels={group.channels}
        exponent={group.exponent}
        // Only the first 3 accordions should be opened by default
        defaultOpened={index < 3}
      >
        {seeMore && (
          <SeeMore
            link={seeMore.link}
            title={seeMore.label}
            modalProps={{
              title: seeMore.title ?? '',
              channels: group.channels
            }}
          />
        )}
      </ChannelsGroup>
    ),
    [seeMore]
  );

  return (
    <Wrapper data-testid="channels">
      <ChannelGroupsStyle />
      <Title
        subtitle={subtitle}
        titleLabel={title}
        titleHtmlTag={titleHtmlTag}
      />
      {groups.map(renderChannelGroup)}
    </Wrapper>
  );
}
