import React, { PureComponent } from 'react';
import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Icon from '../../Icons/Icon';

interface Props {
  name: string;
  className?: string;
  onClick?: () => void;
  size: number;
  phoneSize: number;
}

const StyledButton = styled.button<Omit<Props, 'name'>>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  &::before {
    display: none;
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon)<Pick<Props, 'size' | 'phoneSize'>>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  ${mediaQueries.toPhablet<{ phoneSize: number }>`
    width: ${props => props.phoneSize}px;
    height: ${props => props.phoneSize}px;
  `};
`;

export default class Arrow extends PureComponent<Props> {
  public static readonly defaultProps = {
    size: 27,
    phoneSize: 18
  };

  public render() {
    const { onClick, name, size, phoneSize, ...props } = this.props;

    return (
      <StyledButton
        onClick={onClick}
        size={size}
        phoneSize={phoneSize}
        {...props}
      >
        <StyledIcon name={name} size={size} phoneSize={phoneSize} />
      </StyledButton>
    );
  }
}
