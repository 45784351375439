import React from 'react';

import { IconProps } from '../types';

export default function Position({
  className,
  width = '1em',
  height = '1em'
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 30"
    >
      <path
        d="M12.0664005,30 C4.02213349,21.8829262 1.67583377e-16,15.7315499 0,11.5458711 C0,5.26735293 4.90487869,2.18051806e-15 12.0664005,0 C19.2279222,0 24,5.16812417 24,11.5458711 C24,15.7977024 20.0221335,21.9490787 12.0664005,30 Z M12.0556986,17.9066766 C15.9071954,17.9066766 19.0294505,14.7998746 19.0294505,10.9674401 C19.0294505,7.13500556 15.9071954,4.02820356 12.0556986,4.02820356 C8.20420171,4.02820356 5.08194661,7.13500556 5.08194661,10.9674401 C5.08194661,14.7998746 8.20420171,17.9066766 12.0556986,17.9066766 Z"
        id="Combined-Shape"
        fill="${fillColor}"
      ></path>
    </svg>
  );
}
