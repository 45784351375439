import get from 'lodash/get';

import getBasketUrl from 'shared/blocks/selfcare/event-list/helpers/getBasketUrl';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';
import { Event, FormattedEvent } from '../types';

export default function getEvents(
  events: Event[],
  basketFallbackUrl: string,
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
): FormattedEvent[] {
  return events.map(event => {
    const basketLink = get(event, 'basket.link', null);

    return {
      label: '',
      code: event.code,
      description: event.description,
      imageUrl: getLinkHref(get(event, 'media.link', '')),
      imageAlt: get(event, 'media.filename', ''),
      basketUrl: getBasketUrl(
        basketLink,
        basketFallbackUrl,
        getLinkHrefWithAuthentication
      ),
      basketLabel: get(event, 'basket.label', '')
    };
  });
}
