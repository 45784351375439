import React from 'react';

import {
  CustomTitle,
  CustomSubtitle,
  Wrapper
} from 'shared/components/presentational/Title/sharedTitles';
import { Props } from '../types';

export default function Title({
  subtitle,
  titleLabel,
  titleHtmlTag,
  isDefaultTitleColor
}: Props) {
  return titleLabel || subtitle ? (
    <Wrapper>
      {titleLabel && (
        <CustomTitle
          htmlTag={titleHtmlTag}
          isDefaultColor={isDefaultTitleColor}
        >
          {titleLabel}
        </CustomTitle>
      )}
      {subtitle && <CustomSubtitle>{subtitle}</CustomSubtitle>}
    </Wrapper>
  ) : null;
}
