import styled from 'styled-components';

import { CustomTitle } from 'shared/components/presentational/Title/sharedTitles';

export const AlertBoxContainer = styled.div`
  margin: var(--spacing-l) var(--spacing-m);
`;

export const Container = styled.section`
  padding: 0 var(--container-minimum-padding);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
`;

export const Title = styled(CustomTitle)`
  --title-position: left;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  background-color: var(--surface-primary);
  padding: var(--spacing-xl);
`;

export const RenewalDateLabel = styled.p`
  color: var(--text-title);
  margin: 0;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionLabel = styled.p`
  margin: 0;
  font-size: var(--text-size-base);
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--surface-primary);
  padding: var(--spacing-xl);
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;
