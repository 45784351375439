import React from 'react';

import { FormattedProps } from '../types';
import { TabsProvider } from '../../shared/components/Tabs/component/Tabs/providers/TabsProvider';
import { TabsWrapper } from './TabsWrapper/TabsWrapper';
import Title from 'shared/components/presentational/Title';
import { Wrapper } from './styles/components';

const OffersCardBlock = ({
  title,
  subtitle,
  displayTabs,
  tabs
}: FormattedProps) => {
  return (
    <Wrapper>
      <Title
        titleLabel={title?.label}
        titleHtmlTag={title?.htmlTag}
        subtitle={subtitle}
      />
      <TabsProvider>
        <TabsWrapper tabs={tabs} displayTabs={displayTabs} />
      </TabsProvider>
    </Wrapper>
  );
};

export default OffersCardBlock;
