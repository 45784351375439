import styled from 'styled-components';

import Arrow from 'shared/components/presentational/Slider/Arrow';
import { COLORS } from 'shared/constants/theme';

export const StyledArrow = styled(Arrow)`
  color: var(--icons-default) !important;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
`;

export const ArrowLeft = styled(Arrow).attrs({
  name: 'ArrowLeft',
  color: COLORS.black,
  size: 15,
  phoneSize: 15
})`
  left: -15px !important;
`;

export const ArrowRight = styled(Arrow).attrs({
  name: 'ArrowRight',
  color: COLORS.black,
  size: 15,
  phoneSize: 15
})`
  right: -15px !important;
`;
