import { useMutation } from 'react-query';

import { Api } from 'shared/types';
import postRenewalOfferSummary from '../services/postRenewalOfferSummary';

type Props = {
  apiPostRenewalOfferSummary: Api;
  saleDeviceId: string | null;
};

export const useRenewalOfferSummary = ({
  apiPostRenewalOfferSummary,
  saleDeviceId
}: Props) => {
  return useMutation(
    ({
      managementAct,
      token,
      correlationId,
      body
    }: {
      managementAct: string;
      token: string;
      correlationId: string;
      body: Record<string, unknown>;
    }) =>
      postRenewalOfferSummary({
        managementAct,
        saleDeviceId,
        apiPostRenewalOfferSummary,
        token,
        correlationId,
        body
      })
  );
};
