import get from 'lodash/get';

import { Content, FormattedProps } from './types';
import { PropsFormatterUtils } from 'shared/types';

export const getFormattedApis = (content: Content): FormattedProps['apis'] => ({
  getSubscriberData: {
    url: content.apis?.getSubscriberData?.url ?? '',
    method: content.apis?.getSubscriberData?.method || 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  },
  getDisneyActivationToken: {
    url: content.apis?.get_disney_activation_token?.url ?? '',
    method: content.apis?.get_disney_activation_token?.method || 'GET'
  },
  getDisneyActivationStatus: {
    url: content.apis?.get_disney_activation_status?.url ?? '',
    method: content.apis?.get_disney_activation_status?.method || 'GET'
  },
  getSubscriptionAndOptions: {
    url: content.apis?.get_subscription_and_options?.url ?? '',
    method: content.apis?.get_subscription_and_options?.method || 'GET'
  }
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label', ''),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  text: content.text ?? null,
  imageUrl: content.image?.link?.href ?? '',
  imageAlt: content.image?.filename ?? '',
  activateButton: {
    label: content.buttons?.redirectButton?.label ?? ''
  },
  redirectPopin: {
    text: content.redirectPopinText ?? '',
    linkLabel: content.redirectPopinLinkLabel ?? ''
  },
  redirectPopinInternalLink: getLinkHrefWithAuthentication(
    content.redirectPopinLink || null
  ),
  apis: getFormattedApis(content),
  contextCriteria: content.frontDisplayCriteria ?? null
});
