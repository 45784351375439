import React from 'react';

import {
  Wrapper,
  Container,
  ImageContainer,
  Link,
  Image,
  TextContainer,
  Description,
  StyledButton
} from '../styles/components';
import { FormattedProps } from '../types';
import Title from 'shared/components/presentational/Title';
import Button from 'shared/components/presentational/Button/Button';

export default function AlternatingPresentation({ items }: FormattedProps) {
  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <Container key={index}>
            <ImageContainer>
              {item.banner.link ? (
                <Link to={item.banner.link}>
                  <Image responsivesUrls={item.banner.pictures} />
                </Link>
              ) : (
                <Image responsivesUrls={item.banner.pictures} />
              )}
            </ImageContainer>
            <TextContainer>
              <Title titleLabel={item.title} titleHtmlTag="h2" />
              {item.description && <Description html={item.description} />}
              {item.callToAction && (
                <StyledButton
                  to={item.callToAction.link}
                  variant={
                    item.callToAction.type === 'cta' ? 'primary' : 'tertiary'
                  }
                >
                  <Button.children.Text>
                    {item.callToAction.label}
                  </Button.children.Text>
                </StyledButton>
              )}
            </TextContainer>
          </Container>
        );
      })}
    </Wrapper>
  );
}
