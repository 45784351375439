import React from 'react';
import { useTranslation } from 'react-i18next';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import Error from 'shared/components/presentational/Error/Error';
import Loader from 'shared/components/presentational/Loader/Loader';
import { useYouTubeOembed } from './hooks/useYouTubeOembed';

interface YouTubePlayerProps {
  id: string;
}

/**
 * Component that renders a YouTube video using LiteYouTubeEmbed
 *
 * @param props - The video props containing the YouTube video ID
 * @returns A lightweight YouTube embed component
 */
function YouTubePlayer({ id }: YouTubePlayerProps) {
  const { t } = useTranslation();
  const { data: oembedData, isLoading, isError } = useYouTubeOembed(id);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Error message={t('components.videoPlayer.errors.youtube.metadata')} />
    );
  }

  return (
    <div data-testid="youtube-player-wrapper">
      <LiteYouTubeEmbed
        id={id}
        poster="hqdefault"
        title={
          oembedData?.title ||
          t('components.videoPlayer.defaultYoutubeTitle', { id })
        }
      />
    </div>
  );
}

export default YouTubePlayer;
