import { CrmResponseData, FormattedData, Offer } from '../types';

export function formatUserData(data: CrmResponseData): FormattedData {
  return {
    equipmentId: data.equipmentId,
    personId: data.personId,
    contractId: data.contractId,
    managementAct: data.managementAct,
    phoneNumber: data.data.internationalPhoneNumber,
    operators: data.data.operators.map(operator => ({
      id: operator.ePaymentOperatorId,
      paymentMeanId: operator.paymentMeanId
    })),
    offers: data.data.offers.productEditorials
      .map(
        (offer): Offer => ({
          id: offer.id,
          title: offer.label,
          major: offer.type.major,
          promotionalSticker: offer.detained,
          minor: offer.type.minor,
          currency: offer.currency.code,
          price: offer.totalCatalogPrice,
          detained: offer.detained,
          forbiddenOptions: offer.dependency.forbidden
        })
      )
      .sort((a, b) => {
        return (b.detained ? 1 : 0) - (a.detained ? 1 : 0);
      })
  };
}
