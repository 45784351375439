import { Api } from 'shared/types';
import { RenewalOfferSummaryData } from '../types';

async function postRenewalOfferSummary({
  managementAct,
  saleDeviceId,
  apiPostRenewalOfferSummary,
  token,
  correlationId,
  body
}: {
  managementAct: string;
  saleDeviceId: string | null;
  apiPostRenewalOfferSummary: Api;
  token: string;
  correlationId: string;
  body: Record<string, unknown>;
}): Promise<RenewalOfferSummaryData> {
  const params = new URLSearchParams({
    managementAct,
    saleDeviceId: saleDeviceId ?? ''
  });

  const url = `${apiPostRenewalOfferSummary.url}?${params.toString()}`;
  const response = await fetch(url, {
    method: apiPostRenewalOfferSummary.method,
    headers: {
      Authorization: `QrcodeBearer ${token}`,
      correlationId,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user data');
  }

  const data = await response.json();
  if (data.status && data.status >= 400) {
    throw new Error('An error occurred');
  }
  return data;
}

export default postRenewalOfferSummary;
