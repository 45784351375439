import React from 'react';
import { useTranslation } from 'react-i18next';

import Error from 'shared/components/presentational/Error/Error';
import { Iframe, WrapperIframe, WrapperPlayer } from './styles/Components';
import YouTubePlayer from './YouTubePlayer';
import DailymotionPlayer from './DailymotionPlayer';

/**
 * Props type for the Video component.
 * Can either be:
 * - A provider-based video (YouTube/Dailymotion) with provider name and video ID
 * - A direct URL to an external video
 */
type VideoProps =
  | { provider: 'youtube' | 'dailymotion'; video_id: string }
  | { url: string };

/**
 * Component that renders different types of external videos
 *
 * Supports:
 * - YouTube videos via LiteYouTubeEmbed
 * - Dailymotion videos via their player iframe
 * - Direct URL videos via iframe
 *
 * @param props - The video props (either provider+id or direct url)
 * @returns A wrapped video player component for the specified video
 */
function VideoExternal(props: VideoProps) {
  const { t } = useTranslation();

  if ('provider' in props) {
    const providerMap = {
      youtube: YouTubePlayer,
      dailymotion: DailymotionPlayer
    };

    if (!(props.provider in providerMap)) {
      return (
        <Error message={t('components.videoPlayer.errors.invalidProvider')} />
      );
    }

    const PlayerComponent = providerMap[props.provider];
    return (
      <WrapperPlayer>
        <PlayerComponent id={props.video_id} />
      </WrapperPlayer>
    );
  }

  return (
    <WrapperIframe>
      <Iframe
        src={props.url}
        frameBorder="0"
        allow="fullscreen"
        data-testid="video-external-iframe"
      />
    </WrapperIframe>
  );
}

export default VideoExternal;
