import { Content, FormattedProps } from './types';
import { EngagementUnit } from '../../shared/types';
import { PropsFormatterUtils } from 'shared/types';

export default function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps {
  const minorProductsEngagementUnit: Record<string, EngagementUnit> = {};
  content.minorProducts.forEach(minor => {
    if (minor.productId && minor.engagementUnit) {
      minorProductsEngagementUnit[minor.productId] =
        EngagementUnit[minor.engagementUnit];
    }
  });

  return {
    title: content.title,
    productPriceLabel: content.productPriceLabel,
    renewalDateLabel: content.renewalDateLabel,
    majorProduct: {
      id: content.product.productId,
      engagementUnit: content.product.engagementUnit
        ? EngagementUnit[content.product.engagementUnit]
        : null
    },
    minorProductsTitle: content.minorProductsTitle,
    minorProductsEngagementUnit,
    totalLabel: content.totalLabel,
    submitButton: {
      label: content.submitButton.label,
      link: getLinkHrefWithAuthentication(content.submitButton.link)
    },
    cancelButtonLabel: content.cancelButton.label,
    saleDeviceId: content.saleDeviceId,
    apiPostRenewalOfferSummary: {
      method: content.apis.crm_management_acts_qrcode_summary.method ?? 'POST',
      url: content.apis.crm_management_acts_qrcode_summary.url ?? ''
    }
  };
}
