import { Content, FormattedProps } from './types';

export default (content: Content): FormattedProps => ({
  title: content.title.label ?? '',
  titleHtmlTag: content.title.htmlTag,
  buttonLabel: content.buttons?.displayMoreButton?.label ?? '',
  apis: {
    getSubscriberData: {
      url: content.apis?.getSubscriberData?.url ?? '',
      method: content.apis?.getSubscriberData?.method || 'GET',
      parameters: content.apis?.getSubscriberData?.parameters || {}
    },
    getPaymentsHistory: {
      url: content.apis?.['selfcare.accouting.get_payments_hist']?.url ?? '',
      method:
        content.apis?.['selfcare.accouting.get_payments_hist']?.method || 'GET'
    }
  },
  contextCriteria: content.frontDisplayCriteria ?? null
});
