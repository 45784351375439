import styled from 'styled-components';
import { Form as BaseForm } from 'formik';

import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import BaseWrapper from 'shared/components/presentational/Wrapper/Wrapper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';

export const Wrapper = styled(BaseWrapper)`
  padding: 0 var(--container-minimum-padding);
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const FadeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  --title-position: left;
`;

export const Subtitle = styled.h2`
  font-family: ${FONT_FAMILIES.brand};
  font-size: var(--text-size-base);
  line-height: var(--line-height-m);
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--text-title);
  margin: 0;
`;

export const UserNumber = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  font-size: var(--text-size-base);
  line-height: var(--line-height-m);
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--text-title);
  margin: 0;
`;

export const AlertBoxContainer = styled.div`
  margin: var(--spacing-l) var(--spacing-m);
`;

export const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
`;

export const CardContainer = styled.div`
  margin: 0 calc(var(--container-minimum-padding) * -1);
  ${mediaQueries.fromTablet`
  margin: 0 calc(var(--container-minimum-padding-tablet) * -1);
  `}
`;

export const CardsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: var(--spacing-xs);

  ${mediaQueries.fromTablet`
    padding: var(--spacing-xs) calc(var(--container-minimum-padding-tablet) - var(--spacing-xs));
  `}
`;

export const OfferCardContainer = styled.div`
  text-align: left;
  color: var(--text-title);
  padding: 0 var(--spacing-xs);
  height: 100%;
`;

export const OfferCardInput = styled.input`
  display: none;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span<{ isDisabled: boolean }>`
  text-decoration: ${props => (props.isDisabled ? 'line-through' : 'none')};
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-m);
  background-color: var(--surface-primary);
  border-radius: var(--border-radius-m);
`;

export const PhoneLabel = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  font-size: var(--text-size-base);
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--text-title);
  margin: 0;
`;

export const OperatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const Operators = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: var(--spacing-m);
`;

const PictureWrapper = styled(BaseWrapper)`
  width: auto;
`;

export const Image = styled(Picture).attrs({ customWrapper: PictureWrapper })`
  padding: var(--spacing-xs);
  max-width: 50px;
  height: 100%;
`;

export const Operator = styled.div<{ isSelected: boolean }>`
  height: 100%;
  display: flex;
  background-color: var(--surface-primary);
  border-radius: var(--border-radius-m);
  row-gap: var(--spacing-xs);
  outline: ${props =>
    props.isSelected ? 'var(--primary-default) solid 2px' : 'none'};
`;

export const OperatorName = styled.p`
  margin: auto var(--spacing-xs);
  font-size: var(--text-size-base);
`;

export const PhoneNumber = styled.p`
  font-family: ${FONT_FAMILIES.new};
  color: var(--text-light);
  margin: 0;
`;
