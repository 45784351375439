import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Configure,
  Index,
  useInstantSearch,
  useSearchBox
} from 'react-instantsearch-hooks-web';

import Icon from 'shared/components/presentational/Icons';
import {
  RefinableSearchResultsWrapper,
  SearchIndicatorsWrapper,
  IndexFiltersWrapper,
  Breadcrumb,
  Chevron,
  RefinementsWrapper,
  IndexFiltersTitle,
  RefinableSearchResultsList,
  IndexWrapper,
  IndexFiltersMobileButton,
  MobileIndexFiltersModal,
  MobileIndexFiltersModalCloseButton,
  MobileIndexFiltersModalButtonsWrapper,
  MobileIndexFiltersModalClearButton,
  MobileIndexFiltersModalApplyButton
} from '../../../header/components/components/SearchEngine/styles/components';
import IndexFilters from '../../../header/components/components/SearchEngine/IndexFilters';
import { ConnectedHits } from './Hits';
import Pagination from '../../../header/components/components/SearchEngine/Pagination';
import VirtualPagination from './VirtualPagination';
import isIndexArticle from '../../../header/components/components/SearchEngine/helpers/isIndexArticle';
import { isFAQIndex } from '../../helper/isFAQIndex';
import { SearchEngine } from '../../types';
import {
  MobileIndexFiltersModalTitle,
  RefinableSearchResultsTitle
} from '../../styles/searchEngine';

interface Props {
  searchEngine: SearchEngine;
  mainColor: string;
  indexFiltersOptions: { label: string; value: string }[];
  indexFiltersValues: string[];
  showMobileFiltersModal: boolean;
  pageLang: string;
  companyId: string;
  smartFaqUuId: string;
  bigram: string;
  onHitClick: () => void;
  onIndexFilterToggle: (value: string) => void;
  clearIndexFilters: () => void;
  toggleMobileFiltersModal: () => void;
}

export default function RefinableSearchResults({
  searchEngine,
  mainColor,
  indexFiltersOptions,
  indexFiltersValues,
  showMobileFiltersModal,
  pageLang,
  companyId,
  smartFaqUuId,
  bigram,
  onHitClick,
  onIndexFilterToggle,
  clearIndexFilters,
  toggleMobileFiltersModal
}: Props) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const { scopedResults } = useInstantSearch();

  const [maxPagesForIndex, setMaxPagesForIndex] = React.useState(1);
  const { query } = useSearchBox();

  React.useEffect(() => {
    // The way we have to set the QuickSearch ad an extra index
    // We have to remove it to have the right pagination

    const unfilteredIndex = scopedResults.slice(1);
    const filteredIndex = unfilteredIndex.filter(index =>
      indexFiltersValues.includes(index.indexId)
    );

    const displayedIndex = filteredIndex.length
      ? filteredIndex
      : unfilteredIndex;

    const pages = displayedIndex.map(index => index.results?.nbPages);
    const maxPagesForIndex = Math.max(...pages);

    setMaxPagesForIndex(maxPagesForIndex);
  }, [indexFiltersValues, scopedResults]);

  const visibleIndicesNames = useMemo(() => {
    if (indexFiltersValues.length === 0) {
      return searchEngine.indices.map(index => index.indexName);
    }

    return searchEngine.indices
      .filter(index => indexFiltersValues.includes(index.indexName))
      .map(index => index.indexName);
  }, [searchEngine.indices, indexFiltersValues]);

  useEffect(() => {
    setCurrentPage(1);
  }, [query, indexFiltersValues]);

  return (
    <>
      <MobileIndexFiltersModal
        visible={showMobileFiltersModal}
        data-testid="search-engine-mobile-filters-modal"
      >
        <MobileIndexFiltersModalCloseButton
          hoverColor={mainColor}
          onClick={toggleMobileFiltersModal}
        >
          <Icon name="Close" width="18px" height="18px" />
        </MobileIndexFiltersModalCloseButton>

        <MobileIndexFiltersModalTitle>
          {t('blocks.searchEngine.mobileIndexFiltersModalTitle')}
        </MobileIndexFiltersModalTitle>

        <IndexFiltersTitle>
          {t('blocks.searchEngine.indexFiltersTitle')}
        </IndexFiltersTitle>

        <IndexFilters
          indexFiltersOptions={indexFiltersOptions}
          indexFiltersValues={indexFiltersValues}
          onIndexFilterToggle={onIndexFilterToggle}
        />

        <MobileIndexFiltersModalButtonsWrapper>
          <MobileIndexFiltersModalClearButton onClick={clearIndexFilters}>
            <MobileIndexFiltersModalClearButton.children.Text>
              {t('blocks.searchEngine.mobileIndexFiltersClearButtonLabel')}
            </MobileIndexFiltersModalClearButton.children.Text>
          </MobileIndexFiltersModalClearButton>

          <MobileIndexFiltersModalApplyButton
            theme={searchEngine.seeAllResultsButtonsTheme}
            onClick={toggleMobileFiltersModal}
          >
            <MobileIndexFiltersModalApplyButton.children.Text>
              {t('blocks.searchEngine.mobileIndexFiltersApplyButtonLabel')}
            </MobileIndexFiltersModalApplyButton.children.Text>
          </MobileIndexFiltersModalApplyButton>
        </MobileIndexFiltersModalButtonsWrapper>
      </MobileIndexFiltersModal>

      <RefinableSearchResultsWrapper data-testid="search-engine-refinable-results-wrapper">
        <SearchIndicatorsWrapper>
          <Breadcrumb>
            {t('blocks.searchEngine.breadcrumbFirstToken')}
            &nbsp;&nbsp;
            <Chevron>{'>'}</Chevron>
            &nbsp;&nbsp;
            {query}
          </Breadcrumb>

          <RefinableSearchResultsTitle>
            {t('blocks.searchEngine.completeDisplayModeTitle')}
            &nbsp;:&nbsp;&quot;
            {query}&quot;
          </RefinableSearchResultsTitle>
        </SearchIndicatorsWrapper>

        <RefinementsWrapper>
          <IndexFiltersMobileButton
            onClick={toggleMobileFiltersModal}
            data-testid="search-engine-filters-mobile-button"
          >
            <IndexFiltersMobileButton.children.Text>
              <Icon name="FunnelFilters" width="16px" height="12px" />

              {t('blocks.searchEngine.indexFiltersTitle')}
            </IndexFiltersMobileButton.children.Text>
          </IndexFiltersMobileButton>

          <IndexFiltersWrapper>
            <IndexFiltersTitle>
              {t('blocks.searchEngine.indexFiltersTitle')}
            </IndexFiltersTitle>

            <IndexFilters
              indexFiltersOptions={indexFiltersOptions}
              indexFiltersValues={indexFiltersValues}
              onIndexFilterToggle={onIndexFilterToggle}
            />
          </IndexFiltersWrapper>

          <RefinableSearchResultsList>
            {searchEngine.indices.map(index => (
              <IndexWrapper
                hidden={!visibleIndicesNames.includes(index.indexName)}
                key={index.indexName}
                data-testid="search-engine-index-hits-wrapper"
              >
                <Index indexName={index.indexName}>
                  <Configure
                    facetFilters={
                      isFAQIndex(index.indexName)
                        ? undefined
                        : [`area:${bigram}`, `lang:${pageLang}`]
                    }
                    filters={
                      isFAQIndex(index.indexName)
                        ? `question.category.thematic.company.id: "${companyId}" AND NOT question.disabled_zones_ids: "${smartFaqUuId}"`
                        : undefined
                    }
                  />
                  <ConnectedHits
                    title={index.searchResultsLabel}
                    hideSeeAllResultsButton={true}
                    onHitClick={onHitClick}
                    isArticle={isIndexArticle(index.indexName)}
                    isFAQIndex={isFAQIndex(index.indexName)}
                  />

                  <VirtualPagination currentPage={currentPage} />
                </Index>
              </IndexWrapper>
            ))}
          </RefinableSearchResultsList>
        </RefinementsWrapper>

        <Pagination
          currentPage={currentPage}
          nbPages={maxPagesForIndex}
          goToPreviousPage={
            currentPage > 1 ? () => setCurrentPage(currentPage - 1) : undefined
          }
          goToNextPage={
            currentPage < maxPagesForIndex
              ? () => setCurrentPage(currentPage + 1)
              : undefined
          }
        />
      </RefinableSearchResultsWrapper>
    </>
  );
}
