import React, { useState } from 'react';

import { FormattedPropal } from '../../types';
import {
  Commitment,
  Link,
  Price,
  Propal as PropalStyled,
  Radio,
  Wrapper,
  Modal
} from './styles/components';
import Icon from 'shared/components/presentational/Icons';
import OfferDetailsRocContainer from 'shared/blocks/one-shop/shared/containers/OfferDetailsRocContainer';
import { SubscriptionCommitment } from '../SubscriptionCommitment/SubscriptionCommitment';
import Picture from 'shared/components/presentational/Picture';
import { Engagment } from '../Engagment/Engagment';
import ModalContent from '../Modal/ModalContent';

export const Propal = ({
  propal,
  selectedPropalId
}: {
  propal: FormattedPropal;
  selectedPropalId?: string;
}) => {
  const { product } = propal;
  const isSelected = selectedPropalId === product.productId;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handlePropalDetailsClick = () => {
    setIsModalOpen(true);
  };

  return (
    <label key={product.title} htmlFor={`product-${product.productId}`}>
      <div key={product.productId}>
        <input
          type="radio"
          name={'product'}
          id={`product-${product.productId}`}
          value={product.productId}
          checked={isSelected}
          hidden
          readOnly
        />
        <input
          type="radio"
          name={'option'}
          id={`option-${product.productId}`}
          value={product.productId}
          checked={isSelected}
          hidden
          readOnly
        />
        {propal.minorProductsIds !== '' && (
          <input
            type="radio"
            name={'minorProducts'}
            value={propal.minorProductsIds}
            checked={isSelected}
            hidden
            readOnly
          />
        )}
        <Wrapper isSelected={isSelected}>
          <Radio>
            {isSelected || selectedPropalId === undefined ? (
              <Icon name="RadioCheck" width={24} height={24} />
            ) : (
              <Icon name="RadioDefault" width={24} height={24} />
            )}
          </Radio>
          <PropalStyled>
            <Engagment
              title={product.title}
              definition={product.description}
              sticker={product.promotionalSticker}
            />
            <Commitment>
              {propal.image?.link && (
                <Picture url={propal.image.link} alt={propal.image.alt} />
              )}
              <SubscriptionCommitment
                featuresTitle={propal.featuresTitle}
                features={propal.features}
                gifts={propal.gifts}
              />
            </Commitment>
            <Price>
              <OfferDetailsRocContainer
                {...product.offerDetails}
                variant="selection"
              />
              {propal.propalDetailsButtonText && (
                <Link onClick={handlePropalDetailsClick}>
                  {propal.propalDetailsButtonText}
                </Link>
              )}
            </Price>
          </PropalStyled>
        </Wrapper>

        <Modal
          modalProps={{
            isOpen: isModalOpen,
            onCloseRequest: handleClose,
            closeIconSize: '10',
            shouldCloseOnOverlayClick: true
          }}
        >
          {propal.modal && (
            <ModalContent {...propal.modal} handleClose={handleClose} />
          )}
        </Modal>
      </div>
    </label>
  );
};
