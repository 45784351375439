import ReactModal from 'react-modal';
import styled from 'styled-components';

import {
  FONT_FAMILIES,
  FONT_WEIGHTS,
  BORDER_RADIUS
} from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import BasePicture from 'shared/components/presentational/Picture';
import Raw from 'shared/components/presentational/Raw/Raw';
import Button from 'shared/components/presentational/Button';

export const Modal = styled(ReactModal).attrs({
  style: { overlay: { zIndex: 300 } }
})``;

export const Content = styled.div`
  border-radius: ${BORDER_RADIUS}px;
  background: var(--surface-background);
  display: flex;
  flex-direction: column;
  max-width: 335px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 20px;

  ${mediaQueries.fromTablet`
    flex-direction: row;
    margin: 0;
    max-width: 1000px;
  `};
`;

export const Title = styled.h3`
  color: var(--text-title);
  margin: 0 0 8px;
  padding: 0;
  text-align: center;
  line-height: normal;
  font-size: ${pxInRem(36)};
  font-weight: ${FONT_WEIGHTS.normal};
  max-width: 100%;
  font-family: ${FONT_FAMILIES.brand};

  ${mediaQueries.fromTablet`
    margin-bottom: 16px;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  ${mediaQueries.fromTablet`
    padding: 30px;
  `}
`;

export const StyledRaw = styled(Raw)`
  color: var(--text-light);
`;

export const StyledButton = styled(Button)`
  font-weight: ${FONT_WEIGHTS.demi};
`;
export const ButtonText = Button.children.Text;

export const PictureWrapper = styled.div`
  min-width: 100%;

  ${mediaQueries.fromTablet`
    min-width: 380px;
    height: 380px;
  `};
`;

export const Picture = styled(BasePicture)`
  border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
  object-fit: cover;
  width: 100%;
  height: 278px;

  ${mediaQueries.fromTablet`
    border-radius: var(--border-radius-m) 0 0 var(--border-radius-m);
    width: auto;
    height: 380px;
  `}
`;
