import React from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { MultipleQueriesQuery } from '@algolia/client-search';
import { useTranslation } from 'react-i18next';

import { FormattedAlgoliaConfigExtended } from '../../types';
import HeaderWithSearchLayout from '../HeaderWithSearchLayout';
import {
  InnerModal,
  SearchEnginePanel,
  SearchEngineWrapper,
  SearchIcon,
  SearchTrigger,
  SearchEnginePanelWidthHolder,
  MobileSeeAllResultsButtonWrapper,
  SeeAllResultsButton
} from '../../styles/searchEngine';
import { useSearchInputWidth } from '../../hooks/useSearchInputWidth';
import {
  getInitialState,
  reducer,
  SearchEngineActionType as ActionType
} from '../../helper/reducer';
import { PropsFromState } from '../../containers/SearchEngine';
import HeaderSearch from './HeaderSearch';
import SearchResultsGrid from './SearchResultsGrid';
import RefinableSearchResults from './RefinableSearchResults';
import Picture from 'shared/components/presentational/Picture';
import { ModalOverlay } from './Overlay';

interface Props {
  userMenuRef: any;
  menuParentRef: any;
  logoRef: any;
  algolia: FormattedAlgoliaConfigExtended;
}

const emptyResponse = {
  hits: [],
  nbHits: 0,
  nbPages: 0,
  page: 0,
  processingTimeMS: 0,
  hitsPerPage: 0,
  exhaustiveNbHits: false,
  query: '',
  params: ''
};

const MINIMUM_QUERY_LENGHT = 2;

const shoulBeBlocked = (requests: readonly MultipleQueriesQuery[]) =>
  requests.every(
    ({ params }) =>
      !params?.query || params?.query.length < MINIMUM_QUERY_LENGHT
  );

export const SearchEngine = ({
  userMenuRef,
  menuParentRef,
  logoRef,
  algolia: {
    icon,
    theme,
    searchButton,
    searchEngine,
    seeAllResultsButtons,
    api: { appId, apiKey }
  },
  companyId,
  smartFaqUuId,
  bigram,
  pageLang
}: Props & PropsFromState) => {
  const { t } = useTranslation();

  const hasShortcuts = searchEngine?.shortcuts?.shortcutsItems
    ? searchEngine?.shortcuts?.shortcutsItems.length > 0
    : false;

  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState(hasShortcuts)
  );

  const searchResultsPanel = React.useRef<HTMLDivElement | null>(null);
  const searchTriggerRef = React.useRef<HTMLButtonElement | null>(null);

  const refs = {
    searchTriggerRef,
    userMenuRef,
    menuParentRef,
    logoRef
  };
  const { inputWidth } = useSearchInputWidth(state.isOpened, refs);

  const algoliaClient = algoliasearch(appId, apiKey);
  const searchClient: Pick<SearchClient, 'search' | 'clearCache'> = {
    ...algoliaClient,
    search(requests: readonly MultipleQueriesQuery[]) {
      if (shoulBeBlocked(requests)) {
        return Promise.resolve({
          results: requests.map(() => emptyResponse)
        });
      }

      return algoliaClient.search(requests);
    }
  };

  const handleSeeAllResultsButtonClick = React.useCallback(
    (indexName?: string) =>
      dispatch({ type: ActionType.CLICK_SEE_ALL_RESULTS, indexName }),
    [dispatch]
  );

  const handleIndexFilterToggle = React.useCallback(
    (indexName: string) =>
      dispatch({ type: ActionType.TOGGLE_INDEX_REFINEMENT, indexName }),

    [dispatch]
  );

  const handleIndexFiltersClear = React.useCallback(
    () => dispatch({ type: ActionType.CLEAR_INDEX_REFINEMENT }),
    [dispatch]
  );

  const handleShowResult = React.useCallback(
    value => dispatch({ type: ActionType.SEARCH, value }),
    [dispatch]
  );

  const onCloseRequest = () => dispatch({ type: ActionType.CLOSE });

  const handleCloseSearchEngine = React.useCallback(() => {
    onCloseRequest();
    searchClient.clearCache();
    //clear();
  }, [onCloseRequest]);

  const [showMobileFiltersModal, setShowMobileFiltersModal] = React.useState(
    false
  );

  const toggleMobileFiltersModal = React.useCallback(() => {
    setShowMobileFiltersModal(!showMobileFiltersModal);
  }, [showMobileFiltersModal]);

  const indexFiltersOptions = React.useMemo(
    () =>
      searchEngine.indices.map(index => ({
        label: index.searchResultsLabel,
        value: index.indexName,
        checked: state.indexFilters.includes(index.indexName)
      })),
    [searchEngine.indices, state.indexFilters]
  );

  const resultsPanel = React.useMemo(() => {
    return (
      <div data-testid="search-engine-results-panel">
        <SearchEnginePanelWidthHolder>
          <SearchEnginePanel
            ref={searchResultsPanel}
            color={theme.link.linksColor}
          >
            {state.resultsDisplayMode === 'simple' ? (
              <SearchResultsGrid
                searchEngine={searchEngine}
                onSeeAllResultsButtonClick={handleSeeAllResultsButtonClick}
                onHitClick={handleCloseSearchEngine}
                bigram={bigram}
                pageLang={pageLang}
                companyId={companyId}
                smartFaqUuId={smartFaqUuId}
              />
            ) : (
              <RefinableSearchResults
                mainColor={theme.link.linksColorSecondary}
                searchEngine={searchEngine}
                indexFiltersOptions={indexFiltersOptions}
                indexFiltersValues={state.indexFilters}
                onIndexFilterToggle={handleIndexFilterToggle}
                clearIndexFilters={handleIndexFiltersClear}
                onHitClick={handleCloseSearchEngine}
                showMobileFiltersModal={showMobileFiltersModal}
                toggleMobileFiltersModal={toggleMobileFiltersModal}
                pageLang={pageLang}
                companyId={companyId}
                smartFaqUuId={smartFaqUuId}
                bigram={bigram}
              />
            )}
          </SearchEnginePanel>
        </SearchEnginePanelWidthHolder>
      </div>
    );
  }, [
    state.resultsDisplayMode,
    state.searchValue,
    state.indexFilters,
    state.showShortcuts,
    state.showResults,
    indexFiltersOptions,
    handleSeeAllResultsButtonClick,
    handleIndexFilterToggle,
    handleIndexFiltersClear,
    handleCloseSearchEngine
  ]);

  return (
    <SearchEngineWrapper>
      {!showMobileFiltersModal && (
        <SearchTrigger
          ref={searchTriggerRef}
          colors={theme.link}
          theme={searchButton}
          isSearchOpen={state.isOpened}
          navigationWidth={inputWidth.navigationWidth}
          inputWidth={inputWidth.inputWidth}
          userMenuElementWidth={inputWidth.elUserMenu}
          onClick={() => dispatch({ type: ActionType.OPEN })}
        >
          {icon ? (
            <Picture width={20} height={20} url={icon.link} alt={icon.alt} />
          ) : (
            <SearchIcon name="Search" width={20} height={20} />
          )}
        </SearchTrigger>
      )}
      <InstantSearch
        // `indexName` value here has no real impact on the search as we use Index
        // for multiple-indices search, just setting the first actual visible index for "consistency"
        indexName={
          state.indexFilters.length === 1
            ? state.indexFilters[0]
            : searchEngine.indices[0].indexName
        }
        searchClient={searchClient}
      >
        <HeaderWithSearchLayout
          isSearchOpen={state.isOpened}
          inputWidth={inputWidth}
        >
          <HeaderSearch
            onClose={handleCloseSearchEngine}
            closeButtonTheme={searchButton}
            isSearchOpen={state.isOpened}
            theme={theme}
            handleShowResult={handleShowResult}
          />

          <InnerModal
            backgroundColor={theme.backgroundColorModals}
            data-testid="search-engine-modal"
          >
            {(state.showResults || state.showShortcuts) && resultsPanel}
          </InnerModal>
        </HeaderWithSearchLayout>
      </InstantSearch>
      {state.showResults &&
        state.resultsDisplayMode === 'simple' &&
        state.isOpened && (
          <MobileSeeAllResultsButtonWrapper>
            <SeeAllResultsButton
              data-testid="search-engine-mobile-see-all-results-button"
              onClick={() => handleSeeAllResultsButtonClick()}
              theme={searchEngine.seeAllResultsButtonsTheme}
            >
              <SeeAllResultsButton.children.Text>
                {/* TODO : change when searchEngine.mobileSeeAllResultsButtonLabel is ready*/}
                {seeAllResultsButtons.mobileSeeAllResultsButtonLabel ===
                'mobileLabel'
                  ? t(`${seeAllResultsButtons.mobileSeeAllResultsButtonLabel}`)
                  : seeAllResultsButtons.mobileSeeAllResultsButtonLabel}
              </SeeAllResultsButton.children.Text>
            </SeeAllResultsButton>
          </MobileSeeAllResultsButtonWrapper>
        )}
      {state.isOpened && (
        <ModalOverlay
          userMenuWidth={inputWidth.elUserMenu}
          onClick={handleCloseSearchEngine}
        />
      )}
    </SearchEngineWrapper>
  );
};
