import React from 'react';
import { Index } from 'react-instantsearch-dom';

import { Grid, ShortcutsWrapper } from './styles/components';
import { FormattedAlgoliaConfig } from '../../../types';
import ConnectedHits, { Hits } from './Hits';
import isIndexArticle from './helpers/isIndexArticle';

interface Props {
  searchEngine: FormattedAlgoliaConfig;
  onSeeAllResultsButtonClick: (indexName: string) => void;
  onHitClick: () => void;
  showResults: boolean;
  showShortcuts: boolean;
}

export default function SearchResultsGrid({
  searchEngine,
  onSeeAllResultsButtonClick,
  onHitClick,
  showResults,
  showShortcuts
}: Props) {
  if (showResults) {
    return (
      <Grid>
        {searchEngine.indices.map(index => (
          <div
            key={index.indexName}
            data-testid="search-engine-hits-list-wrapper"
          >
            <Index indexName={index.indexName}>
              <ConnectedHits
                title={index.searchResultsLabel}
                seeAllResultsButtonLabel={index.seeAllResultsButtonLabel}
                seeAllResultsButtonTheme={
                  searchEngine.seeAllResultsButtonsTheme
                }
                onSeeAllResultsButtonClick={() =>
                  onSeeAllResultsButtonClick(index.indexName)
                }
                onHitClick={onHitClick}
                isArticle={isIndexArticle(index.indexName)}
              />
            </Index>
          </div>
        ))}
      </Grid>
    );
  } else if (showShortcuts) {
    return (
      <ShortcutsWrapper
        data-testid="search-engine-shortcuts"
        key={searchEngine.shortcutsTitle}
      >
        <Hits
          title={searchEngine.shortcutsTitle!}
          onHitClick={onHitClick}
          hits={searchEngine.shortcuts!.map(shortcut => ({
            objectID: shortcut.id,
            title: shortcut.title,
            link: shortcut.link,
            _highlightResult: {}
          }))}
        />
      </ShortcutsWrapper>
    );
  }

  return null;
}
