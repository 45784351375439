import React, { Component } from 'react';
import styled from 'styled-components';

import Icon from '../Icons';
import { DATA_TEST_IDS } from './constants';
import { Props } from 'shared/components/presentational/ApiError/type';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { getElementPosition, scrollTo } from 'shared/helpers/viewport';

const Error = styled.div`
  flex-basis: 100%;
  color: var(--semantic-error);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 165px;
  ${mediaQueries.toTablet`
    padding: 25px 75px;
  `}
  ${mediaQueries.toPhablet`
    text-align: center;
    flex-direction: column;
    padding: 15px 0;
  `};
`;

const ErrorIcon = styled(Icon).attrs({ name: 'Warning' })`
  width: 30px;
  height: 30px;
  fill: var(--semantic-error);
  margin-right: 30px;
  ${mediaQueries.toPhablet`
    margin-right: 0;
    margin-bottom: 15px;
  `};
`;

export default class ApiError extends Component<Props> {
  public componentDidMount(): void {
    if (this.props.id) {
      scrollTo(getElementPosition(`#${this.props.id}`));
    }
  }

  public render(): React.ReactNode {
    const { text, id } = this.props;

    return (
      <Error data-testid={DATA_TEST_IDS.WRAPPER} id={id}>
        <ErrorIcon />
        {text}
      </Error>
    );
  }
}
