import React from 'react';

import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import { CardsWrapper, SlideWrapper, CardSlider } from './styles/components';
import { FormattedCardTab } from '../../types';
import { Card } from 'shared/blocks/one-shop/shared/components/Card/Card';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

interface Props {
  content: FormattedCardTab['contents'];
  tabIndex: number;
}

const SLIDES_TO_SHOW = 3.4;
const MINIMUM_CARD_FOR_SLIDER = 4;

function TabContentCards({
  content,
  tabIndex,
  mediaQueries: { toDesktop }
}: Props & ResponsiveHocProps) {
  const isSliderHidden = content.length < MINIMUM_CARD_FOR_SLIDER || toDesktop;

  return isSliderHidden ? (
    <CardsWrapper>
      {content.map((product, index) => (
        <SlideWrapper
          key={`${index}-${product.title}-${tabIndex}`}
          slidesToShow={SLIDES_TO_SHOW}
          data-index={`${index}-${product.title}-${tabIndex}`}
        >
          <Card isSliderHidden={isSliderHidden} {...product} />
        </SlideWrapper>
      ))}
    </CardsWrapper>
  ) : (
    <CardSlider
      showDots={false}
      isCenterMode={false}
      isInfinite={false}
      initialSlide={0}
      nextArrow={<OneShopArrow name="ArrowLeftOneShop" />}
      prevArrow={<OneShopArrow name="ArrowLeftOneShop" />}
      slidesToShow={SLIDES_TO_SHOW}
    >
      {content.map((product, index) => (
        <SlideWrapper
          key={`${index}-${product.title}-${tabIndex}`}
          slidesToShow={SLIDES_TO_SHOW}
          data-index={`${index}-${product.title}-${tabIndex}`}
        >
          <Card isSliderHidden={isSliderHidden} {...product} />
        </SlideWrapper>
      ))}
    </CardSlider>
  );
}

export default ResponsiveHoc(TabContentCards);
