import React, { PureComponent } from 'react';
import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES } from 'shared/constants/theme';
import { DefaultTab } from 'shared/blocks/tabs/type';

const StepDot = styled.div<{
  tab: DefaultTab;
  color?: string;
  onClick?: () => void;
}>`
  display: block;
  position: relative;
  border: 4px solid var(--surface-background);
  height: 40px;
  flex-basis: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${props =>
    props.tab.enabled ? 'var(--primary-default)' : 'var(--secondary-disabled)'};
  color: ${props =>
    props.tab.enabled
      ? 'var(--primary-label-default)'
      : 'var(--secondary-label-disabled)'};
  font-size: ${pxInRem(15)};
  font-family: ${FONT_FAMILIES.base};
  line-height: 32px;
  text-align: center;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  :after {
    content: '${props => props.tab.label}';
    display: block;
    position: absolute;
    font-size: ${pxInRem(12)};
    line-height: ${pxInRem(16)};
    left: 16px;
    top: 42px;
    white-space: normal;
    transform: translateX(-50%);
    color: ${props =>
      props.tab.enabled
        ? 'var(--tertiary-label-default)'
        : 'var(--secondary-label-disabled)'};
  }
`;

interface Props {
  tab: DefaultTab;
  onClick: (url: string) => void;
}

export default class Step extends PureComponent<Props> {
  private handleClick = () => {
    const { onClick, tab } = this.props;
    onClick(tab.url);
  };

  public render(): React.ReactNode {
    const { handleClick } = this;
    const { tab, children } = this.props;
    return (
      <StepDot tab={tab} onClick={tab.enabled ? handleClick : undefined}>
        {children}
      </StepDot>
    );
  }
}
