import { Content, FormattedProps } from './types';

export default function propsFormatter(content: Content): FormattedProps {
  return {
    pending: {
      title: content.pending.title.label,
      titleHtmlTag: content.pending.title.htmlTag,
      content: content.pending.content
    },
    success: {
      title: content.success.title.label,
      titleHtmlTag: content.success.title.htmlTag,
      content: content.success.content
    },
    failure: {
      title: content.failure.title.label,
      titleHtmlTag: content.failure.title.htmlTag,
      content: content.failure.content
    },
    saleDeviceId: content.saleDeviceId,
    apis: {
      getQrCodeRenewal: {
        url: content.apis?.crm_management_acts_qrcode_renewal.url ?? '',
        method:
          content.apis?.crm_management_acts_qrcode_renewal.method || 'POST'
      },
      getQrCodeOrderStatus: {
        url: content.apis?.crm_management_acts_qrcode_orderstatus.url ?? '',
        method:
          content.apis?.crm_management_acts_qrcode_orderstatus.method || 'GET'
      }
    }
  };
}
