/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { getLinkHref } from 'shared/helpers/uri';
import { Content, Item } from './types';
import { PropsFormatterUtils } from 'shared/types';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
) => {
  const handleItemsLinks = (
    items: Item[],
    getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
  ) =>
    items.map(item => ({
      label: item.title,
      text: item.text,
      imageUrl: getLinkHref(get(item, 'media.link', '')),
      imageAlt: get(item, 'media.filename', ''),
      buttonUrl: getLinkHrefWithAuthentication(get(item, 'button.link', '')),
      buttonLabel: get(item, 'button.label', '')
    }));

  return {
    items: handleItemsLinks(
      get(content, 'items', []),
      getLinkHrefWithAuthentication
    ),
    displayMoreButtonLabel: get(content, 'buttons.displayMoreButton.label')
  };
};
