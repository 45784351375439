import React from 'react';
import { Link } from 'react-router-dom';

import { InsetSpan } from '../styles/components';

export const ConditionalLink = ({
  href,
  children,
  onClick
}: {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  if (!onClick) {
    return <>{children}</>;
  }
  return (
    <Link
      to={href}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      <InsetSpan />
      {children}
    </Link>
  );
};
