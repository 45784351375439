import React, { FC, useCallback } from 'react';

import {
  FormattedFeature as FeatureType,
  FormattedGift as GiftType
} from '../../types';
import {
  Wrapper,
  Title,
  Commitment,
  Label,
  WrapperFeature,
  Logos,
  StyledRaw
} from './styles/components';
import Picture from 'shared/components/presentational/Picture';

export const SubscriptionCommitment: FC<{
  featuresTitle: string | null;
  features: FeatureType[] | null;
  gifts: GiftType[] | null;
}> = ({ featuresTitle, features, gifts }) => {
  const renderLogo = useCallback(
    logo => <Picture key={logo.link} url={logo.link} alt={logo.alt} />,
    []
  );

  const renderFeature = useCallback(
    (feature: FeatureType) => (
      <Commitment key={feature.title}>
        {feature.icon.media?.link && (
          <Picture
            url={feature.icon.media?.link}
            alt={feature.icon.media?.alt}
          />
        )}
        <WrapperFeature>
          <Label>{feature.title}</Label>
          <Logos>{feature.logos && feature.logos.map(renderLogo)}</Logos>
        </WrapperFeature>
      </Commitment>
    ),
    [renderLogo]
  );

  const renderGift = useCallback(
    (gift: GiftType) => (
      <Commitment key={gift?.text}>
        <Picture url={gift?.icon?.link || ''} alt={gift?.icon?.alt} />
        <StyledRaw html={gift.text} />
      </Commitment>
    ),
    []
  );

  return (
    <Wrapper>
      <Title>{featuresTitle}</Title>
      <Wrapper>{features && features.map(renderFeature)}</Wrapper>
      <Wrapper>{gifts && gifts.map(renderGift)}</Wrapper>
    </Wrapper>
  );
};
