import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseButton from 'shared/components/presentational/Button';

export const StyledDialogContent = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--surface-background);
  overflow-y: auto;
  border-radius: var(--border-radius-xxl);
  width: 100%;
  height: 100%;

  ${mediaQueries.fromTablet`
        max-width: 1280px;
        height: auto;
        max-height: 95vh;
        border-radius: var(--border-radius-xxl);
    `}

  img {
    width: 100%;
  }
`;

export const CloseButton = styled(BaseButton)`
  z-index: 1;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  color: var(--icons-default);
  position: sticky;
  top: 15px;
  right: 15px;
  background-color: var(--secondary-default);
  padding: 0;
  margin-left: auto;
  border: none;

  span {
    line-height: 0;
  }

  &:hover {
    background-color: var(--secondary-hover);
  }
`;

export const ContentContainer = styled.div`
  margin-top: -30px;
`;
