import React from 'react';

import { Button as ButtonType } from '../../types';
import { Wrapper, PreviousButton, NextButton } from './styles/components';

export const Buttons = ({
  nextButton,
  previousButton
}: {
  nextButton?: ButtonType;
  previousButton?: ButtonType;
}) => {
  if (!nextButton && !previousButton) {
    return null;
  }

  return (
    <Wrapper>
      {nextButton?.label && (
        <NextButton
          type="submit"
          label={nextButton?.label}
          fullWidthMobile
          variant="primary"
        />
      )}
      {previousButton?.label && previousButton?.link && (
        <PreviousButton
          fullWidthMobile
          label={previousButton.label}
          link={previousButton.link}
          variant="secondary"
        />
      )}
    </Wrapper>
  );
};
