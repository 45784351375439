export const DISNEY_PARTNER = 'disney_plus';
export const HBO_PARTNER = 'hbo_max';

// DISNEY
export enum STATUS_CODE {
  SUCCESS = 200,
  CREATED = 201
}

export const DISNEY_PLUS_ACTIVATION_URL =
  '{baseUrl}activate?token={activationToken}&providerId=canal';

export const DISNEY_KEY = 'DISNEY';

// Eligibility includes `RDISN` and `RDISNC`.
// Only `RDISN` is needed because we use lodash/includes and collection is a string
// @See https://jira.toolbox.canal-overseas.com/browse/AWA-1484
export const MICRO_ELIGIBILITY_DISNEY = 'RDISN';

// HBO
export const MICRO_ELIGIBILITY_HBO = 'RHBM';
