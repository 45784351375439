import React, { PureComponent } from 'react';

import { TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP } from 'shared/components/presentational/Slider/styles/constants';
import {
  IconWrapper,
  ArticleIcon,
  Heading,
  Subtitle
} from '../styles/components';
import { ConditionalLink } from '../helpers/ConditionalLink';
import {
  SlideWrapper,
  SlideThumbnail
} from 'shared/components/presentational/Slider/Slide/styles/components';

export interface Props {
  className?: string;
  style?: object;
  title: string | null;
  subtitle: string | null;
  imageUrl: string;
  alt?: string;
  slug: string;
  onClick?: () => void;
  onHover?: () => void;
}

export default class ArticleSlide extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      title,
      subtitle,
      imageUrl,
      alt,
      className,
      style,
      onClick,
      onHover,
      slug
    } = this.props;

    return (
      <SlideWrapper
        className={className}
        style={style}
        onMouseEnter={onHover}
        data-testid="article-slide"
      >
        <SlideThumbnail
          alt={alt}
          url={imageUrl}
          height={TWO_SLIDES_THUMBNAIL_HEIGHT_DESKTOP}
        />
        <Heading>
          <ConditionalLink href={`/articles/${slug}`} onClick={onClick}>
            {title}
          </ConditionalLink>
          <Subtitle>{subtitle}</Subtitle>
        </Heading>
        <IconWrapper>
          <ArticleIcon />
        </IconWrapper>
      </SlideWrapper>
    );
  }
}
