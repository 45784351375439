/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import forgeApiUrl from '../shared/helpers/forgeApiUrl';

export default (content, { getLinkHrefWithAuthentication }) => ({
  title: get(content, 'title.label', ''),
  titleHtmlTag: get(content, 'title.htmlTag', ''),
  buttonGeolocation: get(content, 'geolocButtonLabel', ''),
  redirectLink: getLinkHrefWithAuthentication(get(content, 'link', '')),
  url: forgeApiUrl(
    get(content, 'api.url', ''),
    get(content, 'api.parameters', {})
  )
});
