import styled from 'styled-components';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Icon from 'shared/components/presentational/Icons';

export const Wrapper = styled.div<{ status: 'success' | 'info' | 'error' }>`
  display: flex;
  gap: var(--spacing-m);
  padding: var(--spacing-m);
  background-color: ${({ status }) => {
    switch (status) {
      case 'success':
        return '#0D442F';
      case 'error':
        return '#501D1F';
      default:
        return '#0F4156';
    }
  }};
  color: ${COLORS.white};
  border-radius: var(--border-radius-m);
`;

export const IconWrapper = styled.div<{ status: string }>`
  height: var(--spacing-xl);
  width: var(--spacing-xl);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ status }) => {
    switch (status) {
      case 'success':
        return '#0CC17B';
      case 'error':
        return '#EB3F4D';
      default:
        return '#10B7FF';
    }
  }};
`;

export const Title = styled.h4`
  font-size: var(--text-size-base);
  line-height: var(--line-height-m);
  margin: 0;
  font-family: ${FONT_FAMILIES.brand};
`;

export const Content = styled.p`
  font-size: var(--text-size-base);
  line-height: var(--line-height-m);
  margin: 0;
  font-family: ${FONT_FAMILIES.base};
  color: var(--secondary-dark-hover-legacy);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: var(--spacing-s);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: var(--spacing-xs);
`;

export const Link = styled(CustomLink)`
  font-size: var(--text-size-base);
  font-weight: ${FONT_WEIGHTS.demi};
`;

export const ChevronRight = styled(Icon).attrs({
  name: 'RoundedChevronDown',
  width: '16px',
  height: '8px'
})`
  transform: rotate(-90deg);
`;
