import styled from 'styled-components';

import _Picture from 'shared/components/presentational/Picture';
import { container } from 'shared/constants/mixins';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const Wrapper = styled.section`
  ${container}
  display: grid;
  background-color: var(--surface-background);
  color: var(--text-default);
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  font-family: ${FONT_FAMILIES.base};
  ${mediaQueries.fromTablet`
    gap: var(--spacing-s);
  `}
`;

export const TitleTemplate1 = styled.p`
  font-family: ${FONT_FAMILIES.base};
  color: var(--text-title);
  font-size: var(--text-size-h3);
  font-weight: ${FONT_WEIGHTS.demi};
  line-height: var(--line-height-h3);
  margin: 0;
`;

export const TitleTemplate2 = styled.p`
  font-family: ${FONT_FAMILIES.brand};
  color: var(--text-title);
  font-size: 20px;
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: var(--line-height-h3);
  margin: 0;
`;

export const TextWrapper = styled.div`
  p {
    margin: 0;
  }
`;

export const SubTitle = styled.p`
  color: var(--text-light);
  font-size: var(--text-size-base);
  margin: 0;
  text-align: var(--text-align);
`;

export const CardsWrapperTemplate1 = styled.div<{
  columns: string;
}>`
  display: grid;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  ${mediaQueries.fromTablet<{ columns: string }>`
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  `}
`;

export const CardsWrapperTemplate2 = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  ${mediaQueries.fromTablet`
    display: grid;
    grid-template-columns:
      repeat(2, 1fr)};
    gap: var(--spacing-xxl);
  `}
`;

export const CardWrapperTemplate1 = styled.div`
  display: flex;
  gap: var(--spacing-xs) var(--spacing-xs);
  flex-direction: column;
  align-content: baseline;
`;

export const CardWrapperTemplate2 = styled.div`
  display: flex;
  gap: var(--spacing-xs) var(--spacing-xl);
  flex-direction: row;
  align-content: baseline;
`;

const CustomPictureWrapper = styled.div`
  width: unset;
`;

export const PictureTemplate1 = styled(_Picture)`
  display: flex;
  height: 30px;
  max-width: 30px;
`;

export const PictureTemplate2 = styled(_Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  display: flex;
  height: 64px;
  max-width: 64px;
`;

export const LinkStyled = styled(CustomLink)`
  display: flex;
  color: var(--tertiary-label-default);
  font-weight: var(--text-font-weight);
  font-size: var(--text-size-base);

  &:hover {
    color: var(--tertiary-label-hover);
  }
`;
