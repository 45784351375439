import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ProgressBar from 'shared/components/presentational/ProgressBar';
import { DefaultTab } from 'shared/blocks/tabs/type';

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  width: calc(100% - 40px);
  display: flex;
  top: 18px;
`;

export const currentValueFromTabs = (tabs: DefaultTab[]): number =>
  tabs.reduce(
    (value, tab, index, { length }) =>
      tab.active ? index / (length - 1) : value,
    0
  );

export const previousValueFromTabs = (tabs: DefaultTab[]): number =>
  tabs.reduce(
    (value, tab, index, { length }) =>
      tab.active ? (index - 1 > 0 ? index - 1 : 0) / (length - 1) : value,
    0
  );

interface Props {
  tabs: DefaultTab[];
}

interface State {
  value: number;
}

export default class Path extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { value: previousValueFromTabs(props.tabs) };
  }

  public componentDidMount() {
    const { tabs } = this.props;
    setTimeout(() => this.setState({ value: currentValueFromTabs(tabs) }), 100);
  }

  public UNSAFE_componentWillReceiveProps(props: Props) {
    this.setState({ value: currentValueFromTabs(props.tabs) });
  }

  public render(): React.ReactNode {
    const { value } = this.state;
    return <StyledProgressBar value={value} transition={{ duration: 1 }} />;
  }
}
