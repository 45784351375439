import { Content, FormattedProps, RedirectLink } from './types';
import { PropsFormatterUtils } from 'shared/types';

export const getFormattedApis = (content: Content) => ({
  getSubscriberData: {
    url: content.apis.getSubscriberData.url ?? '',
    method: content.apis?.getSubscriberData?.method || 'GET',
    parameters: content.apis?.getSubscriberData?.parameters || {}
  }
});

export default (
  content: Content,

  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  redirectLinks: content.redirectLinks.map((el: RedirectLink) => ({
    label: el.label,
    link: getLinkHrefWithAuthentication(el.link)
  })),
  apis: getFormattedApis(content),
  contextCriteria: content.frontDisplayCriteria ?? null
});
