import { connect } from 'react-redux';
import { goBack } from 'redux-first-history';

import List from '../components/List';
import {
  FormattedProps,
  PropsFromState,
  PropsFromDispatch,
  PropsFromMerge
} from '../types';
import getArticleSlug from 'shared/blocks/articles/shared/helpers/getArticleSlug';
import getPathname from 'shared/modules/router/selectors/getPathname';
import pushURLHandler from 'shared/helpers/router/pushURLHandler';
import hasDisplayPageParams from 'shared/blocks/articles/shared/helpers/hasDisplayPageParams';
import clientCookies from 'client/cookies';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import queryClient from 'client/queryClient';
import { getDomainURL } from 'shared/modules/hostname/selectors/getDomainURL';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import { formatDomainUrl } from 'shared/modules/page/helpers/formatDomainUrl';

const mapStateToProps = (
  state: object,
  ownProps: FormattedProps
): PropsFromState => {
  // rely on pathname rather than pagePath because slug will only be defined after user interaction
  // and also because pagePath is not updated after article page is fetched

  const displayArticleAsPage = hasDisplayPageParams(state);
  const pathname = getPathname(state);
  const slug = getArticleSlug(pathname);
  const lang = clientCookies.get(COOKIE_KEYS.LANG);
  const hostname = getHostname(state);
  const miniSiteDomain = getMiniSiteDomain(hostname);
  const domainUrl = getDomainURL(state);
  const isoCountryCode = clientCookies.get(COOKIE_KEYS.ZONE);
  const bigram = getBigram(state);

  if (slug) {
    return {
      url: displayArticleAsPage
        ? undefined
        : ownProps.createArticleContentHref({ slug }),
      lang,
      miniSiteDomain,
      domainUrl: formatDomainUrl(domainUrl, bigram),
      zone: isoCountryCode
    };
  }

  return {
    url: undefined,
    domainUrl: formatDomainUrl(domainUrl, bigram),
    zone: isoCountryCode
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  pushURLHandler: (url: string) => dispatch(pushURLHandler(url)),
  goBack: () => dispatch(goBack(-1))
});

const mergeProps = (
  propsFromState: PropsFromState,
  { pushURLHandler, goBack }: PropsFromDispatch,
  ownProps: FormattedProps
): PropsFromMerge => ({
  ...propsFromState,
  ...ownProps,
  onArticleClose: goBack,
  onArticleClick: ownProps.createArticlePageHref
    ? (slug: string) => pushURLHandler(ownProps.createArticlePageHref({ slug }))
    : null,
  onArticleHover: (slug: string) => {
    const url = ownProps.createArticleContentHref({ slug });
    queryClient.invalidateQueries(url);
  }
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(List);
