import get from 'lodash/get';

import { Api, PropsFormatterUtils } from 'shared/types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Content, FormattedProps } from './types';

export default function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps {
  return {
    title: get(content, 'title.label', ''),
    titleColor: get(content, 'title.color', ''),
    titleHtmlTag: get(content, 'title.htmlTag'),
    subtitle: get(content, 'subtitle', '') as string,
    informationMessage: {
      message: get(content.informationMessage, 'message'),
      image: {
        imageAlt: get(content.informationMessage, 'image.filename'),
        imageSrc: get(content.informationMessage, 'image.link.href')
      }
    },
    confirmationButtonLabel: get(
      content,
      'buttons.confirmationButton.label',
      ''
    ),
    confirmationButtonTheme: themeFactory({
      backgroundColor: get(
        content,
        'buttons.confirmationButton.backgroundColor'
      ),
      color: get(content, 'buttons.confirmationButton.labelColor')
    }),
    confirmationButtonLink: getLinkHrefWithAuthentication(
      get(content, 'buttons.confirmationButton.link', '')
    ),
    crmGeographicalAreaApi: get(content, 'apis.crm_geographical_area') as Api,
    crmGetTitlesApi: get(content, 'apis.crm_get_titles') as Api
  };
}
