import React from 'react';
import styled from 'styled-components';
import { FormikTouched } from 'formik';

import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { BaseForm } from 'shared/types';

const Wrapper = styled.em<HintProps>`
  display: block;
  font-family: ${FONT_FAMILIES.base};
  font-style: normal;
  font-size: ${pxInRem(12)};
  font-weight: ${FONT_WEIGHTS.normal};
  color: ${props =>
    props.error ? 'var(--semantic-error)' : 'var(--text-light)'};
`;

export interface HintProps {
  hint?: string;
  isRequired?: boolean;
  touched?: boolean | FormikTouched<any>;
  error?: any;
}

type Props = BaseForm & HintProps;

export default function Hint({
  className,
  error,
  hint,
  isRequired = true
}: Props) {
  if (!isRequired && !error && !hint) {
    return null;
  }

  return (
    <Wrapper
      className={className}
      error={error}
      data-error={error || undefined}
    >
      {error || hint}
    </Wrapper>
  );
}
