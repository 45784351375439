import React from 'react';

import Title from 'shared/components/presentational/Title';
import { FadeContainer, Content } from './styles/components';
import { FormattedBannerTab, FormattedCardTab, TEMPLATES } from '../../types';
import TabContentCards from './TabContentCards';
import TabContentBanner from './TabContentBanner';

interface Props {
  tab: FormattedBannerTab | FormattedCardTab;
  isActive: boolean;
  tabIndex: number;
}

const TabContent = ({ tab, isActive, tabIndex }: Props) => {
  if (!isActive) return null;

  if (tab.template === TEMPLATES.banner) {
    return (
      <FadeContainer isActive={isActive}>
        <Content>
          <TabContentBanner
            content={tab.contents}
            autoPlaySpeed={tab.autoPlaySpeed}
          />
        </Content>
      </FadeContainer>
    );
  }
  return (
    <FadeContainer isActive={isActive}>
      <Title titleLabel={tab.title} titleHtmlTag="h2" subtitle={tab.subtitle} />
      <Content>
        <TabContentCards content={tab.contents} tabIndex={tabIndex} />
      </Content>
    </FadeContainer>
  );
};

export default TabContent;
