'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, FieldProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import AlertBox from 'shared/components/presentational/AlertBox/AlertBox';
import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import {
  AlertBoxContainer,
  CardContainer,
  CardsWrapper,
  FadeContainer,
  Form as RenewalForm,
  HeadContainer,
  OfferCardContainer,
  OfferCardInput,
  Subtitle,
  UserNumber,
  Wrapper,
  OptionsContainer
} from '../styles/components';
import { useUserRenewalData } from '../hooks/useUserRenewalData';
import { FormattedProps } from '../types';
import { Card } from '../../../shared/components/Card/Card';
import updateRenewalForm from '../../actions';
import { FIELDS } from '../../constants';
import Title from 'shared/components/presentational/Title';
import MinorOptionsList from './MinorOptionsList';
import EditablePhoneField from './EditablePhoneField';
import { useMobileOperators } from '../hooks/useMobileOperators';
import Button from 'shared/components/presentational/Button';
import OperatorsSelect from './OperatorsSelect';
import { RenewalFormState } from '../../reducer';
import { phoneValidator } from '../helpers/phoneNumberValidator';
import ResponsiveHoc, {
  ResponsiveHocProps
} from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import { CustomTitle } from 'shared/components/presentational/Title/sharedTitles';

function Form({
  title,
  saleDeviceId,
  productPriceLabel,
  productOffer,
  api: { getRenewalOffer },
  minorProductsTitle,
  firstAlertBox,
  secondAlertBox,
  minorProductsEngagementUnit,
  phoneLabel,
  mobileOperatorTitle,
  submitButton,
  mediaQueries
}: FormattedProps & ResponsiveHocProps) {
  const [isPhoneEditing, setIsPhoneEditing] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    data,
    isError,
    isLoading: isLoadingUserData,
    error
  } = useUserRenewalData({
    getRenewalOffer,
    saleDeviceId,
    productOffer
  });

  const {
    data: formattedOperators = [],
    isError: isMobileOpsError,
    isLoading: isLoadingOperators
  } = useMobileOperators({
    userRenewalData: data
  });

  const dispatch = useDispatch();
  const renewalFormValues = useSelector(
    (state: { renewalForm: RenewalFormState }) => state.renewalForm || {}
  );

  const validationSchema = yup.object().shape({
    [FIELDS.SELECTED_OFFER]: yup
      .string()
      .required(t('blocks.renewalForm.validation.offer')),
    [FIELDS.SELECTED_OPERATOR]: yup
      .string()
      .required(t('blocks.renewalForm.validation.operator'))
  });

  const isLoading = isLoadingOperators || isLoadingUserData;
  if (isLoading) {
    return <Loader />;
  }

  if (mediaQueries.toPhone === false) {
    return (
      <AlertBoxContainer>
        <AlertBox status="info" content={t('blocks.renewalForm.onlyMobile')} />
      </AlertBoxContainer>
    );
  }

  if (isError || !data) {
    return (
      <AlertBoxContainer>
        <AlertBox
          status="error"
          content={(error as Error).message ?? t('common.global.error')}
        />
      </AlertBoxContainer>
    );
  }

  const getDisabledMinorOptions = (
    formSelectedOptions: Record<string, boolean>
  ) => {
    // Get the IDs of the selected minor options
    const selectedOptionsIds = Object.keys(formSelectedOptions).filter(
      optionId => formSelectedOptions[optionId]
    );

    let disabledMinorOptionsIds: string[] = [];

    // For each selected minor option, collect the forbidden options
    selectedOptionsIds.forEach(option => {
      disabledMinorOptionsIds = disabledMinorOptionsIds.concat(
        data.minorsOffers[option].forbiddenOptions
      );
    });

    // Return a list of IDs for minor options that should be disabled
    return disabledMinorOptionsIds;
  };

  return (
    <Wrapper>
      <FadeContainer>
        <HeadContainer>
          {title && (
            <CustomTitle htmlTag={title.htmlTag}>{title.label}</CustomTitle>
          )}
          <div>
            <Subtitle>{t('blocks.renewalForm.renewalNumber')}</Subtitle>
            <UserNumber>{data.equipmentId}</UserNumber>
          </div>
        </HeadContainer>
        <Formik
          initialValues={{
            selectedOffer:
              renewalFormValues.selectedOffer || data.detainedOfferId || '',
            minorsOffers: renewalFormValues.minorsOffers || {},
            phoneNumber:
              renewalFormValues.phoneNumber || data.phoneNumber || '',
            selectedOperator: renewalFormValues.selectedOperator || '',
            paymentMeanId: renewalFormValues.paymentMeanId || ''
          }}
          onSubmit={values => {
            const selectedOperatorData = data.operators.find(
              op => op.id === values.selectedOperator
            );

            const paymentMeanId = selectedOperatorData?.paymentMeanId || '';

            dispatch(
              updateRenewalForm(
                { ...values, paymentMeanId },
                {
                  equipmentId: data.equipmentId,
                  personId: data.personId,
                  contractId: data.contractId,
                  managementAct: data.managementAct
                }
              )
            );

            if (submitButton.link) {
              const minorsOffersIds = Object.entries(values.minorsOffers)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([_, isSelected]) => !!isSelected)
                .map(([id]) => id);

              const params = new URLSearchParams({
                product: values.selectedOffer
              });
              if (minorsOffersIds.length > 0) {
                params.append('minorProducts', minorsOffersIds.join(','));
              }

              history.push(`${submitButton.link}?${params.toString()}`);
            }
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => {
            const disabledMinorOptionsIds = getDisabledMinorOptions(
              values.minorsOffers
            );

            return (
              <RenewalForm>
                <CardContainer>
                  <CardsWrapper>
                    {Object.values(data.majorOffers).map(offer => {
                      return (
                        <OfferCardContainer key={offer.id}>
                          <Field name="selectedOffer" type="radio">
                            {({ field }: FieldProps<string>) => (
                              <label htmlFor={offer.id}>
                                <OfferCardInput
                                  id={offer.id}
                                  type="radio"
                                  {...field}
                                  value={offer.id}
                                  checked={values.selectedOffer === offer.id}
                                />
                                <Card
                                  promotionalSticker={
                                    offer.promotionalSticker
                                      ? t('blocks.renewalForm.current')
                                      : null
                                  }
                                  title={offer.title}
                                  offerDetails={{
                                    price: offer.price,
                                    priceInformation: productPriceLabel,
                                    engagementUnit: offer.engagementUnit,
                                    currency: offer.currency
                                  }}
                                  isSelected={values.selectedOffer === offer.id}
                                  groups={offer.groups}
                                  isSliderHidden={false}
                                  moreChannelsIcon={offer.moreChannelsIcon}
                                />
                              </label>
                            )}
                          </Field>
                        </OfferCardContainer>
                      );
                    })}
                  </CardsWrapper>
                </CardContainer>

                {firstAlertBox && <AlertBox {...firstAlertBox} />}

                <OptionsContainer>
                  <Title
                    titleLabel={minorProductsTitle.label}
                    titleHtmlTag={minorProductsTitle.htmlTag}
                  />

                  <MinorOptionsList
                    majorOffers={data.majorOffers}
                    minorOffers={data.minorsOffers}
                    disabledMinorOptionsIds={disabledMinorOptionsIds}
                    minorProductsEngagementUnit={minorProductsEngagementUnit}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </OptionsContainer>

                {secondAlertBox && <AlertBox {...secondAlertBox} />}

                {!isMobileOpsError && (
                  <>
                    <EditablePhoneField
                      label={phoneLabel}
                      value={values.phoneNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('phoneNumber', e.target.value)
                      }
                      validator={phoneValidator}
                      error={errors.phoneNumber}
                      isEditing={isPhoneEditing}
                      setIsEditing={setIsPhoneEditing}
                    />

                    <OperatorsSelect
                      title={mobileOperatorTitle}
                      operators={formattedOperators}
                    />
                  </>
                )}

                <Button
                  type="submit"
                  variant="primary"
                  disabled={isPhoneEditing}
                >
                  <Button.children.Text>
                    {submitButton.label}
                  </Button.children.Text>
                </Button>
              </RenewalForm>
            );
          }}
        </Formik>
      </FadeContainer>
    </Wrapper>
  );
}

export default ResponsiveHoc(Form);
