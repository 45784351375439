import React from 'react';
import styled from 'styled-components';

import LoaderPlaceHolder from 'shared/components/presentational/Loader/LoaderPlaceholder';

const Loader = styled(LoaderPlaceHolder)`
  width: 100%;
  height: 200px;
`;

export default () => <Loader />;
