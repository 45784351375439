import styled from 'styled-components';

import Icon from 'shared/components/presentational/Icons/Icon';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import {
  FRAGMENT_MAX_WIDTH,
  MAIN_IMAGE_HEIGHT,
  MAIN_IMAGE_HEIGHT_PHONE
} from '../../shared/styles/constants';
import { FONT_WEIGHTS } from 'shared/constants/theme';
import { container } from 'shared/constants/mixins';
import Picture from 'shared/components/presentational/Picture';
import Wrapper from 'shared/components/presentational/Wrapper/Wrapper';

export const ContainerWrapper = styled.section`
  ${container}
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xl);
`;

export const ModalWrapper = styled(Wrapper)`
  margin: 0 auto !important;
  width: ${FRAGMENT_MAX_WIDTH}px;

  ${mediaQueries.toTablet`
  width:100%;
`}
`;

export const ArticleIcon = styled(Icon).attrs({ name: 'Article' })`
  width: 15px;
  height: 15px;
  color: var(--icons-black);
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 0;
  border-color: var(--menu-secondary-label-disabled) transparent transparent
    transparent;
  ${ArticleIcon} {
    position: absolute;
    top: -35px;
    left: 5px;
  }
`;

export const InnerModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--surface-background);
  min-height: ${MAIN_IMAGE_HEIGHT}px;
  border-radius: var(--border-radius-xxl);

  ${mediaQueries.toPhablet`
    min-height: ${MAIN_IMAGE_HEIGHT_PHONE}px;
  `}
`;

export const InsetSpan = styled.span`
  inset: 0;
  position: absolute;
`;

export const ArticleWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  row-gap: var(--spacing-m);
  column-gap: var(--spacing-xs);

  ${mediaQueries.fromTablet`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-s);
`}

  ${mediaQueries.fromDesktop`
  grid-template-columns: repeat(5, 1fr);
  gap: var(--spacing-xs);
`};
`;

export const Article = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-xs) 0;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: var(--text-size-base);
`;

export const Subtitle = styled.p`
  font-size: var(--text-size-s);
  line-height: var(--line-height-base);
  text-transform: capitalize;
  color: var(--text-light);
  font-weight: ${FONT_WEIGHTS.normal};
  margin: 0;
`;

export const MainImage = styled(Picture)`
  object-fit: cover;
  width: 100%;
`;
