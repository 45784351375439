import styled from 'styled-components';

import _Button from 'shared/components/presentational/Button';
import { FONT_WEIGHTS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledButton = styled(_Button)<{
  fullwidthmobile?: boolean;
  fullwidthdesktop?: boolean;
}>`
  color: var(--primary-label-default);
  border-color: none;
  border-radius: var(--border-radius-m);
  border: none;
  font-size: var(--text-size-l);
  font-weight: ${FONT_WEIGHTS.bold};
  justify-content: center;
  line-height: var(--line-height-h3);
  overflow: hidden;
  padding: 13px var(--spacing-m);
  width: ${({ fullwidthmobile }) => (fullwidthmobile ? '100%' : 'min-content')};

  ${mediaQueries.fromTablet<{ fullwidthdesktop?: boolean }>`
    width: ${({ fullwidthdesktop }) =>
      fullwidthdesktop ? '100%' : 'min-content'};
  `};
`;

export const Label = styled.span`
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
