import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';

import { Api } from 'shared/types';
import { formatUserData } from '../helpers/formatUserData';
import { FormattedProps, Offer, ProductOffer } from '../types';
import { ADD_HEADERS_TO_STORE } from '../../reducer';

type Props = {
  getRenewalOffer: Api;
  saleDeviceId: string | null;
  productOffer: FormattedProps['productOffer'];
};

const APPLICATION_NAME = 'WEB';

export const useUserRenewalData = ({
  getRenewalOffer,
  saleDeviceId,
  productOffer
}: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const ope = queryParams.get('ope');
  const kid = queryParams.get('kid');
  const code = queryParams.get('code');

  return useQuery({
    queryKey: ['userData', location.search],
    queryFn: async () => {
      if (!ope || !kid || !code || !saleDeviceId) {
        throw new Error(
          'Missing required parameters: ope, kid, code or saleDeviceId'
        );
      }
      const params = new URLSearchParams({
        ope: ope || '',
        kid: kid || '',
        code: code || '',
        saleDeviceId: saleDeviceId || '',
        applicationName: APPLICATION_NAME
      });

      const url = `${getRenewalOffer.url}?${params.toString()}`;
      const response = await fetch(url, {
        method: getRenewalOffer.method,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const body = await response.json();

        throw new Error(body?.[0]?.detail ?? 'Failed to fetch user data');
      }

      const token = response.headers.get('token');
      const correlationId = response.headers.get('correlationid');

      dispatch({
        type: ADD_HEADERS_TO_STORE,
        payload: { token, correlationId }
      });

      return formatUserData(await response.json());
    },
    select: fetchedData => {
      let detainedOfferId = null;
      const majorOffers: Record<string, Offer & ProductOffer> = {};
      const minorsOffers: Record<string, Offer> = {};

      fetchedData.offers.forEach(offer => {
        if (offer.major === false) {
          minorsOffers[offer.id] = offer;
        }

        if (
          // Do not return offer if not present in BO and CRM
          !productOffer.some(
            (product: { productId: string }) => product.productId === offer.id
          )
        )
          return;

        if (offer.detained) {
          detainedOfferId = offer.id;
        }

        const product = productOffer.find(
          (product: { productId: string }) => product.productId === offer.id
        );

        if (offer.major) {
          majorOffers[offer.id] = {
            ...offer,
            engagementUnit: product?.engagementUnit || null,
            moreChannelsIcon: product?.moreChannelsIcon || {
              label: null,
              href: ''
            },
            groups: product?.groups || [],
            forbiddenOptions: offer.forbiddenOptions || []
          };
        }
      });

      return {
        equipmentId: fetchedData.equipmentId,
        personId: fetchedData.personId,
        contractId: fetchedData.contractId,
        phoneNumber: fetchedData.phoneNumber,
        managementAct: fetchedData.managementAct,
        operators: fetchedData.operators,
        detainedOfferId,
        majorOffers,
        minorsOffers
      };
    },
    enabled: !!ope && !!kid && !!code
  });
};
