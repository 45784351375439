import styled from 'styled-components';

import AccordionItem from 'shared/components/presentational/Accordion/AccordionItem';
import Icon from 'shared/components/presentational/Icons/Icon';
import Picture from 'shared/components/presentational/Picture';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Link from 'shared/components/presentational/CustomLink/CustomLink';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export const CustomLink = styled(Link)`
  border: var(--channel-group-grid-item-border);
  border-radius: var(--channel-group-grid-item-border-radius);
  padding: 0px;
  flex: var(--channel-group-grid-item-flex-mobile);
  cursor: pointer;

  ${mediaQueries.fromTablet`
  flex: var(--channel-group-grid-item-flex-tablette);
`};

  ${mediaQueries.fromDesktop`
  flex: var(--channel-group-grid-item-flex-desktop);
`};
`;

export const ChannelGrid = styled.div`
  display: var(--channel-group-grid-display);
  min-width: 0;
  flex-wrap: wrap;
  grid-template-columns: var(--channel-group-grid-mobile-grid-template);

  justify-content: var(--channel-group-grid-justify);
  gap: var(--channel-group-grid-gap);
  margin: var(--channel-group-grid-margin);

  ${mediaQueries.fromTablet`
    grid-template-columns: var(--channel-group-grid-desktop-grid-template);
  `};
`;

export const ChannelGridItem = styled.div`
  border: var(--channel-group-grid-item-border);
  border-radius: var(--channel-group-grid-item-border-radius);

  flex: var(--channel-group-grid-item-flex-mobile);
  height: var(--channel-group-grid-item-card-height-mobile);
  background: var(--channel-group-grid-item-card-background);
  padding: var(--channel-group-grid-item-card-padding);

  display: flex;
  align-items: center;
  min-width: 0;

  ${mediaQueries.fromTablet`
    flex: var(--channel-group-grid-item-flex-tablette);
    height: 100%;
  `};

  ${mediaQueries.fromDesktop`
    flex: var(--channel-group-grid-item-flex-desktop);
  `};
`;

export const ChannelImage = styled(Picture)`
  border-radius: 3px;
  object-fit: cover;
  width: 100%;
  height: var(--channel-group-grid-item-image-height-mobile);

  ${mediaQueries.fromTablet`
    height: var(--channel-group-grid-item-image-height-tablette);
  `};
`;
export const StyledAccordionItem = styled(AccordionItem)`
  background-color: var(--channel-group-grid-item-card-background);
`;
StyledAccordionItem.displayName = 'Accordion.Item';

export const AccordionTitle = styled.h3`
  font-size: 20px;
  line-height: 120%;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  margin-right: 14px;
  margin-bottom: 16px;
  color: var(--text-default);
`;

export const StyledIcon = styled(Icon)`
  & > path {
    stroke: var(--text-default);
    stroke-width: 1px;
  }
`;
