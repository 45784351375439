import { PropsFormatterUtils } from 'shared/types';
import { Content, FormattedProps } from './types';

export default function propsFormatter(
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps {
  return {
    title: content.title.label ?? '',
    titleHtmlTag: content.title.htmlTag,
    subtitle: content.subtitle,
    description: content.description ?? '',
    agreements: content.agreements,
    button: {
      label: content.button.label,
      link: getLinkHrefWithAuthentication(content.button.link ?? '')
    },
    apis: {
      getSubscriberData: {
        url: content.apis?.getSubscriberData?.url ?? '',
        method: content.apis?.getSubscriberData?.method || 'GET',
        parameters: content.apis?.getSubscriberData?.parameters || {}
      },
      updateCategoryVAccess: {
        url: content.apis?.updateCategoryVAccess?.url ?? '',
        method: content.apis?.updateCategoryVAccess?.method || 'GET'
      }
    },
    contextCriteria: content.frontDisplayCriteria ?? null
  };
}
