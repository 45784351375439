import React from 'react';

import Icon from 'shared/components/presentational/Icons/Icon';
import {
  ChevronRight,
  Container,
  Content,
  IconWrapper,
  Link,
  TextContainer,
  Title,
  Wrapper
} from './styles/components';

export interface AlertBoxProps {
  status: 'success' | 'info' | 'error';
  title?: string | null;
  content: string;
  link?: { label: string; href: string } | null;
  className?: string;
}

function getIconName(status: AlertBoxProps['status']) {
  switch (status) {
    case 'success':
      return 'Check';
    case 'error':
      return 'Close';
    default:
      return 'Info';
  }
}

export default function AlertBox({
  status,
  title,
  content,
  link,
  className
}: AlertBoxProps) {
  return (
    <Wrapper status={status} className={className}>
      <div>
        <IconWrapper status={status}>
          <Icon name={getIconName(status)} width="12" height="12" />
        </IconWrapper>
      </div>
      <Container>
        <TextContainer>
          {title && <Title> {title}</Title>}
          <Content>{content}</Content>
        </TextContainer>
        {link && (
          <div>
            <Link to={link.href}>{link.label}</Link> <ChevronRight />
          </div>
        )}
      </Container>
    </Wrapper>
  );
}
