import React from 'react';

import { IconProps } from '../types';

export default function PositionFill({
  className,
  width = '1em',
  height = '1em'
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 30"
    >
      <path
        d="M12.0664005,30 C4.02213349,21.8829262 1.67583377e-16,15.7315499 0,11.5458711 C0,5.26735293 4.90487869,2.18051806e-15 12.0664005,0 C19.2279222,0 24,5.16812417 24,11.5458711 C24,15.7977024 20.0221335,21.9490787 12.0664005,30 Z"
        id="Combined-Shape"
        fill="current"
      ></path>
      <path
        d="M18.03,12.688 C18.03,12.8 17.974,12.856 17.876,12.856 L14.404,12.856 C14.278,12.856 14.222,12.912 14.222,13.038 L14.222,16.804 C14.222,16.93 14.166,17 14.068,17 L11.198,17 C11.1,17 11.044,16.944 11.044,16.832 L11.044,13.066 C11.044,12.926 10.988,12.856 10.862,12.856 L7.138,12.856 C7.026,12.856 6.97,12.8 6.97,12.674 L6.97,9.804 C6.97,9.706 7.026,9.65 7.124,9.65 L10.876,9.65 C10.988,9.65 11.044,9.58 11.044,9.454 L11.044,6.22 C11.044,6.122 11.086,6.08 11.184,6.08 L14.04,6.08 C14.166,6.08 14.222,6.122 14.222,6.22 L14.222,9.454 C14.222,9.58 14.264,9.65 14.362,9.65 L17.876,9.65 C17.974,9.65 18.03,9.706 18.03,9.832 L18.03,12.688 Z"
        id="plus"
        fill="current"
      ></path>
    </svg>
  );
}
