import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import { FONT_FAMILIES } from 'shared/constants/theme';

const Wrapper = styled.div`
  width: 100%;
  ${mediaQueries.toPhablet`
     .LazyLoad_Wrapper, .LazyLoad_Wrapper img  {
          height: 150px;
      }
  `};
`;

const textStyle = css`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  line-height: ${pxInRem(18)};
`;

const Image = styled(Picture)`
  width: 100%;
  object-fit: cover;
  height: ${props => props.height}px;
`;

const ContentWrapper = styled.div``;

const Title = styled.h3`
  ${textStyle};
  margin: 12px 0 3px;
`;
const Subtitle = styled.p`
  ${textStyle};
  color: var(--text-light);
  margin: 0 0 3px;
`;

const LinkWrapper = styled(CustomLink)`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

class MEAProgram extends PureComponent {
  static defaultProps = {
    imageHeight: 135
  };

  render() {
    const {
      title,
      subtitle,
      channel,
      time,
      imageUrl,
      imageHeight,
      linkUrl
    } = this.props;

    return (
      <Wrapper>
        <LinkWrapper to={linkUrl} variant="text">
          <Image height={imageHeight} url={imageUrl} alt={title} disableLazy />
          <ContentWrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Subtitle>{channel}</Subtitle>
            <Subtitle>{time}</Subtitle>
          </ContentWrapper>
        </LinkWrapper>
      </Wrapper>
    );
  }
}

export default MEAProgram;
