import React from 'react';
import styled from 'styled-components';

import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const Wrapper = styled.p`
  margin: 0;
  border: 1px solid var(--surface-border-default);
  font-family: ${FONT_FAMILIES.brand};
  color: var(--text-default);
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: var(--text-size-xs);
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: var(--line-height-s);
  padding: var(--spacing-xs);
  text-align: left;
  width: fit-content;
  overflow: hidden;
`;

export const Sticker = ({ label, ...props }: { label: string | null }) => (
  <Wrapper {...props}>{label}</Wrapper>
);
