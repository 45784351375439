import styled from 'styled-components';

export const WrapperIframe = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

export const WrapperPlayer = styled.div`
  position: relative;
  width: 100%;
`;

export const WrapperPlayerDailymotion = styled.div`
  padding-bottom: 56.25%;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
