import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';

export const Form = styled.form`
  padding: var(--spacing-xl);
  padding-top: 0;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  margin: var(--spacing-xl) 0 var(--spacing-m) 0;

  ${mediaQueries.fromDesktop`
    margin: var(--spacing-xl) 0;
  `};
`;

export const ProductWrapper = styled.div<{ $isMainProduct?: boolean }>`
  background-color: ${({ $isMainProduct }) =>
    $isMainProduct ? 'inherit' : 'var(--surface-primary)'};
  border: ${({ $isMainProduct }) =>
    $isMainProduct ? '1px solid var(--surface-border-light)' : 'unset'};
  padding: var(--spacing-m);
`;

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-s);
`;

export const ProductTitle = styled.h3`
  color: var(--text-title);
  margin: 0;
`;

export const ProductDescription = styled.p`
  color: var(--text-light);
  margin: var(--spacing-xs) 0 0 0;
`;

export const ProductPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  align-items: end;
`;

export const ChannelsGroup = styled.div`
  margin-top: var(--spacing-xs);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-s);

  ${mediaQueries.fromDesktop`
    grid-template-columns: repeat(8, 1fr);
  `};
`;

export const ChannelImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: var(--spacing-m);

  ${mediaQueries.fromDesktop`
    flex-direction: row;
    gap: var(--spacing-s);
  `};
`;
