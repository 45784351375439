import React from 'react';

import {
  Wrapper,
  PictureWrapper,
  Picture,
  Description,
  MarketingPrice
} from './styles/components';
import Checkbox from 'shared/components/presentational/Form/Checkbox';
import { MinorOfferProps } from '../../types';

const MinorOffer = ({
  minorOffer,
  toggleChecked,
  checked
}: MinorOfferProps) => {
  return (
    <Wrapper>
      {minorOffer.imageUrl && (
        <PictureWrapper>
          <Picture url={minorOffer.imageUrl} alt={minorOffer.imageAlt} />
        </PictureWrapper>
      )}

      {minorOffer.marketingPrice && (
        <MarketingPrice html={minorOffer.marketingPrice} />
      )}

      {minorOffer.description && <Description html={minorOffer.description} />}

      <Checkbox
        name={minorOffer.code}
        checked={checked}
        onChange={toggleChecked}
        scale={2}
      />
    </Wrapper>
  );
};

export default MinorOffer;
