import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

export default styled.div`
  background-color: var(--surface-primary);
  padding: 32px 20px;

  ${mediaQueries.fromDesktop`
    padding: 40px;
  `}
`;
