import styled from 'styled-components';

const defaultEasing = 'ease';

export default styled.div<{
  value: number;
  transition: {
    duration: number;
    easing?: string;
  };
}>`
  display: block;
  position: relative;
  height: 3px;
  width: 100%;
  margin: 0 20px;
  background-color: var(--surface-border-light);
  :before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    transform-origin: left;
    transform: scaleX(${props => props.value});
    background-color: var(--primary-default);
    transition: transform
      ${props =>
        `${props.transition.duration}s ${
          props.transition.easing || defaultEasing
        }`};
  }
`;
