/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps, FormattedArticle, Article } from './types';
import { createTemplate } from 'shared/helpers/template';
import { PropsFormatterUtils } from 'shared/types';
import { getLinkHref } from 'shared/helpers/uri';

export const getArticles = (list: Article[]): FormattedArticle[] => {
  return list.map(article => ({
    id: get(article, 'articleId', null),
    title: get(article, 'title', null),
    subtitle: get(article, 'subtitle', null),
    slug: get(article, 'slug', 'not-found'),
    mainMedia: getLinkHref(get(article, 'mainMedia.link', '')),
    alt: get(article, 'mainMedia.description', 'mainMedia.filename'),
    responsiveMedia: getLinkHref(get(article, 'responsiveMedia.link', ''))
  }));
};

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  template: content.template,
  title: get(content, 'title.label', undefined),
  titleColor: get(content, 'title.color', undefined),
  titleHtmlTag: get(content, 'title.htmlTag', undefined),
  subtitle: content.subtitle ?? undefined,
  articles: getArticles(get(content, 'list', [])),
  createArticleContentHref: createTemplate(
    getLinkHrefWithAuthentication(get(content, 'get_article_content'))
  ),
  createArticlePageHref: createTemplate(
    getLinkHrefWithAuthentication(get(content, 'article_page_url'))
  )
});
