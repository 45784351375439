import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FormattedProps, FormattedRenewalOfferSummaryData } from '../types';
import {
  AlertBoxContainer,
  Buttons,
  Card,
  Container,
  Option,
  OptionLabel,
  OptionsContainer,
  OptionsList,
  RenewalDateLabel,
  Title,
  Total
} from '../styles/components';
import { useRenewalOfferSummary } from '../hooks/useRenewalOfferSummary';
import Loader from 'shared/blocks/selfcare/shared/components/Loader';
import OfferDetailsRocContainer from '../../../shared/containers/OfferDetailsRocContainer';
import AlertBox from 'shared/components/presentational/AlertBox/AlertBox';
import { Button } from '../../../shared/components/Button/Button';
import { formatRenewalOfferSummaryData } from '../helpers/formatRenewalOfferSummaryData';
import { RenewalFormState } from '../../reducer';

function Summary({
  title,
  productPriceLabel,
  renewalDateLabel,
  majorProduct,
  minorProductsTitle,
  minorProductsEngagementUnit,
  totalLabel,
  submitButton,
  cancelButtonLabel,
  saleDeviceId,
  apiPostRenewalOfferSummary
}: FormattedProps) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [
    summaryData,
    setSummaryData
  ] = useState<FormattedRenewalOfferSummaryData | null>(null);

  const renewalForm = useSelector(
    (state: { renewalForm: RenewalFormState }) => state.renewalForm
  );

  const { isIdle, isError, isLoading, mutate } = useRenewalOfferSummary({
    apiPostRenewalOfferSummary,
    saleDeviceId
  });

  useEffect(() => {
    if (renewalForm && !summaryData) {
      const queryParams = new URLSearchParams(location.search);
      const majorProductId = queryParams.get('product');
      const minorProductsParam = queryParams.get('minorProducts');

      const productsArray = minorProductsParam
        ? [majorProductId, ...minorProductsParam.split(',')]
        : [majorProductId];

      mutate(
        {
          token: renewalForm.token,
          correlationId: renewalForm.correlationId,
          managementAct: renewalForm.managementAct,
          body: {
            equipmentId: renewalForm.equipmentId,
            personId: renewalForm.personId,
            contractId: renewalForm.contractId,
            data: { products: productsArray.map(id => ({ id })) }
          }
        },
        {
          onSuccess: data => {
            setSummaryData(
              formatRenewalOfferSummaryData(
                data.data,
                majorProduct,
                minorProductsEngagementUnit
              )
            );
          }
        }
      );
    }
  }, [
    renewalForm,
    location.search,
    mutate,
    majorProduct,
    minorProductsEngagementUnit,
    summaryData
  ]);

  if (isLoading || isIdle) {
    return <Loader />;
  }

  if (isError || !summaryData) {
    return (
      <AlertBoxContainer>
        <AlertBox status="error" content={t('common.global.error')} />
      </AlertBoxContainer>
    );
  }

  const handleCancelButton = () => {
    history.goBack();
  };

  return (
    <Container>
      {title && <Title htmlTag={title.htmlTag}>{title.label}</Title>}

      {summaryData.majorProduct && (
        <Card>
          <Title htmlTag="h2">{summaryData.majorProduct.commercialLabel}</Title>
          <OfferDetailsRocContainer
            price={summaryData.majorProduct.price}
            priceInformation={productPriceLabel ?? ''}
            engagementUnit={summaryData.majorProduct.engagementUnit}
            currency={summaryData.currency}
          />
          <RenewalDateLabel>
            {renewalDateLabel}&nbsp;{summaryData.contractStartDate}
          </RenewalDateLabel>
        </Card>
      )}

      {summaryData.minorProducts.length > 0 && (
        <OptionsContainer>
          <>
            {minorProductsTitle && (
              <Title htmlTag={minorProductsTitle.htmlTag}>
                {minorProductsTitle.label}
              </Title>
            )}
            <OptionsList>
              {summaryData.minorProducts.map(minorProduct => (
                <Option key={minorProduct.id}>
                  <OptionLabel>{minorProduct.commercialLabel}</OptionLabel>
                  <OfferDetailsRocContainer
                    price={minorProduct.price}
                    engagementUnit={minorProduct.engagementUnit}
                    variant="modal"
                    currency={summaryData.currency}
                  />
                </Option>
              ))}
            </OptionsList>
          </>
        </OptionsContainer>
      )}

      <Total>
        <Title htmlTag="h3">{totalLabel}</Title>
        <OfferDetailsRocContainer
          price={summaryData.totalPrice}
          variant="modal"
          currency={summaryData.currency}
        />
      </Total>

      <Buttons>
        <Button
          label={submitButton.label}
          variant="primary"
          fullWidthMobile
          fullWidthDesktop
          link={submitButton.link}
        />
        <Button
          label={cancelButtonLabel}
          variant="secondary"
          fullWidthMobile
          fullWidthDesktop
          onClick={handleCancelButton}
        />
      </Buttons>
    </Container>
  );
}

export default Summary;
