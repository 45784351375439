import dayjs from 'dayjs';

import {
  RenewalOfferSummaryData,
  FormattedRenewalOfferSummaryData,
  FormattedProps
} from '../types';

export function formatRenewalOfferSummaryData(
  data: RenewalOfferSummaryData['data'],
  majorProductFromBackOffice: FormattedProps['majorProduct'],
  minorProductsEngagementUnit: FormattedProps['minorProductsEngagementUnit']
): FormattedRenewalOfferSummaryData {
  let majorProduct: FormattedRenewalOfferSummaryData['majorProduct'] = null;
  const minorProducts: FormattedRenewalOfferSummaryData['minorProducts'] = [];

  data.productsFirstAmount.forEach(product => {
    if (product.type.major && product.id === majorProductFromBackOffice.id) {
      majorProduct = {
        id: product.id,
        commercialLabel: product.commercialLabel,
        price: product.totalCatalogPrice,
        engagementUnit: majorProductFromBackOffice.engagementUnit
      };
    } else if (product.type.minor) {
      minorProducts.push({
        id: product.id,
        commercialLabel: product.commercialLabel,
        price: product.totalCatalogPrice,
        engagementUnit: minorProductsEngagementUnit[product.id]
      });
    }
  });
  return {
    majorProduct,
    minorProducts,
    currency: data.firstCurrency.code,
    totalPrice: data.totalProductsFirstAmount,
    contractStartDate: dayjs(data.contract.startDate).format('DD/MM/YYYY')
  };
}
