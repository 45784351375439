import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from 'shared/components/presentational/Form/Fields/InputField';
import { PhoneContainer, PhoneLabel, PhoneNumber } from '../styles/components';
import Button from 'shared/components/presentational/Button/Button';

interface Props {
  label: string | null;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validator?: (value: string) => string | undefined;
  error?: string;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditablePhoneField({
  label,
  value,
  onChange,
  validator,
  error,
  isEditing,
  setIsEditing
}: Props) {
  const { t } = useTranslation();

  return (
    <PhoneContainer>
      {label && <PhoneLabel>{label}</PhoneLabel>}

      {isEditing ? (
        <Field
          component={InputField}
          name="phoneNumber"
          type="text"
          isRequired={false}
          value={value}
          onChange={onChange}
          validate={validator}
        />
      ) : (
        <PhoneNumber>{value}</PhoneNumber>
      )}

      <Button
        variant="tertiary"
        type="button"
        onClick={() => setIsEditing(v => !v)}
        disabled={!!error}
      >
        <Button.children.Text>
          {isEditing
            ? t('common.form.action.save')
            : t('common.form.action.modify')}
        </Button.children.Text>
      </Button>
    </PhoneContainer>
  );
}
