import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { WithTranslation } from 'react-i18next';

import Button from 'shared/components/presentational/Button/Button';
import Loader from 'shared/components/presentational/Loader/Loader';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const StyledButton = styled(Button)`
  margin: 0 auto 20px;
`;

const WrapperLoader = styled.div`
  margin: 9px auto 27px;
`;

interface Props {
  path: string;
  fetchMore: (URLPage: string, path: string) => void;
  paging?: {
    nbContents: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    URLPage?: string;
  };
}

interface State {
  displayMoreLoader: boolean;
}

/**
 * We render button only if `hasNextPage` is true
 *
 * Instead of using Hodor logical (key / value -> URL / State)
 * We fetch new data and concat it on the key of the original url
 */
export default class RenderMoreButton extends PureComponent<
  Props & WithTranslation,
  State
> {
  public readonly state = {
    displayMoreLoader: false
  };

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const currentPaging = nextProps.paging;
    const newPaging = this.props.paging;

    if (
      currentPaging &&
      newPaging &&
      currentPaging.nbContents !== newPaging.nbContents
    ) {
      this.setState({
        displayMoreLoader: false
      });
    }
  }

  private handleFetchMoreContent = (): void => {
    const { paging, fetchMore, path } = this.props;

    fetchMore(paging!.URLPage!, path);

    this.setState({
      displayMoreLoader: true
    });
  };

  public render(): ReactNode | false {
    const { paging, t } = this.props;

    if (paging && paging.hasNextPage && paging.URLPage) {
      return (
        <ButtonWrapper>
          {!this.state.displayMoreLoader ? (
            <StyledButton
              onClick={this.handleFetchMoreContent}
              title={t('blocks.vod.displayMore')}
              theme={Button.themes.redPlain}
            >
              <Button.children.Text>
                {t('blocks.vod.displayMore')}
              </Button.children.Text>
            </StyledButton>
          ) : (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </ButtonWrapper>
      );
    }
    return false;
  }
}
