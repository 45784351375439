import get from 'lodash/get';

import { DEFAULT_LANG, DEFAULT_LANGS } from 'server/constants';
import { Langs } from 'shared/types';

export default function getCurrencyDetails(state: any) {
  const lang: string = get(state, 'page.data.lang', DEFAULT_LANG);
  const langs: Langs = get(state, 'page.data.zone.langs', DEFAULT_LANGS);
  const currency: string | null = get(state, 'page.data.zone.currency', null);
  const hidePriceDecimal: boolean = get(
    state,
    'page.data.zone.hidePriceDecimal',
    false
  );

  if (langs && langs[lang]) {
    const { currencyDisplay, currencyLocale } = langs[lang];
    return {
      currency,
      currencyDisplay,
      currencyLocale,
      hidePriceDecimal
    };
  }
  return {
    currency,
    currencyLocale: 'fr-FR',
    hidePriceDecimal
  };
}
