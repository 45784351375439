import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import Picture, { BaseWrapper } from 'shared/components/presentational/Picture';
import { container } from 'shared/constants/mixins';
import Link from 'shared/components/presentational/Link';

export const Wrapper = styled.section`
  ${container}
  display: grid;
  gap: var(--spacing-xl);
  overflow: hidden;
  min-width: 0;
`;

export const JaquettesGrid = styled.div`
  display: grid;
  gap: var(--spacing-s);
  grid-auto-rows: 1fr;
  min-width: 0;
  overflow: hidden;

  grid-template-columns: repeat(2, 1fr);
  ${mediaQueries.fromPhablet`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${mediaQueries.fromDesktop`
    grid-template-columns: repeat(5, 1fr);
  `}
  ${mediaQueries.fromHd`
    grid-template-columns: repeat(7, 1fr);
  `}
`;

export const CustomPictureWrapper = styled(BaseWrapper).attrs({})`
  min-width: 0;
`;

export const CardPicture = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  width: 100%;
  min-width: 0;
`;

export const CardLinkPicture = styled(Picture)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const SeeMoreIcon = styled(Picture)`
  width: 36px;
  height: 36px;
`;

export const SeeMoreWrapper = styled(Link)`
  color: var(--text-light);
  background-color: var(--surface-secondary);
  display: flex;
  gap: var(--spacing-s);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: var(--spacing-m);
  min-width: 0;
  ${mediaQueries.fromDesktop`
    padding: var(--spacing-l);
  `}
`;

export const Label = styled.p`
  margin: 0;
  font-size: var(--text-size-base);
`;
