import styled from 'styled-components';

import Button from 'shared/components/presentational/Button/Button';
import { BUTTON_SIZES } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const FormButton = styled(Button)`
  height: ${BUTTON_SIZES.form}px;
  font-size: ${pxInRem(16)};
  padding: 20px 40px;
`;

export default FormButton;
