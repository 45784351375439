import styled from 'styled-components';

const SQUARE_SIZE = 23;
const BADGE_BORDER_RADIUS = 25;

export const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing-xs) var(--spacing-m);
  align-items: center;
  gap: var(--spacing-xs);
  border-radius: ${BADGE_BORDER_RADIUS}px;
  background-color: var(--surface-primary);
  width: fit-content;

  img {
    height: ${SQUARE_SIZE}px;
    width: ${SQUARE_SIZE}px;
  }

  picture {
    width: unset;
  }
`;

export const Label = styled.span`
  color: var(--text-default);
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
`;
