/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps, FormattedSlide, Slide } from './types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from '../../../types';

export const formatSlide = (
  getLinkHrefWithAuthentication: PropsFormatterUtils['getLinkHrefWithAuthentication']
) => (slide: Slide): FormattedSlide => ({
  title: get(slide, 'title'),
  subtitle: get(slide, 'subtitle'),
  href: getLinkHrefWithAuthentication(get(slide, 'link', '')),
  imageUrl: getLinkHref(get(slide, 'media.link')),
  alt: slide.media.description ?? slide.media.filename
});

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => ({
  title: get(content, 'title.label'),
  titleHtmlTag: get(content, 'title.htmlTag'),
  subtitle: content.subtitle ?? undefined,
  template: get(content, 'template'),
  redirectButtonLabel: get(content, 'buttons.redirectButton.label'),
  redirectButtonUrl: getLinkHrefWithAuthentication(
    get(content, 'buttons.redirectButton.link', '')
  ),
  slider: (get(content, 'slider', []) as Slide[]).map(
    formatSlide(getLinkHrefWithAuthentication)
  )
});
