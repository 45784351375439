import React, { useCallback } from 'react';

import TabContent from 'shared/blocks/one-shop/offers-cards/components/TabContent/TabContent';
import { useTabs } from '../../../shared/components/Tabs/component/Tabs/providers/TabsProvider';
import { FormattedBannerTab, FormattedCardTab } from '../../types';
import { Tabs } from './styles/components';

export const TabsWrapper = ({
  tabs,
  displayTabs
}: {
  tabs: (FormattedBannerTab | FormattedCardTab)[];
  displayTabs: boolean;
}) => {
  const { currentTab } = useTabs();

  const renderOffersCard = useCallback(
    function renderOffersCard(tab, index) {
      return (
        <TabContent
          key={`${index}-${tab.title}`}
          tab={tab}
          isActive={currentTab === index}
          tabIndex={index}
        />
      );
    },
    [currentTab]
  );

  const tabsTitle = tabs.map(tab => tab.tabTitle || '');

  return (
    <Tabs tabsTitle={tabsTitle} displayTabs={displayTabs}>
      {tabs.map(renderOffersCard)}
    </Tabs>
  );
};
