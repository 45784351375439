import React from 'react';
import { useTranslation } from 'react-i18next';

import Error from 'shared/components/presentational/Error/Error';
import Loader from 'shared/components/presentational/Loader/Loader';
import { useSiteConfig } from 'shared/hooks/useSiteConfig';
import { Iframe, WrapperPlayerDailymotion } from './styles/Components';

interface DailymotionPlayerProps {
  id: string;
}

/**
 * Component that renders a Dailymotion video using their player iframe
 * Uses site configuration to get the player ID
 *
 * @param props - The video props containing the Dailymotion video ID
 * @returns An iframe with the Dailymotion player
 */
function DailymotionPlayer({ id }: DailymotionPlayerProps) {
  const { t } = useTranslation();
  const { data: siteConfig, isLoading, isError } = useSiteConfig();

  if (isLoading) {
    return <Loader />;
  }

  if (!siteConfig?.video_players?.dailymotion?.player_id) {
    return (
      <Error
        message={t('components.videoPlayer.errors.dailymotion.playerId')}
      />
    );
  }

  if (isError) {
    return <Error />;
  }

  return (
    <WrapperPlayerDailymotion>
      <Iframe
        src={`https://geo.dailymotion.com/player/${siteConfig.video_players.dailymotion.player_id}.html?video=${id}`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        data-testid="dailymotion-player-iframe"
      />
    </WrapperPlayerDailymotion>
  );
}

export default DailymotionPlayer;
