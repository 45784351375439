const updateRenewalForm = (
  formValues: {
    selectedOffer: string;
    minorsOffers: Record<string, boolean>;
    phoneNumber: string;
    selectedOperator: string;
    paymentMeanId: string;
  },
  userInfo: {
    equipmentId: string;
    personId: number;
    contractId: number;
    managementAct: string;
  }
) => ({
  type: 'UPDATE_RENEWAL_FORM',
  payload: {
    ...formValues,
    ...userInfo
  }
});

export default updateRenewalForm;
