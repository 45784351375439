import React from 'react';

import Slider from 'shared/components/presentational/Slider/Slider';
import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import { BannerSlideWrapper } from './styles/components';
import { Banner } from 'shared/blocks/one-shop/shared/components/Banner/Banner';
import { FormattedBannerTab } from '../../types';

interface Props {
  content: FormattedBannerTab['contents'];
  autoPlaySpeed: number | null;
}

export default function TabContentBanner({ content, autoPlaySpeed }: Props) {
  return content.length > 1 ? (
    <Slider
      showDots={true}
      isCenterMode={false}
      isInfinite={true}
      initialSlide={0}
      nextArrow={
        <OneShopArrow
          name="ArrowLeftOneShop"
          isTransparent={true}
          isSecondaryDark={true}
        />
      }
      prevArrow={<></>}
      slidesToShow={1}
      isOneShop={true}
      autoPlaySpeed={autoPlaySpeed}
    >
      {content.map((content, index) => (
        <BannerSlideWrapper
          key={`${index}-${content.title}`}
          data-index={`${index}-${content.title}`}
        >
          <Banner key={index} {...content} />
        </BannerSlideWrapper>
      ))}
    </Slider>
  ) : (
    <Banner {...content[0]} />
  );
}
