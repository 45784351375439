import React, { ReactNode, PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import ButtonLink from './core/ButtonLink';
import ButtonDefault from './core/ButtonDefault';
import * as Children from './core';
import * as themes from './themes';
import getSafeTheme from './helpers/getSafeTheme';

interface DefaultProps {
  loading?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  theme: any;
}

export interface Props extends DefaultProps {
  children: ReactNode;
  to?: string;
  className?: string;
  title?: string;
  onClick?: () => void;
  type?: string;
  defaultTheme?: any;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
}

export default class Button extends PureComponent<Props> {
  public static readonly themes = {
    ...themes
  };

  public static readonly defaultProps = {
    theme: themes.defaultTheme,
    loading: false,
    disabled: false,
    fluid: false,
    defaultTheme: themes.defaultTheme
  };

  public static children = {
    ...Children
  };

  public render(): ReactNode {
    const { theme, defaultTheme, variant, ...props } = this.props;
    const safeTheme = getSafeTheme(theme, defaultTheme);

    // Issues to resolve types checking with
    // `theme`, `visible`, `to` & `onClick`
    const renderButton = props.to ? (
      // @ts-ignore
      <ButtonLink {...props} variant={variant} />
    ) : (
      // @ts-ignore
      <ButtonDefault {...props} variant={variant} />
    );

    if (variant) {
      return renderButton;
    }
    return (
      <ThemeProvider theme={{ ...safeTheme }}>{renderButton}</ThemeProvider>
    );
  }
}
