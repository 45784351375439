import React, { useCallback } from 'react';

import { FormattedModal } from '../../types';
import { Form, ProductsWrapper, ButtonsWrapper } from './styles/components';
import Button from 'shared/components/presentational/Button/Button';
import Title from 'shared/components/presentational/Title/components/Title';
import Product from './Product';

type ModalContentProps = FormattedModal & {
  handleClose: () => void;
};
const ModalContent = ({
  handleClose,
  title,
  product,
  minorProducts = [],
  minorProductsIds,
  cancelButton,
  confirmButton
}: ModalContentProps) => {
  const renderMinorProducts = useCallback(
    product => <Product key={product.title} {...product} />,
    []
  );

  return (
    <Form action={confirmButton?.link || undefined}>
      <Title titleLabel={title} titleHtmlTag="h2" />
      <input
        type="text"
        name={'product'}
        value={product.productId}
        hidden
        readOnly
      />
      <input
        type="text"
        name={'option'}
        value={product.productId}
        hidden
        readOnly
      />
      {minorProductsIds !== '' && (
        <input
          type="text"
          name={'minorProducts'}
          value={minorProductsIds}
          hidden
          readOnly
        />
      )}
      <ProductsWrapper>
        {minorProducts.map(renderMinorProducts)}
        <Product {...product} isMainProduct sendToCRM />
      </ProductsWrapper>
      <ButtonsWrapper>
        {cancelButton ? (
          <Button variant="secondary" onClick={handleClose} type="button">
            <Button.children.Text>{cancelButton.label}</Button.children.Text>
          </Button>
        ) : null}
        {confirmButton ? (
          <Button variant="secondary" type="submit">
            <Button.children.Text>{confirmButton.label}</Button.children.Text>
          </Button>
        ) : null}
      </ButtonsWrapper>
    </Form>
  );
};

export default ModalContent;
