import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  OfferDetailsRoc,
  Props
} from '../components/offerDetails/component/OfferDetailsRoc';
import getCurrencyDetails from 'shared/modules/page/selectors/getCurrencyDetails';
import { CurrencyDisplay } from 'shared/types';

function mapStateToProps(
  state: any,
  ownProps: Omit<
    Props,
    'locale' | 'currency' | 'hidePriceDecimal' | 'currencyDisplay'
  > & { currency?: string }
): {
  locale: string;
  currency: string | null;
  hidePriceDecimal: boolean;
  currencyDisplay?: CurrencyDisplay;
} {
  const {
    currency,
    currencyLocale,
    hidePriceDecimal,
    currencyDisplay
  } = getCurrencyDetails(state);
  return {
    locale: currencyLocale,
    currency: ownProps.currency || currency,
    hidePriceDecimal,
    currencyDisplay
  };
}

export default compose<Props, any>(connect(mapStateToProps))(OfferDetailsRoc);
