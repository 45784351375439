import styled from 'styled-components';

import { container } from 'shared/constants/mixins';

export const Wrapper = styled.section`
  ${container};
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  overflow: hidden;
`;
