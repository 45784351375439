import styled, { css } from 'styled-components';

export interface SelectTheme {
  hasValue?: boolean;
  error?: boolean;
  border?: boolean;
}

export const getSelectTheme = () => {
  return css<SelectTheme>`
    .ReactSelect {
      &__single-value {
        color: var(--text-title);
      }

      &__placeholder {
        color: var(--text-light);
      }

      &__control {
        outline: none;
        color: var(--text-title);
        background: var(--surface-background);
        border: ${props =>
          props.hasValue
            ? `1px solid var(--surface-border-default)`
            : props.error
            ? `border: 1px solid var(--semantic-error)`
            : `1px solid var(--surface-border-light)`};

        &--is-focused {
          border-color: var(--surface-border-default);
          box-shadow: unset;
        }

        &:hover {
          border-color: unset;
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__clear-indicator {
        cursor: pointer;
      }

      &__menu {
        border: solid 1px var(--surface-border-light);
        border-radius: 3px;
        z-index: 1000;
        margin: 1px;
      }

      &__menu-list {
        border-radius: 3px;
        background: var(--surface-background);
        color: var(--text-light);
      }

      &__menu-list {
        border-radius: 0 0 4px 4px;
      }

      &__option {
        padding: 10px 12px;

        &--is-focused,
        &--is-selected,
        &:hover {
          cursor: pointer;
          color: var(--text-light);
          background-color: var(--surface-primary);
        }
      }
    }
  `;
};

export const StyledWrapper = styled.span<SelectTheme>`
  ${getSelectTheme()};
`;
