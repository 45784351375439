/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { PureComponent } from 'react';
import styled from 'styled-components';

import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Picture from 'shared/components/presentational/Picture';
import BaseRaw from 'shared/components/presentational/Raw/Raw';
import Button from 'shared/components/presentational/Button';
import { FONT_FAMILIES } from 'shared/constants/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--surface-border-light);
`;

const Image = styled(Picture)<any>`
  width: 100%;
  object-fit: cover;
`;

const Title = styled.h3`
  color: var(--text-default);
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(14)};
  line-height: ${pxInRem(18)};
  margin: 12px 0 3px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px 30px 30px;
`;

const Raw = styled(BaseRaw)<any>`
  color: var(--text-light);
  text-align: center;
`;

const TitleLink = styled(CustomLink)<any>`
  cursor: pointer;
  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface Props {
  imageUrl: string;
  imageAlt: string;
  detailUrl: string;
  buttonUrl: string | null;
  label: string;
  description: string;
  buttonLabel: string;
  onButtonClick?: () => void;
}

export default class Card extends PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      imageUrl,
      imageAlt,
      detailUrl,
      buttonUrl,
      label,
      description,
      buttonLabel,
      onButtonClick
    } = this.props;

    return (
      <Wrapper>
        <Image url={imageUrl} alt={imageAlt} disableLazy />
        <ContentWrapper>
          <div>
            <TitleLink to={detailUrl}>
              <Title>{label}</Title>
            </TitleLink>
            <Raw html={description} />
          </div>
          {buttonUrl && (
            <ButtonWrapper>
              <Button to={buttonUrl} onClick={onButtonClick} variant="tertiary">
                <Button.children.Text>{buttonLabel}</Button.children.Text>
              </Button>
            </ButtonWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    );
  }
}
