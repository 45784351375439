import * as React from 'react';

import { TEMPLATES } from '../constants';
import {
  DefaultTabs,
  ProgressTabs,
  UnderscoreTabs,
  ImagesTabs
} from './templates';
import { ComponentProps } from '../type';
import { COLORS } from 'shared/constants/theme';

const Tabs = ({
  template,
  tabs,
  pushURLHandler,
  currentTabColor,
  labelColor,
  backgroundColor,
  borderColor,
  title
}: ComponentProps) => {
  switch (template) {
    case TEMPLATES.PROGRESS:
      return <ProgressTabs tabs={tabs} pushURLHandler={pushURLHandler} />;
    case TEMPLATES.UNDERSCORE:
      return <UnderscoreTabs tabs={tabs} currentTabColor={currentTabColor} />;
    case TEMPLATES.IMAGES:
      return (
        <ImagesTabs
          tabs={tabs}
          title={title}
          labelColor={COLORS.black}
          backgroundColor={backgroundColor}
          currentTabColor={currentTabColor}
          pushURLHandler={pushURLHandler}
        />
      );
    default:
      return (
        <DefaultTabs
          tabs={tabs}
          pushURLHandler={pushURLHandler}
          labelColor={labelColor}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        />
      );
  }
};

export default Tabs;
