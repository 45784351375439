import styled, { keyframes } from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseSlider from 'shared/components/presentational/Slider/Slider';

export const fadeAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;

export const FadeContainer = styled.div<{ isActive: boolean }>`
  animation: ${fadeAnimation} 500ms ease-in 0s;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const Content = styled.div`
  margin: 0 calc(var(--container-minimum-padding) * -1);
  ${mediaQueries.fromTablet`
    margin: 0 calc(var(--container-minimum-padding-tablet) * -1);
  `}
`;

export const CardsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: var(--spacing-xs);

  ${mediaQueries.fromTablet`
    padding: var(--spacing-xs) calc(var(--container-minimum-padding-tablet) - var(--spacing-xs));
  `}
`;

export const CardSlider = styled(BaseSlider)`
  .slick-track {
    padding: 0 var(--container-minimum-padding-tablet);
  }

  .slick-next {
    right: var(--spacing-xs);
  }

  .slick-prev {
    left: var(--spacing-xs);
  }

  ${mediaQueries.fromTablet`
  margin: 0 calc(var(--spacing-xs) * -1);
  `}
`;

export const SlideWrapper = styled.div`
  display: flex !important;
  padding: 0 var(--spacing-xs);
  ${mediaQueries.fromHd<{ slidesToShow: number }>`
  width: ${props =>
    `calc((100vw - var(--container-minimum-padding-tablet) * 2 ) / ${props.slidesToShow})`};
  `}
`;

export const BannerSlideWrapper = styled.div`
  display: flex !important;
  background-color: var(--surface-full);
`;
