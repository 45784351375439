import { getLinkHref } from 'shared/helpers/uri';
import { Channel, Content, FormattedProps, Group } from './types';
import { EngagementUnit } from '../../shared/types';

export default function propsFormatter(content: Content): FormattedProps {
  const minorProductsEngagementUnit: Record<string, EngagementUnit> = {};

  content.minorProducts.forEach(minor => {
    if (minor.productId && minor.engagementUnit) {
      minorProductsEngagementUnit[minor.productId] =
        EngagementUnit[minor.engagementUnit];
    }
  });

  return {
    title: content.title,
    saleDeviceId: content.saleDeviceId,
    api: {
      getRenewalOffer: {
        url: content.apis?.crm_management_acts_qrcode.url ?? '',
        method: content.apis?.crm_management_acts_qrcode.method || 'GET'
      }
    },
    productPriceLabel: content.productPriceLabel ?? undefined,
    productOffer: content.products.map(product => ({
      productId: product.productId,
      engagementUnit: EngagementUnit[product.engagementUnit],
      groups: product.groups.map(
        (group): Group => ({
          title: group.title || '',
          subtitle: group.subtitle || '',
          isMoreChannels: group.isMoreChannels,
          channels: group.channels.map(
            (channel): Channel => ({
              label: channel.channel.media.description ?? null,
              href: getLinkHref(channel.channel.media.link)
            })
          )
        })
      ),
      moreChannelsIcon: {
        label: product.moreChannelsIcon?.description,
        href: product.moreChannelsIcon?.link.href
      }
    })),
    minorProductsTitle: content.minorProductsTitle,
    minorProductsEngagementUnit,
    firstAlertBox: {
      status: content.firstAlertBox.status,
      title: content.firstAlertBox.title,
      content: content.firstAlertBox.content,
      link: content.firstAlertBox.cta?.link
        ? {
            label: content.firstAlertBox.cta?.label ?? '',
            href: getLinkHref(content.firstAlertBox.cta.link)
          }
        : null
    },
    secondAlertBox: {
      status: content.secondAlertBox.status,
      title: content.secondAlertBox.title,
      content: content.secondAlertBox.content,
      link: content.secondAlertBox.cta?.link
        ? {
            label: content.secondAlertBox.cta?.label ?? '',
            href: getLinkHref(content.secondAlertBox.cta.link)
          }
        : null
    },
    phoneLabel: content.phoneNumberLabel,
    mobileOperatorTitle: content.mobileOperatorTitle,
    submitButton: {
      label: content.submitButton.label,
      link: getLinkHref(content.submitButton.link)
    }
  };
}
