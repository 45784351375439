import React from 'react';

export default ({ className, width = '1em', height = '1em' }) => (
  <svg
    data-testid="info"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.43527 3.5625C2.56067 3.5625 1.83929 2.828 1.83929 1.94725C1.83929 1.047 2.53833 0.3125 3.43527 0.3125C4.31306 0.3125 5.03125 1.047 5.03125 1.94725C5.03125 2.828 4.30987 3.5625 3.43527 3.5625ZM2.15848 11.6875V4.5375H4.71205V11.6875H2.15848ZM0.5625 4.5375H4.71205V7.1375H0.5625V4.5375Z"
      fill="currentColor"
    />
  </svg>
);
