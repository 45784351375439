import * as React from 'react';
import styled from 'styled-components';

import Tab from 'shared/components/presentational/Tab';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { ComponentProps } from 'shared/blocks/tabs/type';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';

const Wrapper = styled.div`
  margin: 24px 15px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Items = styled.ul<{ backgroundColor: string }>`
  list-style-type: none;
  display: flex;
  overflow: auto;
  height: 100px;
  width: 444px;
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  ${mediaQueries.toPhablet`
      width: 345px
  `};

  li:first-child {
    margin: 8px;
    height: 84px;
    align-self: center;
    border-radius: var(--border-radius-m);
    width: 214px;
  }

  li:last-child {
    margin: 8px;
    height: 84px;
    align-self: center;
    border-radius: var(--border-radius-m);
    width: 214px;
  }
`;

const Item = styled.li`
  display: flex;
  width: calc(100% / 3);
  max-width: 260px;
  min-width: 100px;
`;

const Title = styled.h3`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(20)};
  line-height: ${pxInRem(24)};
  margin-bottom: ${pxInRem(16)};
  letter-spacing: 0px;
  text-align: center;
`;

type Props = Pick<
  ComponentProps,
  | 'tabs'
  | 'pushURLHandler'
  | 'labelColor'
  | 'backgroundColor'
  | 'currentTabColor'
  | 'title'
>;

const ImagesTabs = ({
  tabs,
  backgroundColor,
  currentTabColor,
  title
}: Props) => {
  const renderTab = React.useCallback(
    (tab, index) => (
      <Item key={`Tab_${index}`}>
        <Tab
          label={tab.label}
          active={tab.active}
          url={tab.url}
          labelColor={tab.labelColor!}
          backgroundColor={backgroundColor}
          pictogram={tab.pictogram && tab.pictogram.href}
          currentTabColor={currentTabColor}
        />
      </Item>
    ),
    [backgroundColor, currentTabColor]
  );

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Items backgroundColor={backgroundColor}>{tabs.map(renderTab)}</Items>
    </Wrapper>
  );
};

export default ImagesTabs;
