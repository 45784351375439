import styled, { css } from 'styled-components';

// Global
import Picture, {
  BaseWrapper as BasePictureWrapper
} from 'shared/components/presentational/Picture';
import Raw from 'shared/components/presentational/Raw/Raw';
import {
  COLORS,
  FONT_FAMILIES,
  FONT_WEIGHTS,
  DIMENSIONS
} from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
// Local
import { FRAGMENT_MAX_WIDTH, FRAGMENT_VERTICAL_MARGIN } from './constants';
import { collapsePadding } from 'shared/constants/mixins';
import CustomLink from 'shared/components/presentational/CustomLink/CustomLink';
import { HEADER_UNIFIED_DESKTOP_HEIGHT } from 'shared/constants/heights';

export const Container = styled.div`
  max-width: ${FRAGMENT_MAX_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${DIMENSIONS.CONTAINER_MINIMUM_PADDING}px;
`;

export const InnerModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  padding: 0;
  ${collapsePadding(pxInRem(20))}
  max-width: ${FRAGMENT_MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;

  &:before {
    margin: 0;
  }

  ${mediaQueries.toTablet`
    padding: 0 var(--spacing-l);
    gap: calcvar(--spacing-s);
`}
`;

export const MainImage = styled(Picture).attrs({
  disableLazy: true
})`
  position: relative;
  width: 100%;
  margin-top: ${HEADER_UNIFIED_DESKTOP_HEIGHT}px;
  border-radius: var(--border-radius-xxs);

  ${mediaQueries.toTablet`
   margin-top: 0;
 `}
`;

export const Title = styled.h1`
  color: var(--text-title);
  font-size: ${pxInRem(40)};
  margin-bottom: ${pxInRem(20)};
  font-weight: ${FONT_WEIGHTS.normal};
  ${mediaQueries.toTablet`
      font-size: ${pxInRem(28)};
  `}
`;

export const Subtitle = styled.h2`
  font-size: ${pxInRem(20)};
  margin-bottom: ${pxInRem(20)};
  font-weight: ${FONT_WEIGHTS.demi};
`;

const withFragmentMargin = css`
  position: relative;
  margin-top: ${pxInRem(FRAGMENT_VERTICAL_MARGIN)};
  margin-bottom: ${pxInRem(FRAGMENT_VERTICAL_MARGIN)};
`;

export const ImageFragmentWrapper = styled(CustomLink)`
  ${withFragmentMargin};
`;

export const FragmentWrapper = styled.div`
  ${withFragmentMargin};
`;

export const MediaFragmentWrapper = styled(FragmentWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PictureWrapper = styled(BasePictureWrapper)`
  max-width: ${FRAGMENT_MAX_WIDTH}px;
`;

export const ImageFragment = styled(Picture).attrs({
  disableLazy: true,
  customWrapper: PictureWrapper
})``;

export const VideoFragment = styled.video.attrs({
  controls: true
})`
  max-width: ${FRAGMENT_MAX_WIDTH}px;
  width: 100%;
`;

export const TextFragment = styled(Raw)`
  font-size: ${pxInRem(16)};
  font-weight: 300;
  line-height: 1.5em;
  font-family: ${FONT_FAMILIES.base};
  strong,
  b {
    font-weight: ${FONT_WEIGHTS.demi};
  }
  h1 {
    font-size: ${pxInRem(40)};
    margin-bottom: ${pxInRem(20)};
    font-weight: ${FONT_WEIGHTS.normal};
  }
  h2 {
    font-size: ${pxInRem(20)};
    margin-bottom: ${pxInRem(20)};
    font-weight: ${FONT_WEIGHTS.demi};
  }
  blockquote {
    font-style: italic;
    font-size: ${pxInRem(18)};
    border-left: 5px solid ${COLORS.amaranth};
    padding-left: 20px;
    margin: ${pxInRem(40)} 70px;
    margin-right: 0;
  }
  ol {
    list-style: decimal;
    margin-left: 1.5rem;
  }
  ul {
    list-style: initial;
    margin-left: 1.5rem;
  }
  ${mediaQueries.toTablet`
    font-size: ${pxInRem(15)};
    h1{
      font-size: ${pxInRem(28)};
    }
    blockquote {
      font-size: ${pxInRem(16)};
    }
  `}
`;
