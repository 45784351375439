/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { matchPath } from 'react-router';

import Logger from 'shared/helpers/logger/getLogger';
import getPageURI from 'shared/helpers/uri/getPageURI';
import ConfigHelper from 'shared/helpers/config/index';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import { APIHttpClient } from 'shared/modules/http/HttpClient';

export default async (path, hostname, { uniqueId, lang, userIsConnected }) => {
  const miniSiteDomain = getMiniSiteDomain(hostname);
  const url = getPageURI(path, { authenticated: userIsConnected ? 1 : 0 });

  Logger.info(
    `Fetch page request: ${url}`,
    Logger.generateApiMetadata('structure', 'fetchPage', {
      baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
      uniqueId,
      url,
      hostname,
      miniSiteDomain
    })
  );

  let headers = {
    'X-UNIQUE-ID': uniqueId,
    'X-MINISITE-DOMAIN': miniSiteDomain
  };

  if (lang) {
    headers['X-LANG'] = lang;
  }

  return APIHttpClient.get(url, {
    headers,
    validateStatus: status => (status >= 200 && status < 300) || status === 400
  })
    .then(({ data }) => {
      // Response is invalid
      if (!data || !data.statusCode || !data.blocks) {
        const meta = Logger.generateApiMetadata('structure', 'fetchPage', {
          baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
          uniqueId,
          url,
          hostname,
          miniSiteDomain,
          data
        });

        Logger.error(`Fetch page error: ${url}`, meta);

        const message = `Api response could not be understood: ${JSON.stringify(
          data
        )}`;

        return Promise.reject(new Error(message));
      }

      // Response is successful
      const meta = Logger.generateApiMetadata('structure', 'fetchPage', {
        baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
        uniqueId,
        url,
        hostname,
        miniSiteDomain
      });

      Logger.info(`Fetch page success: ${url}`, meta);

      if (process.env.RAZZLE_ENABLE_LOCAL_CONTRIBUTION === 'true') {
        let localContrib = null;

        try {
          localContrib = require('/localcontrib.js');
        } catch {
          // do nothing in the case 'localcontrib.js' does not exist
        }

        if (localContrib) {
          const matchingRegexp = Object.keys(localContrib).find(
            pattern =>
              matchPath(path, { path: pattern, exact: true, strict: true }) !==
              null
          );

          // override contrib using the first rule where the regex matches the path
          if (matchingRegexp) {
            // the overriding can be a function or an object
            const updatedContrib =
              typeof localContrib[matchingRegexp] === 'function'
                ? localContrib[matchingRegexp](data)
                : localContrib[matchingRegexp];

            Logger.info(
              `Contribution for this page was modified by localcontrib.js.`
            );
            return Promise.resolve(updatedContrib);
          }
        }
      }

      return Promise.resolve(data);
    })
    .catch(error => {
      // Server had a problem
      const meta = Logger.generateApiMetadata('structure', 'fetchPage', {
        baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
        uniqueId,
        url,
        hostname,
        miniSiteDomain,
        error: {
          message: error.message,
          response: {
            data: error.response?.data,
            status: error.response?.status
          },
          request: {
            method: error.request?.method,
            url: error.request?.path
          }
        }
      });
      Logger.error(`Fetch page error: ${url}`, meta);

      const message = error.response
        ? `Api response is unsuccessful: ` +
          `${error.response.status} ${error.response.statusText}`
        : `Api error: ${error.message}`;
      return Promise.reject(new Error(message));
    });
};
