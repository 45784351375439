import styled from 'styled-components';

import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Raw from 'shared/components/presentational/Raw/Raw';
import PictureBase from 'shared/components/presentational/Picture';

export const WrapperList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Title = styled.h3`
  margin-bottom: 15px;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  font-size: ${pxInRem(22)};
  text-align: center;
  line-height: ${pxInRem(24)};
  text-transform: uppercase;

  ${mediaQueries.toPhablet`
    margin-bottom: 5px;
    font-size: ${pxInRem(18)};
  `}
`;

export const Subtitle = styled.p`
  text-align: center;
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.base};
  margin-bottom: 40px;
  color: ${props => props.color ?? COLORS.boulder};
  ${mediaQueries.toPhablet`
    margin-bottom: 30px;
  `}
`;

export const Picture = styled(PictureBase).attrs({ height: 150 })`
  max-height: 150px;
`;

export const PicturePlaceholder = styled.div`
  flex-basis: 150px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;

export const Text = styled(Raw)`
  color: var(--text-light);
  font-size: ${pxInRem(14)};
  font-family: ${FONT_FAMILIES.base};
  margin-top: 20px;
`;

export const Item = styled.div<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  & > picture {
    width: unset;
    align-self: center;
  }
  & > :first-child > :first-child {
    margin-top: 0;
  }
  width: 300px;
  margin: 0 15px 15px;
  ${props =>
    props.hasBorder &&
    `border: 1px solid var(--surface-border-light); 
     padding: 40px 20px;
   `}
`;
