/**
 * This file is part of the "Awaken Media project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

import { Content, FormattedProps, SummaryNextStep } from './types';
import themeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import { Offer } from '../shared/types';
import { PropsFormatterUtils } from 'shared/types';
import { createTemplate } from 'shared/helpers/template';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  return {
    title: get(content, 'title.label', ''),
    titleColor: get(content, 'title.color', ''),
    titleHtmlTag: get(content, 'title.htmlTag'),
    mainOfferSubtitle: get(content, 'mainOfferSubtitle'),
    minorOfferSubtitle: get(content, 'minorOfferSubtitle'),
    agreementText: get(content, 'summaryTosAgreementText'),
    agreementError: get(content, 'summaryTosAgreementError'),
    agreementColorError: get(content, 'summaryTosAgreementColorError'),
    newsletterAgreementLabel: get(content, 'newsletterAgreementLabel', ''),
    mainColor: get(content, 'hoverColor'),
    informationMessage: {
      message: get(content.informationMessage, 'message'),
      image: {
        imageAlt: get(content.informationMessage, 'image.filename'),
        imageSrc: get(content.informationMessage, 'image.link.href')
      }
    },
    redirectPopin: {
      title: get(content, 'redirectPopinTitle'),
      text: get(content, 'redirectPopinText'),
      link: {
        label: get(content, 'redirectPopinLinkLabel'),
        color: get(content, 'redirectPopinLinkLabelColor'),
        url: getLinkHrefWithAuthentication(get(content, 'redirectPopinLink'))
      },
      image: {
        url: get(content, 'redirectPopinImage.link.href'),
        alt: get(content, 'redirectPopinImage.filename')
      }
    },
    errorPopin: {
      title: get(content.errorPopin, 'popinTitle'),
      text: get(content.errorPopin, 'popinText'),
      link: {
        label: get(content.errorPopin, 'popinLinkLabel'),
        color: get(content.errorPopin, 'popinLinkLabelColor'),
        url: getLinkHrefWithAuthentication(get(content.errorPopin, 'popinLink'))
      },
      image: {
        url: get(content.errorPopin, 'popinImage.link.href'),
        alt: get(content.errorPopin, 'popinImage.filename')
      }
    },
    button: {
      label: get(content, 'buttons.confirmationButton.label'),
      theme: themeFactory({
        color: get(content, 'buttons.confirmationButton.labelColor'),
        backgroundColor: get(
          content,
          'buttons.confirmationButton.backgroundColor'
        ),
        hover: {
          color: get(content, 'buttons.confirmationButton.labelColor'),
          backgroundColor: get(content, 'hoverColor')
        }
      }),
      link: getLinkHrefWithAuthentication(
        get(content, 'buttons.confirmationButton.link')
      )
    },
    offers: get(content, 'offers').map((offer: Offer) => ({
      title: get(offer, 'title'),
      titleColor: get(offer, 'titleColor'),
      options: get(offer, 'options').map(option => ({
        code: get(option, 'code'),
        secondaryCodes: get(option, 'secondaryCodes').split(';'),
        duration: get(option, 'duration').toString(),
        previousPrice: get(option, 'strikeThroughPrice'),
        price: get(option, 'price'),
        priceInteger: get(option, 'marketingPriceInteger'),
        priceExponent: get(option, 'marketingPriceExponent'),
        priceTerms: get(option, 'marketingPriceTerms'),
        text: get(option, 'summaryText'),
        nextStepsTitle: get(option, 'summaryNextStepsTitle'),
        nextSteps: get(option, 'summaryNextSteps', [])
          ? (get(option, 'summaryNextSteps', []) as SummaryNextStep[]).map(
              (nextStep: SummaryNextStep) => ({
                title: get(nextStep, 'title'),
                image: {
                  url: get(nextStep, 'image.link.href'),
                  alt: get(nextStep, 'image.link.filename')
                },
                description: get(nextStep, 'description')
              })
            )
          : undefined,
        subscriptionText: get(option, 'summarySubscriptionText')
      })),
      minorOffers: get(offer, 'minorOffers').map(minorOffer => ({
        code: get(minorOffer, 'code'),
        description: get(minorOffer, 'description'),
        price: get(minorOffer, 'price'),
        marketingPrice: get(minorOffer, 'marketingPrice'),
        imageAlt: get(minorOffer, 'image.filename'),
        imageUrl: get(minorOffer, 'image.link.href'),
        billingInformation: get(minorOffer, 'billingInformation')
      }))
    })),
    createSubscriptionAPI: ({ appLocations }: { appLocations: string }) => ({
      url: createTemplate(get(content, 'apis.crm_subscription.url'))({
        salesDevice: get(
          content,
          'apis.crm_subscription.parameters.salesDevice'
        ),
        userId: get(content, 'apis.crm_subscription.parameters.userId'),
        distributorId: get(
          content,
          'apis.crm_subscription.parameters.distributorId'
        ),
        operatorCod: get(
          content,
          'apis.crm_subscription.parameters.operatorCod'
        ),
        appLocations
      }),
      method: get(content, 'apis.crm_subscription.method')
    })
  };
};
