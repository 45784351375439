import React from 'react';

import Slider, { Props as SliderProps } from '../Slider/Slider';
import { List } from './styles/components';
import { OneShopArrow } from '../Slider/styles/OneShopComponents';

const SliderOrList = (props: SliderProps) => {
  const { children, slidesToShow, ...restProps } = props;

  const slidesCount = React.Children.count(children);

  if (slidesCount > slidesToShow) {
    return (
      <Slider
        slidesToShow={slidesToShow}
        nextArrow={<OneShopArrow name="ArrowLeftOneShop" />}
        prevArrow={<OneShopArrow name="ArrowLeftOneShop" />}
        {...restProps}
      >
        {children}
      </Slider>
    );
  }

  return (
    <List slidesToShow={slidesToShow} {...restProps}>
      {children}
    </List>
  );
};

export default SliderOrList;
