import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { Api } from 'shared/types';

interface Props {
  getQrCodeOrderStatus: Api;
  orderId: string | null;
  correlationId: string | null;
}

export const useOrderStatusPolling = ({
  orderId,
  getQrCodeOrderStatus,
  correlationId
}: Props) => {
  const [isPollingActive, setIsPollingActive] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsPollingActive(false);
    }, 120000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useQuery({
    queryKey: ['orderStatus', orderId],
    queryFn: async () => {
      if (!orderId || !correlationId) {
        throw new Error(
          'Missing required parameters: orderId or correlationId'
        );
      }

      if (!isPollingActive) {
        throw new Error('blocks.renewalForm.error.timeout');
      }

      const urlTemplate = getQrCodeOrderStatus.url;
      const url = urlTemplate.replace('{orderId}', orderId);

      const response = await fetch(url, {
        method: getQrCodeOrderStatus.method,
        headers: {
          correlationId
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch order status');
      }

      const data = await response.json();
      return data.status;
    },
    enabled: !!orderId,
    refetchInterval: isPollingActive ? 5000 : false
  });
};
