import { Link, Media, Image, BannerUrlByDevice } from 'shared/types';
import { FormattedTab } from '../shared/components/Tabs/component/Tabs/types';
import {
  EngagementUnit,
  EngagementUnitShort,
  FormattedGroup,
  ProductMajor
} from '../shared/types';

type Title = {
  label: string;
  htmlTag: string;
};

type Button = {
  label: string;
  link: Link | null;
};

type Group = {
  title: string | null;
  subtitle: string | null;
  exponent: string | null;
  isMoreChannels: boolean;
  channels: Channel[];
};

type Channel = {
  exponent?: string | null;
  channel: {
    title: string;
    description: string | null;
    media: Media;
    link: Link;
  };
};

type FormattedProduct = {
  groups: FormattedGroup[];
  primaryButton: { label: string; link: string } | null;
  secondaryButton: { label: string; link: string } | null;
  moreChannelsIcon: {
    label: string | null;
    href: string;
  };
} & ProductMajor;

type CardProduct = {
  productId: string;
  title: string;
  subtitle: string | null;
  primaryButton: Button;
  secondaryButton?: Button;
  moreChannelsIcon: Image;
  groups: Group[];
  promotionalSticker: string | null;
  availableCommitmentDurations: string | null;
  priceInformation: string | null;
  commitmentConditions: string | null;
  engagementDuration: string | null;
  engagementUnit: EngagementUnitShort;
  netPrice: number | null;
  price: number | null;
  promoDuration: string | null;
};

type BannerProduct = {
  product_id: string;
  title: string;
  description: string | null;
  primary_button: Button;
  media: {
    desktop_banner: Media;
    tablet_banner: Media;
    mobile_banner: Media;
  };
};

export enum TEMPLATES {
  card = 'card',
  banner = 'banner'
}

export type CardTab = {
  template: TEMPLATES.card;
  title: string;
  tab_title: string | null;
  subtitle: string | null;
  products: CardProduct[];
};

export type BannerTab = {
  template: TEMPLATES.banner;
  tab_title: string | null;
  autoPlaySpeed: number | null;
  products: BannerProduct[];
};

export type FormattedBannerTab = {
  template: TEMPLATES.banner;
  tabTitle: string | null;
  autoPlaySpeed: number | null;
  contents: {
    productId: string;
    title: string;
    description: string | null;
    primaryButton: { label: string; link: string } | null;
    bannerUrlByDevice: BannerUrlByDevice;
  }[];
};

export type FormattedCardTab = {
  template: TEMPLATES.card;
  tabTitle: string | null;
  title: string;
  subtitle: string | null;
  contents: {
    productId: string;
    title: string;
    promotionalSticker: string | null;
    offerDetails: {
      availableCommitmentDurations: string | null;
      commitmentConditions: string | null;
      engagementDuration: string | null;
      engagementUnit: EngagementUnit | null;
      netPrice: number | null;
      price: number | null;
      priceInformation: string | null;
      promoDuration: string | null;
    };
    groups: FormattedGroup[];
    primaryButton: { label: string; link: string } | null;
    secondaryButton: { label: string; link: string } | null;
    moreChannelsIcon: {
      label: string | null;
      href: string;
    };
  }[];
};

type Tab = CardTab | BannerTab;

type FormattedProps = {
  title: Title;
  subtitle: string;
  displayTabs: boolean;
  tabs: (FormattedBannerTab | FormattedCardTab)[];
};

type Content = {
  title: Title;
  subtitle: string;
  display_tabs: boolean;
  tabs: Tab[];
};

export { Content, FormattedProps, FormattedProduct, FormattedTab };
