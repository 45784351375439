import parsePhoneNumber from 'libphonenumber-js';

import { Operator } from '../types';

/**
 * Returns a list of operators compatible with the given phone number
 */
export const getCompatibleOperators = (
  phoneNumber: string,
  operators: Operator[]
): string | null => {
  try {
    const phone = parsePhoneNumber(phoneNumber);

    if (!phone?.isValid()) {
      return null;
    }

    const nationalNumber = phone.nationalNumber;

    const filteredOperators = operators.filter(
      operator =>
        operator.areaCode.replace('+', '') === phone.countryCallingCode &&
        (operator.phonePrefixList.length === 0 ||
          operator.phonePrefixList.some(prefix =>
            nationalNumber.startsWith(prefix)
          ))
    );
    if (filteredOperators.length === 1) {
      return filteredOperators[0].code;
    }
    return null;
  } catch (e) {
    return null;
  }
};

/**
 * Validates a phone number for Formik
 * Returns undefined if valid, error message string if invalid
 */
export const phoneValidator = (value: string): string | undefined => {
  if (!value?.trim()) {
    return 'Phone number is required';
  }

  try {
    const phone = parsePhoneNumber(value);

    if (!phone?.isValid()) {
      return 'Please enter a valid phone number';
    }

    return undefined;
  } catch {
    return 'Please enter a valid phone number';
  }
};
