import styled from 'styled-components';

import BaseWrapper from 'shared/components/presentational/Wrapper/Wrapper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

export const AlertBoxContainer = styled.div`
  margin: var(--spacing-l) var(--spacing-m);
`;

export const Wrapper = styled(BaseWrapper)`
  padding: 0 var(--container-minimum-padding);
`;

export const Title = styled.h1`
  font-family: ${FONT_FAMILIES.brand};
  font-size: var(--text-size-h1);
  line-height: var(--line-height-xxl);
  font-weight: ${FONT_WEIGHTS.bold};
  color: var(--text-title);
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const PendingContainer = styled.div`
  display: flex;
  border: 1px solid var(--surface-border-light);
  border-radius: var(--border-radius-m);
  padding: var(--spacing-m);
  gap: var(--spacing-m);
`;
