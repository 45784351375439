import React from 'react';
import { Field } from 'formik';

import { OptionsList, Option, Label } from '../styles/components';
import Checkbox from 'shared/components/presentational/Form/Checkbox';
import OfferDetailsRocContainer from '../../../shared/containers/OfferDetailsRocContainer';
import { Offer } from '../types';
import { EngagementUnit } from '../../../shared/types';

interface MinorOptionsListProps {
  majorOffers: Record<string, Offer>;
  minorOffers: Record<string, Offer>;
  disabledMinorOptionsIds: string[];
  minorProductsEngagementUnit: Record<string, EngagementUnit>;
  setFieldValue: (field: string, value: boolean) => void;
  values: {
    selectedOffer: string;
    minorsOffers: Record<string, boolean>;
  };
}

const MinorOptionsList: React.FC<MinorOptionsListProps> = ({
  majorOffers,
  minorOffers,
  disabledMinorOptionsIds,
  minorProductsEngagementUnit,
  setFieldValue,
  values
}) => {
  return (
    <OptionsList>
      {Object.values(minorOffers).map(option => {
        const isForbidden = Object.values(majorOffers)
          .find(v => v.id === values.selectedOffer)
          ?.forbiddenOptions.includes(option.id);

        if (isForbidden) {
          return null;
        }

        const isDisabled = disabledMinorOptionsIds.includes(option.id);

        return (
          <Option key={option.id}>
            <Field
              component={Checkbox}
              name={`minorsOffers.${option.id}`}
              checked={values.minorsOffers?.[option.id] || false}
              onChange={() =>
                setFieldValue(
                  `minorsOffers.${option.id}`,
                  !values.minorsOffers[option.id]
                )
              }
              disabled={isDisabled}
              label={<Label isDisabled={isDisabled}>{option.title}</Label>}
            />
            <OfferDetailsRocContainer
              price={option.price}
              currency={option.currency}
              engagementUnit={minorProductsEngagementUnit[option.id] ?? null}
              variant="modal"
              isDisabled={isDisabled}
            />
          </Option>
        );
      })}
    </OptionsList>
  );
};

export default MinorOptionsList;
