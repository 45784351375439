import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import { OptionsType } from 'react-select';
import styled from 'styled-components';
import createFilterOptions from 'react-select-fast-filter-options';

import Placeholder from './Placeholder';
import BaseSelect, { BaseSelectProps, OptionType } from '../Select/Select';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import {
  StyledWrapper as BaseWrapper,
  SelectTheme
} from '../Select/Select.styled';

const Wrapper = styled(BaseWrapper)<SelectTheme>`
  position: relative;

  label::before {
    width: max-content;
  }

  .ReactSelect {
    &__control {
      max-height: 56px;

    &__value-container {
      padding: 0 8px;
    }
  }
`;

export interface Props extends BaseSelectProps {
  isPlaceholderLabel?: boolean;
  value: string | null;
}

interface State {
  focused: boolean;
}

class Select extends PureComponent<Props, State> {
  private get getFilterOptions() {
    return createFilterOptions({ options: this.props.options });
  }

  public constructor(props: Props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  private handleFocus = (): void => {
    this.setState({ focused: true });
  };

  private handleBlur = (event: React.FocusEvent<any>): void => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  private setValue = (
    options: OptionsType<OptionType>,
    value: string | null
  ): OptionType | null | undefined => {
    if (value) {
      return options.find((option: OptionType) => option.value === value);
    }

    return null;
  };

  public render(): ReactNode {
    const { handleFocus, handleBlur } = this;
    const {
      name,
      value,
      placeholder,
      onChange,
      onInputChange,
      options,
      error,
      isClearable,
      isSearchable,
      isPlaceholderLabel,
      disabled
    } = this.props;

    const { focused } = this.state;

    return (
      <Wrapper hasValue={!!value} error={error}>
        <Placeholder
          placeholder={placeholder ?? ''}
          value={value ?? ''}
          focused={focused}
        >
          <BaseSelect
            id={name}
            name={name}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            value={this.setValue(options, value)}
            filterOption={this.getFilterOptions}
            placeholder=""
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={error}
            isClearable={isClearable}
            isSearchable={isSearchable}
            disabled={disabled}
            styles={{
              control: (base: CSSProperties) => ({
                ...base,
                minHeight: 56,
                cursor: 'pointer'
              }),
              valueContainer: (base: CSSProperties) => ({
                ...base,
                minHeight: 56,
                fontSize: pxInRem(18),
                cursor: 'pointer'
              }),
              singleValue: (base: CSSProperties) => ({
                ...base,
                paddingTop: placeholder && !isPlaceholderLabel ? 18 : 0,
                paddingLeft: 5,
                lineHeight: 'normal'
              }),
              input: (base: CSSProperties) => ({
                ...base,
                paddingLeft: 5
              })
            }}
          />
        </Placeholder>
      </Wrapper>
    );
  }
}

export default Select;
