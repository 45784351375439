import styled from 'styled-components';

import { Button as _Button } from '../../../one-shop/shared/components/Button/Button';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { container } from 'shared/constants/mixins';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';

const ICON_SIZE = 45;

export const Image = styled.img`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

export const Button = styled(_Button)`
  ${mediaQueries.fromTablet`
    display: flex;
    align-self: center;
    justify-content: flex-end;
    width: min-content;
  `}
`;

export const WrapperBlock = styled.section`
  ${container}

  display: flex;
  flex-direction: column;

  gap: var(--spacing-xl);
`;

export const WrapperTitleAndContent = styled.div`
  border-radius: 3px;

  background-color: var(--surface-primary);
  padding: var(--spacing-xl) var(--spacing-m);

  display: flex;
  flex-direction: column;

  gap: var(--spacing-l);

  ${mediaQueries.fromTablet`
    flex: 1;  
    padding: var(--spacing-xl);
    gap: var(--spacing-xl);
  `}
`;
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
    flex-direction: row;
    gap: var(--spacing-xxl);
  `}
`;
export const WrapperContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const ContentTitle = styled.h3`
  font-size: var(--text-size-h3);
  color: var(--text-title);
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.demi};
  line-height: var(--line-height-h3);
  margin: 0;
`;

export const Description = styled.div`
  color: var(--text-light);
`;

export const WrapperImageAndDescription = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  flex-direction: column;

  ${mediaQueries.fromTablet`
    flex: 1;
    flex-direction: row;
    gap: var(--spacing-m);
  `}
`;
