/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Title from 'shared/components/presentational/Title';
import { FormattedProps } from '../types';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  height: 100%;
  width: 100%;
`;

const ContentWrapper = styled.section`
  width: 100%;
  background-color: var(--surface-primary);
  padding: 40px 20px;
  max-width: 1000px;
  margin: 0 auto;
`;

const Columns = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: columns;
  justify-content: space-between;
  flex: 1;

  ${mediaQueries.toTablet`
  `} @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

const Column = styled(Raw)`
  color: var(--text-default);
  min-width: 24%;
  max-width: 100%;
  @media (max-width: 550px) {
    min-width: 100%;
  }
  padding: 0 20px;
  img {
    width: auto !important;
    height: auto !important;
  }
`;

export default function ColumnEdito({
  title,
  columns,
  titleHtmlTag
}: FormattedProps) {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title titleLabel={title} titleHtmlTag={titleHtmlTag} />
        <Columns>
          {columns.map((column, index) => (
            <Column html={column.text} key={index} />
          ))}
        </Columns>
      </ContentWrapper>
    </Wrapper>
  );
}
