import styled from 'styled-components';

import { container } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const WRAPPER_MAX_WIDTH = 640;
const WRAPPER_PRICE_DETAILS_WIDTH = 435;

export const Wrapper = styled.section`
  ${container}

  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  max-width: ${WRAPPER_MAX_WIDTH}px;

  ${mediaQueries.fromTablet`
    margin: auto var(--title-margin);
  `}
`;

export const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);

  ${mediaQueries.fromTablet`
    width: ${WRAPPER_PRICE_DETAILS_WIDTH}px;
  `}
`;

export const Divider = styled.div`
  border: 1px solid var(--surface-border-default);
`;
