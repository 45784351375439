import { createGlobalStyle } from 'styled-components';

import { ThemePalette } from 'shared/modules/page/types';

export const ThemePaletteGlobalStyle = createGlobalStyle<{
  theme: ThemePalette;
}>`
  :root {
    --surface-background: ${({ theme }) => theme?.surface?.background};
    --surface-border-default: ${({ theme }) => theme?.surface?.border?.default};
    --surface-border-light: ${({ theme }) => theme?.surface?.border?.light};
    --surface-error: ${({ theme }) => theme?.surface?.error};
    --surface-full: ${({ theme }) => theme?.surface?.full};
    --surface-info: ${({ theme }) => theme?.surface?.info};
    --surface-overlay: ${({ theme }) => theme?.surface?.overlay};
    --surface-primary: ${({ theme }) => theme?.surface?.primary};
    --surface-secondary: ${({ theme }) => theme?.surface?.secondary};
    --surface-success: ${({ theme }) => theme?.surface?.success};
    --surface-tertiary: ${({ theme }) => theme?.surface?.tertiary};

    --text-default: ${({ theme }) => theme?.text?.default};
    --text-light: ${({ theme }) => theme?.text?.light};
    --text-title: ${({ theme }) => theme?.text?.title};

    --primary-default: ${({ theme }) => theme?.primary?.default};
    --primary-disabled: ${({ theme }) => theme?.primary?.disabled};
    --primary-hover: ${({ theme }) => theme?.primary?.hover};
    --primary-label-default: ${({ theme }) => theme?.primary?.label?.default};
    --primary-label-disabled: ${({ theme }) => theme?.primary?.label?.disabled};
    --primary-pressed: ${({ theme }) => theme?.primary?.pressed};
    
    --secondary-default: ${({ theme }) => theme?.secondary?.default};
    --secondary-disabled: ${({ theme }) => theme?.secondary?.disabled};
    --secondary-hover: ${({ theme }) => theme?.secondary?.hover};
    --secondary-label-default: ${({ theme }) =>
      theme?.secondary?.label?.default};
    --secondary-label-disabled: ${({ theme }) =>
      theme?.secondary?.label?.disabled};
    --secondary-pressed: ${({ theme }) => theme?.secondary?.pressed};

    --tertiary-label-default: ${({ theme }) => theme?.tertiary?.label?.default};
    --tertiary-label-hover: ${({ theme }) => theme?.tertiary?.label?.hover};
    --tertiary-label-pressed: ${({ theme }) => theme?.tertiary?.label?.pressed};

    --icons-black: ${({ theme }) => theme?.icons?.black};
    --icons-default: ${({ theme }) => theme?.icons?.default};
    --icons-secondary: ${({ theme }) => theme?.icons?.secondary};

    --semantic-error: ${({ theme }) => theme?.semantic?.error};
    --semantic-info: ${({ theme }) => theme?.semantic?.info};
    --semantic-success: ${({ theme }) => theme?.semantic?.success};

    --menu-secondary-label-disabled: ${({ theme }) =>
      theme?.menu?.secondary?.label?.disabled};
  }
`;
