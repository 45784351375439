/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { PureComponent, ChangeEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import borderHelper from './helpers/borderHelper';
import _StarPlaceholder from './Placeholder';
import _FadeOutPlaceholder from './FadeOutPlaceholder';
import { BaseForm } from 'shared/types';
import { FONT_FAMILIES } from 'shared/constants/theme';

const _Input = styled.input<Props & State>`
  font-family: ${FONT_FAMILIES.base};
  width: 100%;
  line-height: ${pxInRem(20)};
  ${props => borderHelper(props)};
  height: 56px;
  padding: 15px 20px;
  font-size: ${pxInRem(18)};
  color: var(--text-default);
  background-color: ${props =>
    props.disabled ? 'var(--surface-secondary)' : 'var(--surface-background)'};
`;

const Wrapper = styled.div`
  position: relative;
`;

const _InputWithPlaceholder = styled(_Input)`
  padding: 24px 16px 8px;
`;

export interface Props extends BaseForm {
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value: string | number;
  // type is any input type except file input which has is own component
  type: 'text' | 'password' | 'number' | 'tel' | 'time' | 'url' | 'email';
  min?: string;
  max?: string;
  disabled?: boolean;
  fadeOutPlaceholder?: boolean;
  autocomplete?: 'on' | 'off';
}

interface State {
  focused: boolean;
}

export default class Input extends PureComponent<Props, State> {
  public readonly state = {
    focused: false
  };

  public static readonly defaultProps = {
    type: 'text'
  };

  private handleFocus = (e: React.FocusEvent<any>): void => {
    this.setState({ focused: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
  };

  private handleBlur = (e: React.FocusEvent<any>): void => {
    this.setState({ focused: false });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  public render(): ReactNode {
    const { handleFocus, handleBlur } = this;
    const {
      className,
      id,
      name,
      onChange,
      value,
      type,
      placeholder,
      fadeOutPlaceholder,
      error,
      disabled,
      autocomplete,
      ...inputProps
    } = this.props;
    const { focused } = this.state;

    const StyledInput =
      placeholder && !fadeOutPlaceholder ? _InputWithPlaceholder : _Input;

    const Placeholder = fadeOutPlaceholder
      ? _FadeOutPlaceholder
      : _StarPlaceholder;

    return (
      <Wrapper className={className}>
        <Placeholder
          className={className}
          placeholder={placeholder}
          value={value}
          focused={focused}
        >
          <StyledInput
            id={id || name}
            className={className}
            autoComplete={autocomplete}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            error={error}
            disabled={disabled}
            focused={focused}
            {...inputProps}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Placeholder>
      </Wrapper>
    );
  }
}
