import { useMutation } from 'react-query';

import { Api } from 'shared/types';
import { RenewalFormState } from '../../reducer';

type Props = {
  renewalFormValues: RenewalFormState;
  getQrCodeRenewal: Api;
  saleDeviceId: string | null;
};

export const useInitiateRenewal = ({
  renewalFormValues,
  getQrCodeRenewal,
  saleDeviceId
}: Props) => {
  return useMutation(async () => {
    if (!saleDeviceId) {
      throw new Error('Missing required parameters: saleDeviceId');
    }
    const params = new URLSearchParams({
      saleDeviceId
    });

    const products = [
      ...Object.keys(renewalFormValues.minorsOffers)
        .filter(id => renewalFormValues.minorsOffers[id])
        .map(id => ({ id })),
      { id: renewalFormValues.selectedOffer }
    ];

    const url = `${getQrCodeRenewal.url}?${params.toString()}`;

    const response = await fetch(url, {
      method: getQrCodeRenewal.method,
      headers: {
        Authorization: `QrcodeBearer ${renewalFormValues.token}`,
        'Content-Type': 'application/json',
        correlationId: renewalFormValues.correlationId
      },
      body: JSON.stringify({
        equipmentId: renewalFormValues.equipmentId,
        personId: renewalFormValues.personId,
        contractId: renewalFormValues.contractId,
        payment: {
          paymentMeanId: renewalFormValues.paymentMeanId,
          internationalPhoneNumber: renewalFormValues.phoneNumber,
          epaymentOperatorId: renewalFormValues.selectedOperator
        },
        products
      })
    });

    if (!response.ok) {
      throw new Error('Failed to initiate renewal');
    }

    const data = await response.json();
    return { orderId: data.orderId, correlationId: data.correlationId };
  });
};
