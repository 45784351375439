import React from 'react';

import {
  ArticleWrapper,
  Article,
  Heading,
  Subtitle,
  MainImage
} from '../styles/components';
import { ConditionalLink } from '../helpers/ConditionalLink';
import { FormattedArticle, ListProps } from '../types';

export default function ListGrid({
  articles,
  mediaQueries,
  onArticleClick,
  onArticleHover
}: ListProps) {
  return (
    <ArticleWrapper>
      {articles.map((article: FormattedArticle, index: number) => {
        let imageUrl = article.mainMedia;

        if (mediaQueries.toPhablet && article.responsiveMedia) {
          imageUrl = article.responsiveMedia;
        }

        return (
          <li key={index}>
            <Article
              onMouseEnter={
                onArticleHover ? () => onArticleHover(article.slug) : undefined
              }
            >
              <MainImage url={imageUrl} alt={article.alt} />
              <Heading>
                <ConditionalLink
                  href={`/articles/${article.slug}`}
                  onClick={
                    onArticleClick
                      ? () => onArticleClick(article.slug)
                      : undefined
                  }
                >
                  {article.title}
                </ConditionalLink>
                {article.subtitle && <Subtitle>{article.subtitle}</Subtitle>}
              </Heading>
            </Article>
          </li>
        );
      })}
    </ArticleWrapper>
  );
}
