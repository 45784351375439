import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture, { BaseWrapper } from 'shared/components/presentational/Picture';
import Button from 'shared/components/presentational/Button';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import Raw from 'shared/components/presentational/Raw/Raw';

export const Wrapper = styled.section`
  background-color: var(--surface-background);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
      gap: 0;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0;
  
  
      &:nth-child(even) {
        grid-template-areas: 
          "text image";
      }
  
      &:nth-child(odd) {
        grid-template-areas: 
          "image text";
      }
    `};
`;

export const ImageContainer = styled.div`
  grid-area: image;
`;

export const Link = styled(BaseLink)`
  width: 100%;
`;

export const CustomPictureWrapper = styled(BaseWrapper).attrs({})`
  height: 100%;
`;

export const Image = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  gap: var(--spacing-m);
  padding: 0 var(--spacing-xl);
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.fromTablet`
      padding: var(--spacing-xl) 60px;
    `};
`;

export const Description = styled(Raw)`
  margin: 0;
  font-size: var(--text-size-base);
  color: var(--text-light);
  line-height: var(--line-height-h3);

  ${mediaQueries.fromDesktop`
      margin-bottom: 8px;
  `}
`;

export const StyledButton = styled(Button)`
  ${mediaQueries.fromTablet`
      width: fit-content;
    `}
`;
