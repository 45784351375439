import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { compose } from 'recompose';
import { Field, FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import find from 'lodash/find';
import { InputActionMeta } from 'react-select';

import Button from 'shared/components/presentational/Button';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import errorEnhancer from 'shared/helpers/form/errorEnhancer';
import {
  FieldHint,
  Container as FormContainer
} from 'shared/components/presentational/Form';
import InputField from 'shared/components/presentational/Form/Fields/InputField';
import SelectField from 'shared/components/presentational/Form/Fields/SelectField';
import translateStaticYupError from 'shared/components/presentational/Form/helpers/translateStaticYupError';
import { COLORS } from 'shared/constants/theme';
import {
  InformationMessageWrapper,
  InformationMessageImage,
  InformationMessage,
  FieldsGrid,
  FieldWrapper
} from 'shared/blocks/eshop/shared/styles/components';
import { FIELDS } from '../../shared/constants';
import {
  Form,
  Subtitle,
  RadiosWrapper,
  RadioField,
  PhoneAndEmailNote,
  TitleWrapper,
  SubmitButtonWrapper,
  SubmitButton,
  Wrapper
} from './styles/components';
import { OfferFormValues } from '../../shared/types';
import geographicalAreaToPostalCodeOptions from '../helpers/geographicalAreaToPostalCodeOptions';
import geographicalAreaToCityOptions from '../helpers/geographicalAreaToCityOptions';
import { MandatoryFieldsMessage } from 'shared/blocks/form/styles/components';
import Title from 'shared/components/presentational/Title';
import useGeographicalAreaQuery from '../hooks/useGeographicalArea';
import { Props } from '../types';

const OfferSubscriptionForm = ({
  title,
  titleColor,
  titleHtmlTag,
  subtitle,
  informationMessage,
  values,
  confirmationButtonTheme,
  confirmationButtonLabel,
  crmGeographicalAreaApi,
  handleSubmit,
  onSave: handleSave,
  setFieldValue,
  errors,
  touched,
  t,
  titleOptions
}: Props & FormikProps<OfferFormValues>) => {
  const [postalCodeSearch, setPostalCodeSearch] = useState<string>('');

  const isSearchValid = postalCodeSearch.length > 2;
  const postalCodeValue = values[FIELDS.POSTAL_CODE];
  const cityValue = values[FIELDS.CITY];

  const { data: geographicalAreaData } = useGeographicalAreaQuery(
    {
      ...crmGeographicalAreaApi,
      url: queryString.stringifyUrl({
        url: crmGeographicalAreaApi.url,
        query: { query: postalCodeValue || postalCodeSearch }
      })
    },
    { enabled: isSearchValid }
  );

  const postalCodeOptions = geographicalAreaData
    ? geographicalAreaToPostalCodeOptions(geographicalAreaData)
    : [];

  const cityOptions =
    postalCodeValue && geographicalAreaData
      ? geographicalAreaToCityOptions(geographicalAreaData)
      : [];

  // METHODS
  useEffect(() => {
    // populate city choices otherwise select fields are shown empty
    const postalCodeValue = values[FIELDS.POSTAL_CODE];
    if (postalCodeValue) {
      setPostalCodeSearch(postalCodeValue);
    }
  }, []);

  const handleBlur = useCallback(
    () => handleSave({ values, submitted: false }),
    [values]
  );

  // clear city field when postal code is cleared
  useEffect(() => {
    if (!postalCodeValue) {
      setFieldValue(FIELDS.CITY, null);
      setFieldValue(FIELDS.COUNTRY_CODE, null);
      setPostalCodeSearch('');
    }
  }, [postalCodeValue]);

  // update countryCode when city is selected
  useEffect(() => {
    if (cityValue && geographicalAreaData) {
      const matchingCity = find(
        geographicalAreaData.data,
        ({ city }) => city === cityValue
      );

      if (matchingCity) {
        setFieldValue(FIELDS.COUNTRY_CODE, matchingCity.countryCode);
      }
    }
  }, [cityValue]);

  const handlePostalCodeInputChange = debounce(
    (search: string, inputActionMeta: InputActionMeta) => {
      if (inputActionMeta.action === 'input-change') {
        setPostalCodeSearch(search);
      }
    },
    500
  );

  return (
    <Wrapper>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Title
            titleLabel={title}
            titleColor={titleColor}
            titleHtmlTag={titleHtmlTag}
          />
          <Subtitle color={titleColor} html={subtitle} />

          <MandatoryFieldsMessage>
            {t('blocks.form.mandatoryFieldsMessage')}
          </MandatoryFieldsMessage>

          <FieldsGrid>
            <FieldWrapper fullWidth={true}>
              <RadiosWrapper id="selectedTitle">
                {titleOptions?.map(({ label, value }) => (
                  <TitleWrapper key={`eshop-title-${label}`}>
                    <RadioField
                      name={FIELDS.TITLE}
                      label={label}
                      type="text"
                      id={value}
                      color={COLORS.amaranth}
                    />
                  </TitleWrapper>
                ))}
              </RadiosWrapper>

              <FieldHint
                error={translateStaticYupError(
                  touched[FIELDS.TITLE] && errors[FIELDS.TITLE],
                  t
                )}
                isRequired={false}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.FIRST_NAME}
                placeholder={t('common.form.firstName.label')}
                isRequired={true}
                type="text"
              />
            </FieldWrapper>

            <FieldWrapper>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.LAST_NAME}
                placeholder={t('common.form.lastName.label')}
                isRequired={true}
                type="text"
              />
            </FieldWrapper>

            <FieldWrapper zIndex={3}>
              <Field
                onBlur={handleBlur}
                component={SelectField}
                name={FIELDS.POSTAL_CODE}
                onInputChange={handlePostalCodeInputChange}
                options={postalCodeOptions}
                placeholder={t('common.form.postalCode.label')}
                isSearchable={true}
                isClearable={true}
                isRequired={true}
              />
            </FieldWrapper>

            <FieldWrapper zIndex={2}>
              <Field
                onBlur={handleBlur}
                component={SelectField}
                name={FIELDS.CITY}
                options={cityOptions}
                disabled={!postalCodeValue}
                placeholder={t('common.form.city.label')}
                isSearchable={false}
                isClearable={true}
                isRequired={true}
              />
            </FieldWrapper>

            <FieldWrapper fullWidth={true}>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.ADDRESS}
                placeholder={t('common.form.address.label')}
                type="text"
                isRequired={true}
              />
            </FieldWrapper>

            <FieldWrapper fullWidth={true}>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.ADDRESS_COMPLEMENT}
                placeholder={t('common.form.addressComplement.label')}
                type="text"
                isRequired={false}
              />
            </FieldWrapper>
          </FieldsGrid>

          <PhoneAndEmailNote>
            {t('blocks.eshop.offerSubscriptionForm.phoneNumberAndEmailUsage')}
          </PhoneAndEmailNote>

          <FieldsGrid>
            <FieldWrapper>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.MOBILE_PHONE_NUMBER}
                placeholder={t('common.form.mobilePhoneNumber.label')}
                type="text"
                isRequired={true}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Field
                onBlur={handleBlur}
                component={InputField}
                name={FIELDS.EMAIL}
                placeholder={t('common.form.email.label')}
                type="email"
                isRequired={true}
              />
            </FieldWrapper>
          </FieldsGrid>

          {informationMessage.message && (
            <InformationMessageWrapper>
              {informationMessage.image.imageSrc && (
                <InformationMessageImage
                  url={informationMessage.image.imageSrc}
                  alt={informationMessage.image.imageAlt}
                />
              )}
              <InformationMessage html={informationMessage.message} />
            </InformationMessageWrapper>
          )}

          <SubmitButtonWrapper>
            <SubmitButton type="submit" theme={confirmationButtonTheme}>
              <Button.children.Text>
                {confirmationButtonLabel}
              </Button.children.Text>
            </SubmitButton>
          </SubmitButtonWrapper>
        </Form>
      </FormContainer>
    </Wrapper>
  );
};

export default compose<Props & FormikProps<OfferFormValues>, Props>(
  withTranslation(),
  withFormik<Props, OfferFormValues>({
    mapPropsToValues: ({ defaultValues }) => defaultValues,
    handleSubmit: (values, { props, setTouched }) => {
      const { onSubmit: handleSubmit, onSave: handleSave } = props;

      // Mark all field as touched
      setTouched(
        Object.values(FIELDS).reduce(
          (acc, field) => ({ ...acc, [field]: true }),
          {}
        )
      );

      // Saving form in store
      // @ts-ignore (null values would not pass yup validation)
      handleSave({ values, submitted: true }).then(() => handleSubmit());
    },
    validationSchema: (props: { t: TFunction }) =>
      yup.object().shape({
        [FIELDS.TITLE]: yup
          .string()
          .nullable()
          .required(props.t('common.form.title.empty')),
        [FIELDS.FIRST_NAME]: yup
          .string()
          .required(props.t('common.form.firstName.empty')),
        [FIELDS.LAST_NAME]: yup
          .string()
          .required(props.t('common.form.lastName.empty')),
        [FIELDS.POSTAL_CODE]: yup
          .string()
          .isNumber()
          .nullable()
          .required(props.t('common.form.postalCode.empty')),
        [FIELDS.CITY]: yup
          .string()
          .nullable()
          .required(props.t('common.form.city.empty')),
        [FIELDS.ADDRESS]: yup
          .string()
          .forbiddenCharacters()
          .required(props.t('common.form.address.empty')),
        [FIELDS.ADDRESS_COMPLEMENT]: yup.string().forbiddenCharacters(),
        [FIELDS.MOBILE_PHONE_NUMBER]: yup
          .string()
          .required(props.t('common.form.mobilePhoneNumber.empty'))
          .isPhoneNumber(),
        [FIELDS.EMAIL]: yup
          .string()
          .required(props.t('common.form.email.empty'))
          .email()
      })
  }),
  ResponsiveHoc,
  errorEnhancer
)(OfferSubscriptionForm);
