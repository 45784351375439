import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/presentational/Button';
import BasePicture from 'shared/components/presentational/Picture';
import Raw from 'shared/components/presentational/Raw/Raw';
import BaseIcon from 'shared/components/presentational/Icons';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import { collapsePadding } from 'shared/constants/mixins';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const Wrapper = styled.div`
  ${collapsePadding('0')};
  font-family: ${FONT_FAMILIES.base};
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const StyledButton = styled(Button)<{
  fontSize: number;
  noticeOpened: boolean;
}>`
  picture {
    background-color: var(--tertiary-label-default);
  }

  &:hover {
    picture {
      background-color: var(--tertiary-label-hover);
    }
  }

  ${mediaQueries.fromTablet`
    &:hover {
      picture {
        background-color: var(--tertiary-label-hover);
      }

      span {
        color: var(--tertiary-label-hover);
      }
      
      color: var(--tertiary-label-hover);

    }
  `};

  ${({ noticeOpened }) =>
    noticeOpened &&
    `
    color: var(--tertiary-label-pressed);

    picture {
      background-color: var(--tertiary-label-pressed);
    }

    span {
      color: var(--tertiary-label-pressed);
    }
  `}

  width: 100% !important;
  margin: 0;
  display: flex;
  gap: var(--spacing-xs, 12px);
  font-size: ${props => pxInRem(props.fontSize)} !important;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};

  ${props =>
    props.noticeOpened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};

  ${props =>
    props.noticeOpened &&
    css`
      margin-bottom: 0;
    `};
`;

const PictureWrapper = styled.picture`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Picture = styled(BasePicture).attrs({
  customWrapper: PictureWrapper
})`
  max-height: 15px;
`;

const Text = styled(Raw)<{ fontSize: number }>`
  font-size: ${props => pxInRem(props.fontSize)};
  color: var(--text-light);
`;

const Label = styled(Button.children.Text)`
  font-size: var(--text-size-s);
  text-decoration: none;
`;

const RoundedChevronDown = styled(BaseIcon).attrs({
  name: 'RoundedChevronDown',
  width: '16px',
  height: '8px'
})``;

const ChevronRight = styled(BaseIcon).attrs({
  name: 'RoundedChevronDown',
  width: '16px',
  height: '8px'
})`
  transform: rotate(-90deg);
`;

const DoubleChevronWrapper = styled.div`
  display: flex;

  ${ChevronRight}:last-of-type {
    transform: rotate(-90deg) translateY(-10px);
  }
`;

interface Props {
  title?: string;
  notice?: string;
  className?: string;
  fontSizes?: Record<string, number>;
  pictogram?: string;
  redirectLink?: string;
}

export default function LegalNotice({
  title,
  notice,
  className,
  fontSizes = { title: 12, notice: 14 },
  pictogram,
  redirectLink
}: Props) {
  const { t } = useTranslation();
  const [noticeOpened, setNoticeOpened] = useState(!title);

  const toggleNotice = useCallback(() => {
    setNoticeOpened(!noticeOpened);
  }, [noticeOpened]);

  const showNoticeText = noticeOpened || !title;
  const buttonClickProps = redirectLink
    ? { to: redirectLink, target: '_blank' }
    : { onClick: toggleNotice };

  return (
    <Wrapper className={className}>
      {(pictogram || title) && (
        <StyledButton
          {...buttonClickProps}
          fontSize={fontSizes.title}
          noticeOpened={noticeOpened}
          title={t('components.legalNotice.show')}
          variant="tertiary"
        >
          <>
            {pictogram && <Picture externalUrl={pictogram} alt="pictogram" />}
            {title && <Label>{title}</Label>}
            {redirectLink ? (
              <DoubleChevronWrapper>
                <ChevronRight />
                <ChevronRight />
              </DoubleChevronWrapper>
            ) : title ? (
              <RoundedChevronDown />
            ) : null}
          </>
        </StyledButton>
      )}
      {showNoticeText && notice && (
        <Text html={notice} fontSize={fontSizes.notice} />
      )}
    </Wrapper>
  );
}
