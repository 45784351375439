import { ArticleContent, FormattedArticleContent } from '../shared/types';
import { Link, CustomTitle, TitleHtmlTag } from 'shared/types';
import { ResponsiveHocProps } from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

export interface Content {
  template: string;
  title: CustomTitle | null;
  subtitle: string | null;
  list: Article[];
  anchorId: null;
  article_page_url: Link;
  get_article_content: Link;
}

export interface FormattedProps {
  template: string;
  title?: string;
  titleColor?: string;
  titleHtmlTag?: TitleHtmlTag;
  subtitle?: string;
  articles: FormattedArticle[];
  createArticleContentHref: (params: Record<'slug', string>) => string;
  createArticlePageHref: (params: Record<'slug', string>) => string;
}

export interface PropsFromState {
  url?: string;
  lang?: string;
  miniSiteDomain?: string;
  domainUrl: string;
  zone: string;
}

export interface PropsFromDispatch {
  pushURLHandler: (url: string) => void;
  goBack: () => void;
}

export interface PropsFromMerge {
  onArticleClose: () => void;
  onArticleClick: ((slug: string) => void) | null;
  onArticleHover: (slug: string) => void;
}

export type Props = FormattedProps &
  PropsFromState &
  PropsFromDispatch &
  PropsFromMerge &
  ResponsiveHocProps;

export type FormattedArticle = Omit<FormattedArticleContent, 'fragments'>;

export type Article = Omit<ArticleContent, 'fragments' | 'category'>;

export enum TEMPLATES {
  slider = 'slider',
  grid = 'grid'
}

export interface ListProps {
  articles: FormattedArticle[];
  mediaQueries: ResponsiveHocProps['mediaQueries'];
  onArticleClick: ((slug: string) => void) | null;
  onArticleHover: (slug: string) => void;
}
