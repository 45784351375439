import React from 'react';

import { IconProps } from '../types';

export default function Position({
  className,
  width = '1em',
  height = '1em'
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 48 64"
      version="1.1"
    >
      <path
        d="M24.1328009,64 C8.04426698,46.6835759 3.35166755e-16,33.5606398 0,24.6311917 C0,11.2370196 9.80975738,4.65177187e-15 24.1328009,0 C38.4558445,0 48,11.0253316 48,24.6311917 C48,33.7017651 40.044267,46.8247012 24.1328009,64 Z M24,41.5135135 C33.4677386,41.5135135 41.1428571,33.7692497 41.1428571,24.2162162 C41.1428571,14.6631827 33.4677386,6.91891892 24,6.91891892 C14.5322614,6.91891892 6.85714286,14.6631827 6.85714286,24.2162162 C6.85714286,33.7692497 14.5322614,41.5135135 24,41.5135135 Z"
        id="Combined-Shape"
        fill="current"
      ></path>
    </svg>
  );
}
